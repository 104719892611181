import {
  ASSIGNMENT_NOT_STARTED,
  ASSIGNMENT_NOT_STARTED_PAST_DUE,
  ASSIGNMENT_STARTED,
  ASSIGNMENT_SUBMITTED,
  CONTENT_TYPE_ASSESSMENT,
  CONTENT_TYPE_BLOCK,
} from 'core/consts'
import {
  dedupe,
  filter,
  first,
  flatten,
  join,
  map,
  reduce,
  sort,
  sortBy,
  toKeyedObject,
} from 'fp/arrays'
import { sum } from 'fp/numbers'
import { get, mapValues, merge, omit, pick, set } from 'fp/objects'
import { curryRight, fallbackTo, isDefined, matches, pipe } from 'fp/utils'
import { isPastMaxSubmitDate } from 'hss/utils'
import { produce } from 'immer'
import { compose } from 'redux'
import { getScore } from './interactions'

const averageScores = scores => {
  const filteredScores = scores.filter(compose(isDefined, getScore))
  return {
    score: filteredScores.length
      ? sum(...map(getScore)(filteredScores)) / filteredScores.length
      : null,
    requiresGrading: scores.some(get('requiresGrading')),
  }
}

// Returns an array where each item is an array of one user's userAssignments
const groupUserAssignmentsByUser = compose(
  Object.values,
  reduce((result, userAssignment) => {
    const { userId } = userAssignment
    const assignmentsForThisUser = result[userId] || []
    return set(userId, [...assignmentsForThisUser, userAssignment])(result)
  }, {}),
)
const getRequiresGrading = (assignmentInteractives, interactionsByContentId) =>
  assignmentInteractives
    .map(({ id }) => get(`${id}.scoreData`)(interactionsByContentId))
    .some(get('requiresGrading'))

const buildStudentWithScoreData = map(userAssignments => ({
  maxScore: sum(map(get('maxScore'))(userAssignments)),
  score: sum(map(getScore)(userAssignments)),
  requiresGrading: userAssignments.some(get('requiresGrading')),
  ...first(userAssignments).user,
  userAssignmentsByAssignmentId: curryRight(
    toKeyedObject,
    'assignmentId',
  )(userAssignments),
}))

const getGradingStatus = (userAssignment, isPastDueDate) => {
  const { progress, submittedDate } = userAssignment
  const inProgress = progress > 0
  let gradingStatus: string // TODO: define GradingStatus type

  if (submittedDate !== null) {
    gradingStatus = ASSIGNMENT_SUBMITTED
  } else if (inProgress) {
    gradingStatus = ASSIGNMENT_STARTED
  } else if (isPastDueDate) {
    gradingStatus = ASSIGNMENT_NOT_STARTED_PAST_DUE
  } else {
    gradingStatus = ASSIGNMENT_NOT_STARTED
  }

  return gradingStatus
}

const buildAssignmentsGradebook = (
  userAssignments,
  assignments,
  interactionsById,
  interactivesById,
) => {
  const interactivesByAssignmentId = compose(
    mapValues(
      compose(
        filter(Boolean),
        map(id => interactivesById[id]),
        fallbackTo([]),
        get('scoreableContentIds'),
      ),
    ),
    toKeyedObject,
  )(assignments)

  const getInteractions = compose(
    curryRight(toKeyedObject, 'contentId'),
    filter(Boolean),
    map(id => interactionsById[id]),
  )
  const students = compose(
    buildStudentWithScoreData,
    map(
      map(userAssignment => {
        const { assignmentId, interactionIds } = userAssignment
        const requiresGrading = getRequiresGrading(
          interactivesByAssignmentId[assignmentId],
          getInteractions(interactionIds),
        )
        const { data, endDate, maxScore } = assignments.find(
          matches('id', assignmentId),
        )
        const isPastDueDate = isPastMaxSubmitDate({
          endDate,
          data,
        })
        return merge(userAssignment, {
          maxScore,
          requiresGrading,
          gradingStatus: getGradingStatus(userAssignment, isPastDueDate),
        })
      }),
    ),
    groupUserAssignmentsByUser,
  )(userAssignments)

  return {
    assignments,
    students,
    requiresGrading: students.some(get('requiresGrading')),
  }
}

const singleAssignmentGradebook = ({ assignments, students }) => {
  const assignment = first(assignments)
  return {
    assignment,
    students: compose(
      map(({ userAssignmentsByAssignmentId, ...rest }) => ({
        ...rest,
        userAssignment: userAssignmentsByAssignmentId[assignment.id],
      })),
      fallbackTo([]),
    )(students),
  }
}

/**
 * Finds the ancestor content that should be linked to
 * from the StandardMasteryDialog.
 */
const findLinkTarget = (contentItem, allContent) => {
  const id = get('id')(contentItem)
  const content = get(id)(allContent)
  const parentId = get('parent.id')(content)
  const parent = get(parentId)(allContent)
  if (parent?.contentType === CONTENT_TYPE_ASSESSMENT) {
    // API returns assessment questions as faux interactives whose parents are the assessment.
    // We'll want to link to the assessment instead of searching for the nearest block parent.
    return parent
  }
  return content && content.contentType !== CONTENT_TYPE_BLOCK
    ? findLinkTarget(content.parent, allContent)
    : parent
}

const groupBy = key => {
  const getKey = get(key)
  return reduce((prev, next) => {
    const nextKey = getKey(next)
    return {
      ...prev,
      [nextKey]: [...(prev[nextKey] || []), next],
    }
  }, {})
}

const groupScoresByAssignmentAndLinkTarget = compose(
  result => ({ assignmentScores: result }),
  pipe(
    groupBy('assignmentId'),
    Object.entries,
    map(([assignmentId, assignmentScores]) => ({
      assignmentId,
      linkTargetScores: pipe(
        groupBy('linkTargetId'),
        Object.entries,
        map(([linkTargetId, scores]) => ({
          linkTargetId,
          scores,
        })),
      )(assignmentScores),
    })),
  ),
)

const buildStandardsGradebook = (
  userAssignments,
  assignments,
  standardsById,
  interactionsById,
  interactivesById,
  allContent,
) => {
  const getLinkTargetId = compose(
    get('id'),
    curryRight(findLinkTarget, allContent),
  )

  const interactivesByAssignmentId = compose(
    mapValues(
      compose(
        map(interactive => ({
          ...interactive,
          linkTargetId: getLinkTargetId(interactive),
        })),
        filter(Boolean),
        map(id => interactivesById[id]),
        fallbackTo([]),
        get('scoreableContentIds'),
      ),
    ),
    toKeyedObject,
  )(assignments)

  const getInteractions = compose(
    curryRight(toKeyedObject, 'contentId'),
    filter(Boolean),
    map(id => interactionsById[id]),
  )

  const scoreableInteractives = compose(
    filter(Boolean),
    map(id => interactivesById[id]),
    dedupe,
    fallbackTo([]),
    flatten,
    map(get('scoreableContentIds')),
  )(assignments)

  const standards = compose(
    filter(Boolean),
    map(id => standardsById[id]),
    dedupe,
    flatten,
    map(flatten),
    map(Object.values),
    map(pick('applicationStandardIds', 'instructionStandardIds')),
  )(scoreableInteractives)

  const groupUserAssignmentInteractiveScoresByUserAndStandard = compose(
    Object.values,
    reduce((result, userAssignment) => {
      const { assignmentId, interactionIds, user, userId } = userAssignment
      const interactives = interactivesByAssignmentId[assignmentId]
      const interactions = getInteractions(interactionIds)
      const { standardScoresById } = result[userId] || {
        standardScoresById: {},
      }

      const newScores = produce(standardScoresById, draft => {
        interactives.forEach(interactive => {
          const {
            applicationStandardIds,
            contentType,
            data,
            id: interactiveId,
            instructionStandardIds,
            linkTargetId,
          } = interactive

          const {
            scoreData: {
              manualScoreOverride,
              requiresGrading = false,
              score,
            } = {},
          } = interactions[interactiveId] || {}

          applicationStandardIds
            .concat(instructionStandardIds)
            .forEach(standardId => {
              if (!draft[standardId]) {
                draft[standardId] = []
              }
              if (isDefined(score)) {
                draft[standardId].push({
                  assignmentId,
                  contentId: interactiveId,
                  contentType,
                  data,
                  linkTargetId,
                  manualScoreOverride,
                  score,
                  requiresGrading,
                })
              }
            })
        })
      })

      return set(userId, {
        user,
        standardScoresById: newScores,
      })(result)
    }, {}),
  )

  const getDescription = pipe(
    map(get('data.assessmentBlockNumberLabel')),
    filter(Boolean),
    join(', '),
  )

  const students = pipe(
    groupUserAssignmentInteractiveScoresByUserAndStandard,

    map(user => {
      const { standardScoresById } = user
      return {
        ...user,
        standardScoresById: mapValues(groupScoresByAssignmentAndLinkTarget)(
          standardScoresById,
        ),
      }
    }),

    // The average score for each content page is the average of its interactive scores. So far so good.
    map(({ user, standardScoresById }) => ({
      user,
      standardScoresById: mapValues(({ assignmentScores }) => ({
        assignments: assignmentScores.map(
          ({ assignmentId, linkTargetScores }) => ({
            assignmentId,
            linkTargets: linkTargetScores.map(({ linkTargetId, scores }) => ({
              linkTargetId,
              ...averageScores(scores),
              scores,
            })),
          }),
        ),
      }))(standardScoresById),
    })),
    // The average score for each assignment is the average of its interactive scores.
    // We're giving equal weight to each interactive, not each page of content.
    map(({ user, standardScoresById }) => ({
      user,
      standardScoresById: mapValues(({ assignments: standardAssignments }) => ({
        assignments: standardAssignments.map(
          ({ assignmentId, linkTargets }) => ({
            assignmentId,
            linkTargets,
            ...averageScores(compose(flatten, map(get('scores')))(linkTargets)),
          }),
        ),
      }))(standardScoresById),
    })),

    // The average score for each standard as a whole is the average of all its interactive scores
    // across the given assignments.
    // Again, we're giving equal weight to each interactive, not each group of interactives within each assignment.
    map(({ user, standardScoresById }) => ({
      user,
      standardScoresById: mapValues(({ assignments: standardAssignments }) => ({
        ...averageScores(
          compose(
            flatten,
            map(get('scores')),
            flatten,
            map(get('linkTargets')),
          )(standardAssignments),
        ),
        assignments: standardAssignments,
      }))(standardScoresById),
    })),

    // In contrast to the above, the average score for each student is the average of all their standard scores.
    // Each standard gets equal weight regardless of the number of interactives each one has.
    map(({ user, standardScoresById }) => ({
      ...user,
      ...averageScores(Object.values(standardScoresById)),
      standardScoresById,
    })),

    // We don't need to include every single score in the result--just the aggregates.
    map(({ standardScoresById, ...restA }) => ({
      ...restA,
      standardScoresById: mapValues(
        ({ assignments: standardAssignments, ...restB }) => ({
          ...restB,
          assignments: standardAssignments.map(({ linkTargets, ...restC }) => ({
            ...restC,
            linkableContents: linkTargets.map(linkTarget =>
              pipe(
                set('contentId', linkTarget.linkTargetId),
                set(
                  'contentType',
                  get(`${linkTarget.linkTargetId}.contentType`)(allContent),
                ),
                set('description', getDescription(linkTarget.scores)),
                omit('scores', 'linkTargetId'),
              )(linkTarget),
            ),
          })),
        }),
      )(standardScoresById),
    })),
  )(userAssignments)

  return {
    requiresGrading: students.some(get('requiresGrading')),
    standards,
    students,
  }
}

const buildStandardsMasteryGradebook = ({ standards, students }) =>
  compose(
    sort(sortBy('average', 'desc', 'numeric')),
    sort(sortBy('shortCode')),
    map(standard => {
      const { id } = standard
      const average = averageScores(
        students.map(({ standardScoresById }) =>
          get(`${id}`)(standardScoresById),
        ),
      )
      return set('average', average.score || 0)(standard)
    }),
  )(standards)

export {
  buildAssignmentsGradebook,
  buildStandardsGradebook,
  buildStandardsMasteryGradebook,
  singleAssignmentGradebook,
}
