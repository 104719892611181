import type { Theme } from '@mui/material'

const overrides = ({ palette }: Theme) => ({
  MuiDivider: {
    styleOverrides: {
      light: { borderColor: palette.border[1] },
    },
  },
})

export default overrides
