import cl from 'classnames'
import { forwardRef } from 'react'
import { useMatch, useResolvedPath } from 'react-router-dom'
import Link from './Link'

type NavLinkProps = {
  children: React.ReactNode
  className?: string
  to: string
}

const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ children, className, to, ...rest }, ref) => {
    const active = useMatch({ path: useResolvedPath(to).pathname })

    return (
      <Link
        {...{
          'aria-current': active ? 'page' : null,
          className: cl(className, { active }),
          ref,
          to,
          ...rest,
        }}>
        {children}
      </Link>
    )
  },
)

export default NavLink
