import { SimpleTreeView } from '@mui/x-tree-view'
import type { SimpleTreeViewProps } from '@mui/x-tree-view/SimpleTreeView'
import {
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
} from 'core/consts'
import { push } from 'fp/arrays'
import useContent from 'hooks/useContent'
import { forwardRef, useEffect, useId, useState } from 'react'
import { useSelector } from 'react-redux'
import type { Content } from 'reducers/@types/content'
import type { NavItem } from 'routing/shells/NavShell/NavSidebar/Nav/TreeEntry'
import { getContentNav, getParentFromContentId } from 'selectors/contentViewer'
import { getContentViewerParams } from 'selectors/contentViewerParams'
import Sections from './Sections'

type CommonTreeProps = object & {
  actionsHeight?: number
  contentId: string
  maxHeight: string
  ref?: React.Ref<HTMLUListElement>
}

const ChapterTree = forwardRef<HTMLUListElement, CommonTreeProps>(
  ({ actionsHeight = 0, contentId, maxHeight }, ref) => {
    const sections: NavItem[] = useSelector(getContentNav({ contentId }))

    const {
      echoId,
      navTreeSubsectionId,
      sourceId,
      subsectionId,
    }: {
      echoId: string
      navTreeSubsectionId: string
      sourceId: string
      subsectionId: string
    } = useSelector(getContentViewerParams()) || {}

    const possibleSubsection: Content | null = useContent({
      contentType: CONTENT_TYPE_SUBSECTION,
      contentId: subsectionId || sourceId,
      disableFetch: true,
    })

    const possibleSource: Content | null = useContent({
      contentType: CONTENT_TYPE_SOURCE,
      contentId: sourceId,
      disableFetch: true,
    })

    const possibleEcho: Content | null = useContent({
      contentId: echoId,
      contentType: CONTENT_TYPE_ECHO,
      disableFetch: true,
    })

    const currentSubsection =
      possibleSubsection || possibleSource || possibleEcho

    const subsectionParent: Content = useSelector(
      getParentFromContentId({
        contentType: CONTENT_TYPE_SUBSECTION,
        contentId: navTreeSubsectionId || possibleSubsection?.id,
      }),
    )

    const sourceParent: Content = useSelector(
      getParentFromContentId({
        contentType: CONTENT_TYPE_SOURCE,
        contentId: navTreeSubsectionId || possibleSource?.id,
      }),
    )

    const echoParent: Content = useSelector(
      getParentFromContentId({
        contentType: CONTENT_TYPE_ECHO,
        contentId: possibleEcho?.id,
      }),
    )

    const currentSection = String(navTreeSubsectionId).startsWith('TE-')
      ? sections[0]
      : subsectionParent || sourceParent || echoParent

    const currentParentSection: Content = useSelector(
      getParentFromContentId({
        contentType: CONTENT_TYPE_SECTION,
        contentId: currentSection?.id,
      }),
    )

    const [expandedItems, setExpandedItems] = useState<string[]>([])
    const [selectedItems, setSelectedItems] = useState<
      SimpleTreeViewProps<true>['selectedItems']
    >([])
    const id = useId()

    useEffect(() => {
      if (currentSection?.data?.tabbed) {
        // a section within a section is selected -- expand the grandparent
        if (currentParentSection) {
          setExpandedItems(push(currentParentSection?.id))
        }
        setSelectedItems([currentSection?.id])
      } else {
        setExpandedItems(push(currentSection?.id))
        if (navTreeSubsectionId || currentSubsection?.id) {
          setSelectedItems([
            String(navTreeSubsectionId || currentSubsection?.id),
          ])
        } else {
          setSelectedItems([])
        }
      }
    }, [
      currentParentSection,
      currentSection,
      currentSubsection,
      navTreeSubsectionId,
    ])

    const handleToggle = (_: React.SyntheticEvent, itemIds: string[]) => {
      setExpandedItems(itemIds)
    }

    return sections ? (
      <SimpleTreeView
        multiSelect
        aria-label="navigation tree"
        data-subvariant="content-viewer"
        expandedItems={expandedItems}
        id={id}
        onExpandedItemsChange={handleToggle}
        ref={ref}
        selectedItems={selectedItems}
        sx={{
          /**
           * This component is restricted in height in order to cause a scrollbar
           * to appear when needed.
           *
           * A bit of padding is added to the bottom so things feel less cramped.
           * Padding equal to the height of the `actions` area is also added so
           * that the scrollbar appears "behind" the `actions` area.
           *
           * SEE ALSO:
           *
           *    SourceTree  - This is pretty much the same as this component.
           *                  Make sure to apply any required edits to both files
           *
           *    Nav/Actions - This is where the `actions` are defined
           *
           *    SectionTree - This is where the `actions` height is defined
           */
          height: maxHeight,
          maxHeight,
          overflowY: 'auto',
          paddingBottom: 40 + actionsHeight,
          // border: '1px solid red !important',
        }}
        variant="nav-shell">
        <Sections sections={sections} />
      </SimpleTreeView>
    ) : null
  },
)

export default ChapterTree

export type { CommonTreeProps }
