import type { Theme } from '@mui/material'
import { isString, suffix } from 'fp/strings'
import { isDefined } from 'fp/utils'
import { produce } from 'immer'
import { Maybe } from 'monet'
import {
  border,
  size as polishedSize,
  position,
  rgba,
  transparentize,
} from 'polished'
import type { Mixins } from 'src/@types/mui-palette'
import type { NumOrStr } from 'src/@types/utils'
import {
  containedPaddingLeft,
  containedPaddingRight,
  featuredContentMaxWidth,
} from '.'
import { acumin, inter } from './typography'

// -----------------------------------------------------------------------------
// Helper methods
// -----------------------------------------------------------------------------

type Unit = 'em' | 'px' | 'rem'

type UnitPrefixer = (
  unit: Unit,
) => (size: number | string | null | undefined) => string

const unitPrefixer: UnitPrefixer = unit => size =>
  isString(size)
    ? size
    : size !== undefined && size !== null
      ? size === 0
        ? size.toString()
        : suffix(unit)(size)
      : 'initial'

const spreadValues =
  (asUnit: UnitPrefixer) =>
  (...values: (number | string | null | undefined)[]): string =>
    values
      .slice(0, 4)
      .map(
        v => (values.length > 1 ? (isDefined(v) ? v : 'initial') : v) as Unit,
      )
      .map(asUnit)
      .join(' ')

// -----------------------------------------------------------------------------
// Atomic methods that are later exported
// -----------------------------------------------------------------------------

const asEm = unitPrefixer('em')
const asPx = unitPrefixer('px')
const asRem = unitPrefixer('rem')

const important = suffix(' !important')

const em: Mixins['em'] = spreadValues(asEm as unknown as UnitPrefixer)
const px: Mixins['px'] = spreadValues(asPx as unknown as UnitPrefixer)
const rem = spreadValues(asRem as unknown as UnitPrefixer)

const textSizeR: Mixins['textSizeR'] = (
  fontSize,
  lineHeight,
  fontWeight = 'inherit',
) => ({
  fontSize: asRem(fontSize),
  lineHeight: asRem(lineHeight),
  fontWeight,
})

// -----------------------------------------------------------------------------
// Sorted from here on down
// -----------------------------------------------------------------------------

const absHeight: Mixins['absHeight'] = height => ({
  height,
  minHeight: height,
  maxHeight: height,
})

const absWidth: Mixins['absWidth'] = width => ({
  width,
  minWidth: width,
  maxWidth: width,
})

const flexWidth: Mixins['flexWidth'] = width => ({
  msFlexPreferredSize: width,
  flexBasis: width,
  maxWidth: width,
})

const acuminTextSizeR: Mixins['acuminTextSizeR'] = (
  size,
  height,
  weight = 600,
) => ({
  ...textSizeR(size, height, weight),
  fontFamily: acumin,
  fontStyle: 'normal',
  textTransform: 'uppercase',
})

const backgroundImage: Mixins['backgroundImage'] = url => ({
  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${url})`,
})

const borderS = (color = 'currentColor', width = 1) =>
  border(width, 'solid', color)

const clearfix: Mixins['clearfix'] = (display = 'block') => ({
  clear: 'both',
  content: '""',
  display,
})

const featuredMaxWidth = important(
  `calc(${px(
    featuredContentMaxWidth,
  )} + ${containedPaddingLeft} + ${containedPaddingRight})`,
)

const featuredContainer = /* istanbul ignore next */ () => ({
  maxWidth: featuredMaxWidth,
  marginRight: 'auto',
  marginLeft: 'auto',
})

const featuredContentContainer = /* istanbul ignore next */ () => ({
  maxWidth: featuredContentMaxWidth,
  marginRight: 'auto',
  marginLeft: 'auto',
})

const firefox: Mixins['firefox'] = content => ({
  '@supports (-moz-appearance:none)': content,
})

const hideForPrint: Mixins['hideForPrint'] = () =>
  ({
    '@media print': {
      display: important('none'),
    },
  }) as React.CSSProperties

const hideForScreen: Mixins['hideForScreen'] = () =>
  ({
    '@media screen': {
      display: important('none'),
    },
  }) as React.CSSProperties

const icon: Mixins['icon'] = (content, extras = true) => ({
  content: `'${content}'`,
  ...(extras
    ? {
        width: 'auto',
        margin: 0,
        fontStyle: 'normal',
        fontWeight: 'normal',
        speak: 'none',
        display: 'inline-block',
        textDecoration: 'inherit',
        lineHeight: em(1),
        textAlign: 'center',
        fontVariant: 'normal',
        textTransform: 'none',
        verticalAlign: 'middle',
        position: 'relative',
        top: -1,
        textRendering: 'optimizeLegibility',
      }
    : {}),
})

const iconAfter: Mixins['iconAfter'] = (content, extras = true, more = {}) => ({
  whiteSpace: 'nowrap',
  '&::after': {
    ...icon(content, extras),
    transition: 'all 150ms ease-in-out 0ms',
    marginLeft: asRem(0.5),
    ...more,
  },
})

const importantEm: Mixins['importantEm'] = (...args) =>
  Maybe.fromUndefined(em(...args))
    .map(important)
    .orJust('')
const importantPx: Mixins['importantPx'] = (...args) =>
  Maybe.fromUndefined(px(...args))
    .map(important)
    .orJust('')
const importantRem: Mixins['importantRem'] = (...args) =>
  Maybe.fromUndefined(rem(...args))
    .map(important)
    .orJust('')

const interTextSizeR: Mixins['interTextSizeR'] = (
  size,
  height,
  weight = 400,
) => ({
  ...textSizeR(size, height, weight),
  fontFamily: inter,
  fontStyle: 'normal',
})

const percentage = suffix('%')

const resetList = (clearfixToo = true, marginToo = true) => ({
  ...(clearfixToo ? clearfix() : {}),
  ...(marginToo ? { margin: 0 } : {}),
  padding: 0,
  listStyle: 'none',
  '&>li, &>dd': {
    margin: 0,
  },
})

const safari: Mixins['safari'] = content =>
  ({
    '@media not all and (min-resolution:.001dpcm)': {
      '@supports (-webkit-appearance:none)': content,
    },
  }) as React.CSSProperties

const size: Mixins['size'] = (height, width = height) => ({
  ...absHeight(height),
  ...absWidth(width),
})

const sizeR: Mixins['sizeR'] = (...args) =>
  polishedSize(...(args.map(asRem) as [NumOrStr, NumOrStr]))

const transition = (
  property = 'all',
  duration = 300,
  timing = '',
  delay = 0,
) => ({
  transition: `${property} ${duration}ms ${timing} ${delay}ms`,
})

const vertCenter: Mixins['vertCenter'] = (
  pos = 'relative',
  offset = '-50%',
) => ({
  transform: `translateY(${offset})`,
  position: pos,
  top: '50%',
})

const vertScrollFade: Mixins['vertScrollFade'] = (bgColor = '#fff') => ({
  position: 'relative',
  '&::after': {
    ...position('absolute', null, null, 0, 0),
    content: "''",
    width: percentage(100),
    height: asEm(5),
    backgroundImage: `linear-gradient( ${transparentize(
      1,
      bgColor,
    )}, ${bgColor})`,
    pointerEvents: 'none',
  },
})

const vertScrollIndicator: Mixins['vertScrollIndicator'] = (
  bgColor,
  shadowColor,
) => ({
  background: `
    linear-gradient(${bgColor} 30%, ${rgba(bgColor, 0)}),
    linear-gradient(${rgba(bgColor, 0)}, ${bgColor} 70%) 0 100%,
    radial-gradient(farthest-side at 50% 0, ${rgba(shadowColor, 0.2)}, ${rgba(
      shadowColor,
      0,
    )}),
    radial-gradient(farthest-side at 50% 100%, ${rgba(
      shadowColor,
      0.2,
    )}, ${rgba(shadowColor, 0)}) 0 100%`,
  backgroundRepeat: 'no-repeat',
  backgroundColor: bgColor,
  backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
  backgroundAttachment: 'local, local, scroll, scroll',
})

const when =
  (theme: Theme): Mixins['when'] =>
  (name, content, altContent = {}) => {
    const pass = theme.name === name || name?.includes(theme.name)
    return pass ? content : altContent
  }

const plugin = (theme: Theme) =>
  produce(theme, draft => {
    draft.mixins = {
      absHeight,
      absWidth,
      flexWidth,
      acuminTextSizeR,
      backgroundImage,
      borderS,
      clearfix,
      em,
      featuredContainer,
      featuredContentContainer,
      featuredMaxWidth,
      firefox,
      hideForPrint,
      hideForScreen,
      icon,
      iconAfter,
      important,
      importantEm,
      importantPx,
      importantRem,
      interTextSizeR,
      percentage,
      px,
      rem,
      resetList,
      safari,
      size,
      sizeR,
      textSizeR,
      transition,
      vertCenter,
      vertScrollFade,
      vertScrollIndicator,
      when: when(theme),
    }
  })

export default plugin

/* istanbul ignore next line */
export {
  absHeight,
  acuminTextSizeR,
  firefox,
  hideForPrint,
  hideForScreen,
  important,
  importantPx,
  importantRem,
  px,
  rem,
  vertCenter,
}
