import Icon from '@mui/material/Icon'
import IconButton, { type IconButtonOwnProps } from '@mui/material/IconButton'
import { Minimize2 } from 'react-feather'

const MinimizeIconButton = (props: IconButtonOwnProps) => (
  <IconButton
    aria-label="Minimize"
    data-bodyvariant="body1"
    data-expansionbutton="true"
    style={{
      position: 'fixed',
      height: 48,
      width: 48,
      top: 20,
      right: 20,
      backgroundColor: 'white',
      opacity: 1,
      padding: 8,
      zIndex: 1,
    }}
    {...props}>
    <Icon>
      {' '}
      <Minimize2 />{' '}
    </Icon>
  </IconButton>
)

export default MinimizeIconButton
