import type { SvgIconProps } from '@mui/material'
import { TreeItem2 } from '@mui/x-tree-view'
import type { Content } from 'reducers/@types/content'
import TreeEntryContent, {
  type TreeEntryContentProps,
} from './TreeEntryContent'

type NavTreeEntryChild = {
  href?: string
  key: string
  label: string
  rightsCheck?: () => boolean
  to?: string
}

type NavTreeEntry = NavTreeEntryChild & {
  Icon: React.FC<SvgIconProps>
  IconActive: React.FC<SvgIconProps>
  items: NavTreeEntryChild[]
  key: string
  matches?: boolean
}

type TreeEntryProps = Omit<React.HTMLProps<HTMLLIElement>, 'onFocus'> & {
  children?: React.ReactNode
  entry: NavTreeEntry | NavTreeEntryChild
  itemId: string
}

type NavItem = Content

const TreeEntry = ({ children, entry, itemId, ...rest }: TreeEntryProps) => (
  <TreeItem2
    {...rest}
    slots={{
      // we turn off the default icons as we provide our own
      collapseIcon: () => null,
      expandIcon: () => null,
      // this \could\ be broken up into separate slots.
      // right now the label, icon, etc are all done within TreeEntryContent
      content: TreeEntryContent,
    }}
    slotProps={
      {
        content: { entry },
      } as unknown as TreeEntryContentProps
    }
    itemId={itemId}
    variant="nav-shell">
    {children}
  </TreeItem2>
)

export default TreeEntry

export type { NavItem, NavTreeEntry, NavTreeEntryChild, TreeEntryProps }
