import Typography from '@mui/material/Typography'
import { componentShape, textAlignShape } from 'core/shapes'
import PropTypes from 'prop-types'
import Headline from './Headline'

const DualHeadline = props => {
  const {
    children,
    dividerBelow = false,
    multiline = false,
    subtitle,
    textAlign = 'inherit',
    title1,
    title2,
    ...rest
  } = props

  return (
    <Headline
      {...rest}
      dividerBelow={dividerBelow}
      hasSubtitle={Boolean(subtitle)}
      textAlign={textAlign}
      title={
        <>
          <span className="tr-typography tr-headline--weight-light">
            {title1}
          </span>
          {multiline ? <br /> : ' '}
          {title2}
        </>
      }
      variant="advanced-heading">
      {Boolean(subtitle) && (
        <Typography
          component="div"
          textAlign={textAlign}
          variant="heading-subtitle">
          {subtitle}
        </Typography>
      )}

      {children}
    </Headline>
  )
}

DualHeadline.propTypes = {
  children: componentShape,
  contained: PropTypes.bool,
  dividerBelow: PropTypes.bool,
  multiline: PropTypes.bool,
  swatch: PropTypes.bool,
  subtitle: componentShape,
  textAlign: textAlignShape,
  title1: componentShape.isRequired,
  title2: componentShape.isRequired,
}

export default DualHeadline
