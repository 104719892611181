import type { Theme } from '@mui/material'
import { important } from 'polished'
import type { Styles } from 'polished/lib/types/style'

const plugin = ({ typography }: Theme) => ({
  '.notistack-MuiContent': important({
    ...typography.variants.paragraph,
    fontWeight: 400,
  } as unknown as Styles),
})

export default plugin
