import ContainerQuery from 'common/layout/ContainerQuery'
import { assert, alter } from 'core/store/search/squery'
import withContextProps from 'hoc/withContextProps'
import withProps from 'hoc/withProps'
import Card from 'hss/views/Card'
import PosterImage from 'hss/views/Card/PosterImage'
import SearchItemRenderer from 'hss/views/Library/SearchItemRenderer'
import SearchBar from 'hss/views/Search/Filters/SearchBar'
import { SearchProvider, searchContext } from 'hss/views/Search/SearchProvider'
import { SEARCH_TYPE_LIBRARY } from 'hss/views/Search/consts'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { restEndpoint } from 'reducers/utils'
import { compose } from 'redux'
import Banner from 'routing/shells/NavShell/Banner'
import {
  getSearchableLibraryContentSubTypes,
  getSearchableLibraryContentTypes,
} from 'selectors/library'

const LibraryNavShell = () => {
  const contentTypes = useSelector(getSearchableLibraryContentTypes)
  const contentSubTypes = useSelector(getSearchableLibraryContentSubTypes)

  const searchConfig = useMemo(
    () => ({
      initialSquery: compose(
        alter.set.limit(10),
        alter.set.orderBy('name', 'asc'),
        alter.set.where('contentType').in(contentTypes),
        alter.set.modifier('withParents').is(true),
        alter.set.modifier('forLibrary').is(true),
      )(assert()),
      restEndpoint: restEndpoint.content,
    }),
    [contentTypes],
  )

  const CardRenderer = withContextProps(Card, searchContext, ['viewMode'])

  const ItemRenderer = withProps(CardRenderer, {
    ContentRenderer: SearchItemRenderer,
    gridModeDirection: 'ltr',
    gridModeImageAspectRatio: '16/9',
    ImageRenderer: withContextProps(
      withProps(PosterImage, { includeLinkToContent: true }),
      searchContext,
      ['viewMode'],
    ),
    listModeDirection: 'ltr',
    listModeImageAspectRatio: '1/1',
  })

  return (
    <SearchProvider
      contentSubTypes={contentSubTypes}
      contentTypes={contentTypes}
      ItemRenderer={ItemRenderer}
      searchConfig={searchConfig}
      searchType={SEARCH_TYPE_LIBRARY}
      tableId="library-search"
      viewMode="list">
      <Banner SearchComponent={<SearchBar />} />

      <ContainerQuery>
        <Outlet />
      </ContainerQuery>
    </SearchProvider>
  )
}

export default LibraryNavShell
