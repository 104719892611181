import classNames from 'classnames'
import { a11yContext } from 'core/a11y'
import { whenPresent } from 'fp/utils'
import { useCallback, useContext, useEffect } from 'react'

const isManaged = (node: HTMLElement): boolean =>
  node.dataset?.managedtabmode
    ? true
    : node.parentNode
      ? isManaged(node.parentNode as HTMLElement)
      : false

const withTabModeSupport = <P,>(WrappedComponent: React.ComponentType<P>) => {
  const Enhanced = ({
    className,
    ...rest
  }: React.HTMLAttributes<React.ComponentType<P>>) => {
    // istanbul ignore next
    const { setTabModeEnabled, tabModeEnabled } = useContext(a11yContext) || {}

    const handleKeyDown = useCallback(
      ({ key }: KeyboardEvent) => {
        if (key === 'Tab') {
          whenPresent(setTabModeEnabled, true)
        }
      },
      [setTabModeEnabled],
    )

    const handleMouseDown = useCallback(
      ({ target }: MouseEvent) => {
        if (!isManaged(target as HTMLElement)) {
          whenPresent(setTabModeEnabled, false)
        }
      },
      [setTabModeEnabled],
    )

    useEffect(() => {
      document.addEventListener('keydown', handleKeyDown)
      document.addEventListener('mousedown', handleMouseDown)
      return () => {
        document.removeEventListener('keydown', handleKeyDown)
        document.removeEventListener('mousedown', handleMouseDown)
      }
    }, [handleKeyDown, handleMouseDown])

    return (
      <WrappedComponent
        {...({
          className: classNames({
            [className || '']: true,
            'tab-mode-enabled': tabModeEnabled,
          }),
          ...rest,
        } as P)}
      />
    )
  }

  return Enhanced
}

export default withTabModeSupport
