import DeleteIcon from '@mui/icons-material/DeleteForever'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import { itemRendererProps } from 'common/formControls/lists/AdvancedList'
import { set } from 'fp/objects'
import withConfirm from 'hoc/withConfirm'
import { forwardRef, useCallback } from 'react'

const ConfirmButton = styled(withConfirm(IconButton))(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  minWidth: 'unset',
}))

const Item = forwardRef(({ index, item, replaceItem, removeItem }, ref) => {
  const { primaryWord, variations } = item

  const handleChange = useCallback(
    fieldName =>
      ({ target: { value } }) => {
        if (fieldName === 'variations') {
          value = value.split(',').map(v => v.trim())
        }

        replaceItem(index, set(fieldName, value)(item))
      },
    [index, item, replaceItem],
  )

  return (
    <Stack
      alignItems="center"
      component="div"
      flex={1}>
      <Stack
        border="1px dotted"
        borderColor="border.0"
        mb={2}
        p={2}
        position="relative"
        width="100%">
        <TextField
          fullWidth
          label="Primary Word"
          name={`contentWordBank[${index}].primaryWord`}
          onChange={handleChange('primaryWord')}
          ref={ref}
          value={primaryWord}
          required
        />
        <TextField
          fullWidth
          label="Variations"
          name={`contentWordBank[${index}].variations`}
          onChange={handleChange('variations')}
          ref={ref}
          value={variations}
        />

        <ConfirmButton
          aria-label={`Remove item ${index + 1}, ${primaryWord}`}
          confirmationMessage={`Are you sure you want to remove word set ${primaryWord}?`}
          confirmationTitle="Remove?"
          onClick={() => removeItem(index)}>
          <DeleteIcon />
        </ConfirmButton>
      </Stack>
    </Stack>
  )
})

Item.propTypes = itemRendererProps
export default Item
