import { Box, FormHelperText, Stack } from '@mui/material'
import type { Theme } from '@mui/material'
import { styled } from '@mui/material/styles'
import type {
  AssetUpload,
  AssetUploadType,
  AssetUploadVariant,
  AssetUploadedProps,
} from 'common/formControls/textInputs/RichTextEdit2/nodes/ImageNode/@types'
import SplitHeadline from 'common/text/SplitHeadline'
import { get, mapValues } from 'fp/objects'
import { prefix } from 'fp/strings'
import { takeX } from 'fp/utils'
import useAbilityCheck from 'hooks/useAbilityCheck'
import { useState } from 'react'
import { compose } from 'redux'
import { ABILITY_CONTENT_CREATION } from 'shared/consts'
import AssetPreviewer from './AssetPreviewer'
import CantoImporter from './CantoImporter'
import FileUploader from './FileUploader'
import GDriveImporter from './GDriveImporter'
import Provenance from './Provenance'
import RemoveAsset from './RemoveAsset'

type VariantLinkageInterface = 'cc' | 'audioDescription' | 'image' | 'thumb'

export interface VariantLinkage {
  formField: string
  variant: VariantLinkageInterface
}

interface AssetUploadedEvent {
  target: {
    value: AssetUploadedProps
  }
}

interface AssetUploaderProps extends React.ComponentPropsWithRef<'div'> {
  assetCode?: string
  disabled?: boolean
  headlineHelperText?: string
  helperText?: string
  label?: string
  labelProps?: object
  name: string
  onCantoFields?: (upload: AssetUpload) => void
  onUpload: (event: AssetUploadedEvent) => void
  onClear: () => void
  onUploadSuccess?: (upload: AssetUpload) => void
  required?: boolean
  uploadType: AssetUploadType
  uploadVariant?: AssetUploadVariant
  value?: AssetUpload
  variantLinkage?: VariantLinkage[]
  FileUploaderButtonProps?: object
}

const cropMarkWidth = 10

export const AssetSelectionWrapper = styled('div', {
  name: 'selects-AssetUploader',
})(
  ({
    theme: {
      mixins: { absWidth, borderS, px, rem },
      palette,
    },
  }: {
    theme: Theme
  }) => ({
    paddingBottom: rem(2),

    'audio, img, video': absWidth(250),

    img: {
      display: 'block',
      position: 'relative',
    },

    '.crop-marks': {
      ...borderS(palette.background.default, Math.round(cropMarkWidth / 3)),
      marginTop: cropMarkWidth - 1,
      position: 'relative',

      '&:before, &:after': {
        ...borderS(),
        content: '""',
        position: 'absolute',
        zIndex: -1,
      },
      '&:before': {
        borderWidth: px(0, 1),
        bottom: px(-cropMarkWidth),
        left: 0,
        right: 0,
        top: px(-cropMarkWidth),
      },
      '&:after': {
        borderWidth: px(1, 0),
        bottom: 0,
        left: px(-cropMarkWidth),
        right: px(-cropMarkWidth),
        top: 0,
      },
    },
  }),
)

const AssetUploader: React.FC<AssetUploaderProps> = props => {
  const {
    assetCode,
    disabled = false,
    headlineHelperText,
    helperText,
    label,
    labelProps,
    name,
    onCantoFields,
    onClear,
    onUpload,
    onUploadSuccess,
    required = false,
    uploadType,
    uploadVariant = 'full',
    value,
    FileUploaderButtonProps,
  } = props

  const isAudioDescription = name.includes('audioDescription')

  const canUploadOnly = !useAbilityCheck(ABILITY_CONTENT_CREATION)

  const handleUploadSuccess = (response: AssetUploadedProps) => {
    onUploadSuccess?.(response)
    onUpload({
      target: { value: response.generated?.[uploadVariant] || response },
    })
  }

  // biome-ignore lint/suspicious/noExplicitAny: TODO: figure out the canto signatures
  const handleCantoFields = (response: any, fieldAssignments: any) => {
    const mapper = compose(
      (field: string) => get(field)(response),
      prefix('metadata.fields.'),
      takeX(0),
    )
    const data: AssetUpload = mapValues(mapper)(fieldAssignments) as AssetUpload

    onCantoFields?.(data)
  }

  const [busy, setBusy] = useState(false)

  const removeButton =
    value && onClear ? <RemoveAsset onClick={onClear} /> : <span />

  return (
    <AssetSelectionWrapper>
      {label ? (
        <SplitHeadline
          left={`${label}${required ? ' *' : ''}`}
          mt={4}
          right={removeButton}
          {...labelProps}
        />
      ) : (
        removeButton
      )}

      {Boolean(headlineHelperText) && (
        <FormHelperText
          data-variant="block"
          sx={{ marginLeft: '0 !important' }}>
          {headlineHelperText}
        </FormHelperText>
      )}

      <Box
        alignItems="flex-start"
        display="flex"
        justifyContent="space-between"
        sx={{ flexFlow: 'row wrap' }}>
        {Boolean(value) && (
          <AssetPreviewer
            uploadType={uploadType}
            value={value}
          />
        )}

        <Stack
          alignItems="flex-start"
          gap={1}
          ml={2}>
          <Stack
            alignItems="stretch"
            gap={1}>
            {!canUploadOnly && (
              <>
                <CantoImporter
                  assetCode={assetCode}
                  disabled={busy || disabled}
                  onCantoFields={onCantoFields ? handleCantoFields : undefined}
                  onComplete={handleUploadSuccess}
                  previousCantoId={value?.cantoFileName}
                  setBusy={setBusy}
                  uploadType={uploadType}
                />

                <GDriveImporter
                  assetCode={assetCode}
                  disabled={busy || disabled}
                  isAudioDescription={isAudioDescription}
                  onComplete={handleUploadSuccess}
                  previousFileName={value?.gdriveFileName}
                  setBusy={setBusy}
                  uploadType={uploadType}
                />
              </>
            )}

            <FileUploader
              ButtonProps={FileUploaderButtonProps}
              disabled={busy || disabled}
              isAudioDescription={isAudioDescription}
              onClear={onClear}
              onUploadSuccess={handleUploadSuccess}
              uploadType={uploadType}
              value={value}
            />
          </Stack>

          {value && <Provenance value={value} />}
        </Stack>
      </Box>

      {Boolean(helperText) && (
        <Box
          color="error.main"
          mt={2}>
          {helperText}
        </Box>
      )}
    </AssetSelectionWrapper>
  )
}

export default AssetUploader
