import type { Theme } from '@mui/material'

const overrides = ({
  mixins: { important, rem, size, transition },
  palette,
  shadows,
}: Theme) => ({
  MuiToggleButton: {
    styleOverrides: {
      root: {
        backgroundColor: palette.background.default,
        border: `1px solid ${palette.grey[0]}`,
        borderRadius: 0,
        color: palette.text.primary,
        minWidth: 50,
        ...transition(),

        '&.Mui-selected': {
          backgroundColor: palette.common.black,
          color: palette.common.white,
        },

        '&.Mui-selected:hover': {
          backgroundColor: palette.common.black,
        },

        '&:hover:not(.Mui-selected)': {
          backgroundColor: palette.common.white,
          boxShadow: shadows.borderInsetButton,
          ...transition(),
        },

        '&.Mui-disabled [class*="-TeacherEdition-root"]': {
          color: palette.text.disabled,
        },
      },
    },
    variants: [
      {
        props: { variant: 'toolbutton' },
        style: {
          ...size(48),
          borderRightWidth: 0,
          fontSize: rem(1.6),
          '&:last-child': {
            borderRightWidth: 1,
          },
          '&:hover:not(.Mui-selected)': {
            backgroundColor: important(palette.common.white),
            color: important(palette.text.primary),
          },
          padding: 0,
        },
      },
    ],
  },
})

export default overrides
