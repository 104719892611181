import { get } from 'fp/objects'
import type { NavTreeEntry } from './TreeEntry'

const EntryIcon = ({ entry }: { entry: NavTreeEntry }) => {
  const { Icon, IconActive } = entry

  const selected = entry.matches || entry.items?.some(get('matches'))

  if (selected) {
    return (
      <div className="icon-container">
        <IconActive />
      </div>
    )
  }

  return (
    <div className="icon-container">
      {Boolean(Icon) && <Icon className="icon-unfilled" />}
      {Boolean(IconActive) && <IconActive className="icon-filled" />}
    </div>
  )
}

export default EntryIcon
