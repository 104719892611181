import type {
  Theme,
  TypographyPropsVariantOverrides,
  TypographyStyle,
} from '@mui/material'
import { arraySequence } from 'fp/arrays'
import { increment } from 'fp/numbers'
import { merge } from 'fp/objects'
import { percent } from 'fp/strings'
import { curryRight } from 'fp/utils'
import { produce } from 'immer'
import { border } from 'polished'
import type { FontWeight } from 'src/@types/mui-palette'
import type { NumOrStr } from 'src/@types/utils'

const mergeRight = (obj: React.CSSProperties) => curryRight(merge, obj)

const acumin = [
  'acumin-pro-extra-condensed',
  '-apple-system',
  'sans-serif',
].join(', ')
const inter = ['"Inter"', '-apple-system', 'sans-serif'].join(', ')

const fontFamily = inter

type Typography = Theme['typography']

const fonts = (theme: Theme) =>
  produce(theme, draft => {
    draft.typography = draft.typography || {}
    draft.typography.htmlFontSize = 10
    draft.typography.fontFamily = inter
  })

const headings = (
  { mixins: { important, importantRem, rem, size }, palette, spacing }: Theme,
  typography: Typography,
) => ({
  '&[class*="-headline--size-1"]': typography.h1,
  '&[class*="-headline--size-2"]': typography.h2,
  '&[class*="-headline--size-3"]': typography.h3,
  '&[class*="-headline--size-4"]': typography.h4,
  '&[class*="-headline--size-5"]': typography.h5,
  '&[class*="-headline--size-6"]': typography.h6,

  ...arraySequence(8)
    .map(increment)
    .map(i => i * 100)
    .reduce(
      (acc, i) => ({
        ...acc,
        [`&[class*="-headline--weight-${i}"]`]: { fontWeight: important(i) },
      }),
      {},
    ),

  '&[class*="-headline--weight-light"]': { fontWeight: important(300) },

  '&[class*="-headline--hr"]': {
    ...border('bottom', 1, 'solid', palette.grey[5]),
    marginBottom: rem(1.3),
    paddingBottom: rem(1.9),
  },

  '&[class*="-headline--dividerBelow"]': {
    '&[class*="-headline--hasSubtitle"]': {
      marginBottom: importantRem(2.4),
    },

    '&::after': {
      display: 'block',
      content: '""',
      borderTop: `2px solid ${palette.accent.dark}`,
      ...size(5, 130),
      margin: 'auto',
      marginTop: 30,
    },
  },

  '&[class*="-headline--mb"]': { marginBottom: spacing(1) },
  ...arraySequence(16)
    .map(increment)
    .reduce(
      (acc, i) => ({
        ...acc,
        [`&[class*="-headline--mb-${i}"]`]: { marginBottom: spacing(i) },
      }),
      {},
    ),

  '&[class*="-headline--mt"]': { marginTop: spacing(1) },
  ...arraySequence(16)
    .map(increment)
    .reduce(
      (acc, i) => ({
        ...acc,
        [`&[class*="-headline--mt-${i}"]`]: { marginTop: spacing(i) },
      }),
      {},
    ),

  ...['capitalize', 'lowercase', 'none', 'uppercase'].reduce(
    (acc, transform) => ({
      ...acc,
      [`&[class*="-headline--textTransform-${transform}"]`]: {
        textTransform: important(transform),
      },
    }),
    {},
  ),

  '&.content-heading': {
    ...typography.h4,
    textTransform: 'none',
    marginBottom: rem(3),
    // '&:not(first-of-type)': {
    marginTop: rem(6),
    // },
  },

  '&.source-title': {
    ...typography.h2,
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: rem(6),
    lineHeight: '93.5%',
    '&::after': {
      display: 'block',
      content: '""',
      borderTop: `2px solid ${palette.accent.dark}`,
      ...size(5, 130),
      margin: 'auto',
      marginTop: 30,
      marginBottom: rem(2.4),
    },
  },

  '&.sub-heading': {
    ...typography.h5,
    ...border('top', 1, 'solid', palette.grey[4]),
    fontSize: rem(2.2),
    lineHeight: percent(79.5),
    paddingTop: rem(4),
    textTransform: 'none',
    marginTop: rem(4.8),
  },
})

const text = ({ palette, mixins: { px } }: Theme) => ({
  '&[class*="--disabled"]': { color: palette.text.disabled },
  '&[class*="-contained"]': {
    paddingLeft: 'var(--containedPaddingLeft)',
    paddingRight: 'var(--containedPaddingRight)',
    letterSpacing: px(-0.1),
  },
  '&[class*="-swatch"]': {
    position: 'relative',
    '&::before': {
      content: "''",
      position: 'absolute',
      width: 17,
      height: '100%',
      left: 0,
    },
  },
})

const variants = ({
  mixins: {
    acuminTextSizeR,
    em,
    importantPx,
    interTextSizeR,
    px,
    rem,
    transition,
  },
  palette,
}: Theme): Record<keyof TypographyPropsVariantOverrides, TypographyStyle> => ({
  'attribute-name': {
    fontSize: px(11),
    fontWeight: 500,
    textTransform: 'uppercase',
  },

  answer: {
    color: palette.teacherEdition,
    fontStyle: 'italic',
    display: 'block',
  },

  body1: {
    ...interTextSizeR(1.4, px(24), 300),
    p: { marginBottom: px(24) },
  },

  'body1-semibold': {
    ...interTextSizeR(1.4, px(24), 600),
    p: { marginBottom: px(24) },
  },

  body2: {
    ...interTextSizeR(1.4, px(24)),
    color: palette.primary.contrastText,
    a: {
      color: palette.primary.contrastText,
      textDecoration: 'none',
    },
    p: { marginBottom: px(24) },
  },

  'body2-semibold': {
    ...interTextSizeR(1.4, px(24), 600),
    color: palette.primary.contrastText,
    a: {
      color: palette.primary.contrastText,
      textDecoration: 'none',
    },
    p: { marginBottom: px(24) },
  },

  callout: interTextSizeR(1.4, px(24), 300),

  caption: {
    ...interTextSizeR(1.4, px(24), 300),
    '& p:only-child, & p:last-child': {
      marginBottom: 14,
    },
  },

  definition: {
    ...acuminTextSizeR(4, '93.5%'),
    fontStyle: 'normal',
    marginBottom: px(24),
    textTransform: 'uppercase',
  },

  draft: {
    // stub -- filled out below
  },

  'drop-cap': {
    ...interTextSizeR(1.6, px(28), 300),

    '&:first-letter': {
      ...acuminTextSizeR(10, '79.5%'),
      float: 'left',
      marginTop: em(-0.06),
      marginRight: em(0.1),
      textTransform: 'uppercase',
    },
  },

  eyebrow: {
    ...interTextSizeR(1.2, px(12), 600),
    letterSpacing: em(0.1),
    lineHeight: px(20),
    marginBottom: px(16),
    textTransform: 'uppercase',
  },

  'feature-paragraph': {
    letterSpacing: px(-0.1),
    ...interTextSizeR(1.6, px(28), 300),
    p: { marginBottom: px(28) },
  },

  'popup-label': {
    fontSize: rem(1.2),
    fontWeight: 700,
    letterSpacing: px(1.5),
    lineHeight: 'normal',
    margin: rem(0, 0, 1.6, 0),
    textTransform: 'uppercase',
  },

  'feature-paragraph-semibold': {
    ...interTextSizeR(1.6, px(28), 600),
    p: { marginBottom: px(28) },
  },

  'feature-label-popup': {
    ...acuminTextSizeR(4, 4, 500),
    p: { marginBottom: px(28) },
    textTransform: 'none',
  },

  footnote: {
    fontSize: rem(1.2),
    fontStyle: 'italic',
  },

  'card-footer': interTextSizeR(1.2, 'normal'),

  greyscale: {
    color: palette.grey[3],
  },

  'grid-search-heading': {
    ...acuminTextSizeR(rem(2.6), '95%', 600),
    marginBottom: rem(1),
  },

  heroHeader: {
    // stub -- filled out below
  },

  highlighted: {
    backgroundColor: palette.accent.light,
  },

  instructions: {
    ...acuminTextSizeR(3.5, px(35.35), 400),
    textTransform: 'none',
    blockquote: interTextSizeR(1.4, px(24), 300),
  },

  'large-number': {
    ...acuminTextSizeR(10, '79.5%', 600),
    textTransform: 'uppercase',
  },

  'large-semibold': interTextSizeR(1.8, px(28), 600),

  link: {
    cursor: 'pointer',
    color: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    textDecoration: 'underline',
    textDecorationColor: 'inherit',
    textUnderlineOffset: rem(0.4),
    textDecorationThickness: 'from-font',
    ...transition('text-decoration-thickness', 250, 'ease-in-out'),

    '&:hover': {
      textDecorationThickness: rem(0.3),
      color: 'unset',
    },
  },

  'link-plain': {
    ...interTextSizeR(1.4, px(24), 600),
    color: 'inherit',
    textDecoration: 'none',
    a: {
      color: 'inherit',
      textDecoration: 'none',
    },
  },

  'link-semibold': {
    textDecoration: 'underline',
    fontWeight: 600,
  },

  'list-header': {
    ...acuminTextSizeR(2.5, px(25.25), 600),
    textTransform: 'none',
    marginBottom: 10,
  },

  'course-header': {
    ...interTextSizeR(2.6, px(26)),
    textTransform: 'none',
    lineHeight: 'normal',
    display: 'inline-block',
  },

  'multiple-choice': {
    ...interTextSizeR(1.6, px(24), 300),
    p: { marginBottom: 0 },
  },

  navigation: {
    ...interTextSizeR(1.4, px(14), 600),
    letterSpacing: em(0.1),
    textTransform: 'uppercase',
  },

  navigation2: {
    ...interTextSizeR(1.4, px(14), 500),
    color: palette.primary.contrastText,
    letterSpacing: em(0.1),
    textTransform: 'uppercase',
  },

  'nav-item': {
    fontSize: importantPx(14),
    lineHeight: importantPx(20),
    fontWeight: 400,
    textDecoration: 'none',
  },

  'nav-item-semibold': {
    fontSize: importantPx(14),
    fontWeight: 600,
    lineHeight: importantPx(20),
  },

  'nav-item-uppercase': {
    fontSize: importantPx(12),
    fontWeight: 400,
    letterSpacing: em(0.1),
    lineHeight: importantPx(20),
    textTransform: 'uppercase',
  },

  'nav-item-uppercase-semibold': {
    fontSize: importantPx(12),
    fontWeight: 600,
    letterSpacing: em(0.1),
    lineHeight: importantPx(20),
    textTransform: 'uppercase',
  },

  paragraph: {
    ...interTextSizeR(1.4, px(24), 300),
    p: { marginBottom: px(24) },
  },

  'paragraph-semibold': {
    ...interTextSizeR(1.4, px(24), 600),
    p: { marginBottom: px(24) },
  },

  'paragraph-dark-background': {
    ...interTextSizeR(1.4, px(24)),
    p: { marginBottom: px(24) },
    color: palette.primary.contrastText,
    a: {
      color: palette.primary.contrastText,
      textDecoration: 'none',
    },
  },

  'paragraph-dark-background-semibold': {
    ...interTextSizeR(1.4, px(24), 600),
    p: { marginBottom: px(24) },
    color: palette.primary.contrastText,
    a: {
      color: palette.primary.contrastText,
      textDecoration: 'none',
    },
  },

  'pull-quote': acuminTextSizeR(4.8, '95%', 400),

  'pull-quote-credit': interTextSizeR(1.8, px(28), 400),

  small: interTextSizeR(1.2, px(18)),

  'small-semibold': interTextSizeR(1.2, px(18), 600),

  'tab-title': {
    ...interTextSizeR(1.4, px(14), 500),
    letterSpacing: em(0.1),
    textTransform: 'uppercase',
  },

  'toc-title': {
    ...interTextSizeR(1.6, px(24)),
    display: 'block',
  },

  'toc-title-semibold': {
    ...interTextSizeR(1.6, px(24), 600),
    display: 'block',
  },
  'word-form': {
    ...acuminTextSizeR(3, '93.5%', 300),
    fontStyle: 'italic',
    textTransform: 'none',
  },
})

const plugin = (theme: Theme) =>
  produce(theme, draft => {
    const {
      mixins: { acuminTextSizeR, rem },
      palette,
    } = theme

    const acuminTextSizeCurried = (
      size: number,
      height: NumOrStr,
      weight: FontWeight = '600',
    ) => mergeRight(acuminTextSizeR(size, height, weight))

    draft.typography.variants = variants(theme)

    draft.typography.h1 = produce(
      draft.typography.h1,
      acuminTextSizeCurried(6.5, '90.5%'),
    )
    draft.typography.h2 = produce(
      draft.typography.h2,
      acuminTextSizeCurried(5, '100%'),
    )
    draft.typography.h3 = produce(
      draft.typography.h3,
      acuminTextSizeCurried(4, '93.5%'),
    )
    draft.typography.h4 = produce(
      draft.typography.h4,
      acuminTextSizeCurried(3, '93.5%'),
    )
    draft.typography.h5 = produce(
      draft.typography.h5,
      acuminTextSizeCurried(2.6, '79.5%'),
    )
    draft.typography.h6 = produce(
      draft.typography.h6,
      acuminTextSizeCurried(2.5, '101%'),
    )
    draft.typography.variants.heroHeader = produce(
      draft.typography.h1,
      acuminTextSizeCurried(8.5, '90.5%'),
    )

    draft.typography.h3.textTransform = 'capitalize'
    draft.typography.h4.textTransform = 'capitalize'

    // draft.typography.variants = variants(theme)

    draft.typography.variants.draft = {
      color: palette.common.red,
      margin: rem(0, 0.8),
      fontWeight: 400,
      svg: {
        height: rem(1.5),
      },
    }

    draft.globals.h1 = draft.typography.h1
    draft.globals.h2 = draft.typography.h2
    draft.globals.h3 = draft.typography.h3
    draft.globals.h4 = draft.typography.h4
    draft.globals.h5 = draft.typography.h5
    draft.globals.h6 = draft.typography.h6
    draft.globals.heroHeader = draft.typography.h1

    draft.globals['.tr-typography'] = {
      ...headings(theme, draft.typography),
      ...text(theme),
    }
    // draft.globals['.draft'] = draft.typography.variants
    draft.globals['.greyscale'] = draft.typography.variants.greyscale
  })

export default plugin

export { acumin, fontFamily, fonts, inter }
