import MuiTextField, { type TextFieldProps } from '@mui/material/TextField'
import type { DatePickerProps } from '@mui/x-date-pickers'
import { format as formatIt, isValid, set } from 'date-fns/fp'
import { get } from 'fp/objects'
import { prefix } from 'fp/strings'
import { now } from 'locale/dates'
import { tzAbbr } from 'locale/timezones'
import { Maybe } from 'monet'
import { createElement, useMemo } from 'react'
import { compose } from 'redux'

interface Props<T extends DatePickerProps<Date>> {
  Component: React.ComponentType<T>
  format: string
  helperText?: React.ReactNode
  name: string
  value?: string | Date
}

const Base = <T extends DatePickerProps<Date>>({
  Component,
  helperText,
  format,
  value,
  ...rest
}: Props<T>) => {
  const exampleInput = useMemo(
    () => compose(formatIt(format), set({ minutes: 0, seconds: 0 }), now)(),
    [format],
  )

  const valueTimeZoneText = Maybe.fromFalsy(tzAbbr(value))
    .map(prefix('Time zone: '))
    .orNull()
  const exampleInputText = Maybe.fromFalsy(exampleInput)
    .map(prefix('Example: '))
    .orNull()

  return createElement(Component, {
    fullWidth: false,
    disableMaskedInput: true,

    textField: (props: TextFieldProps) => (
      <MuiTextField
        {...props}
        helperText={
          helperText || (isValid(value) ? valueTimeZoneText : exampleInputText)
        }
      />
    ),
    ...({ format, value } as T),
    ...rest,
    inputProps: {
      ...get('inputProps')(rest),
      placeholder: '',
    },
  } as T)
}

export default Base
