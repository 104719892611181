import type { Theme } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledDuration = styled('div', { name: 'duration' })(
  ({ theme: { breakpoints, palette, typography } }: { theme: Theme }) => ({
    position: 'absolute',
    zIndex: 2,
    backgroundColor: palette.video.main,
    color: palette.primary.contrastText,
    ...typography.variants['feature-paragraph-semibold'],
    lineHeight: 1,
    padding: 12,
    bottom: '8%',
    right: '6%',
    [breakpoints.down('sm')]: {
      padding: 8,
    },
  }),
)

export default StyledDuration
