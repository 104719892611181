import { SimpleTreeView } from '@mui/x-tree-view'
import useLocalSetting from 'hooks/useLocalSetting'
import { useId } from 'react'
import TreeEntry, { type NavTreeEntry } from './TreeEntry'

const AsTree = ({ entries }: { entries: NavTreeEntry[] }) => {
  const [expanded, setExpanded] = useLocalSetting('NavShell-expanded', [])
  const id = useId()

  const handleToggle = (_: React.SyntheticEvent, itemIds: string[]) => {
    setExpanded(itemIds)
  }

  return (
    <SimpleTreeView
      aria-label="site navigation tree"
      expandedItems={expanded}
      id={id}
      onExpandedItemsChange={handleToggle}
      variant="nav-shell">
      {entries.map(entry => (
        <TreeEntry
          data-haschildren={Boolean(entry.items.length)}
          entry={entry}
          key={entry.key}
          itemId={entry.key}>
          {entry.items.map(childEntry => (
            <TreeEntry
              entry={childEntry}
              key={childEntry.key}
              itemId={childEntry.key}
            />
          ))}
        </TreeEntry>
      ))}
    </SimpleTreeView>
  )
}

export default AsTree
