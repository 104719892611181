import type { Theme } from '@mui/material'

const plugin = ({ mixins: { em, important } }: Theme) => ({
  '.print-forbidden': {
    userSelect: important('none'),
  },

  '.print-only': {
    display: 'none',
  },

  '@media print': {
    '.print-forbidden': {
      visibility: 'hidden',
      height: em(2),
      overflow: 'hidden',
    },

    '.print-forbidden::before': {
      content: 'attr(print-forbidden-message)',
      visibility: 'visible',
      lineHeight: em(2),
    },

    '.print-only': {
      display: 'initial',
    },
  },
})

export default plugin
