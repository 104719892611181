import MuiTextField, {
  type TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField'
import { type ReactNode, createElement, forwardRef, useId } from 'react'

type TextFieldProps = MuiTextFieldProps & {
  id?: string
  helperText?: ReactNode
  name?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  rules?: Record<string, unknown>
  value?: string
}

const TextField = forwardRef(
  ({ id, name, value, ...rest }: TextFieldProps, inputRef) => {
    const generatedId = useId()

    return createElement(MuiTextField, {
      fullWidth: true,
      id: id || generatedId,
      name,
      inputRef,
      value: value ?? undefined, // If value is nullish, set to `undefined` to avoid a React Warning
      ...rest,
    })
  },
)

export default TextField
