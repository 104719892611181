import type { Theme } from '@mui/material'
import CheckedHover from 'hss/images/controls/radio/radio-checked-hover.svg'
import Checked from 'hss/images/controls/radio/radio-checked.svg'
import UncheckedHover from 'hss/images/controls/radio/radio-hover.svg'
import Unchecked from 'hss/images/controls/radio/radio.svg'
import { radiosAndCheckboxes } from './FormControlLabel'

const overrides = (theme: Theme) => {
  const {
    mixins: { rem, sizeR },
    palette,
  } = theme

  return {
    MuiRadio: {
      defaultProps: {
        checkedIcon: (
          <>
            <Checked className="checked" />
            <CheckedHover className="checked-hover" />
          </>
        ),
        color: 'primary',
        'data-color': 'primary',
        disableRipple: true,
        icon: (
          <>
            <Unchecked className="unchecked" />
            <UncheckedHover className="unchecked-hover" />
          </>
        ),
      },

      styleOverrides: {
        root: {
          ...sizeR(2.4, 4.8),
          margin: rem(0.5, 0),

          '&:not(.Mui-checked).Mui-disabled svg': { color: palette.grey[3] },

          '&:hover': {
            backgroundColor: 'unset',
          },
        },
      },

      variants: [
        {
          props: { 'data-color': 'primary' },
          style: radiosAndCheckboxes(theme, palette.cobalt),
        },

        {
          props: { 'data-color': 'secondary' },
          style: radiosAndCheckboxes(theme, palette.grey),
        },
      ],
    },
  }
}

export default overrides
