import { whenPresent } from 'fp/utils'
import { useContext, useEffect } from 'react'
import type { BrandColor } from 'src/@types/mui-palette'
import { context } from './ThemeProvider'

type AccentColorProviderProps = {
  accentColor: BrandColor
  children: React.ReactNode
}

const AccentColorProvider = ({
  accentColor,
  children,
}: AccentColorProviderProps) => {
  /* istanbul ignore next */
  const { setAccentColor } = useContext(context) || {}

  useEffect(() => {
    whenPresent(setAccentColor, accentColor)
  }, [accentColor, setAccentColor])

  return children
}

export default AccentColorProvider
