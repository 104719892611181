import { Button, DialogActions, Popover, Typography } from '@mui/material'
import { getImageUrlFromUploadsMap } from 'common/avclub/utils'
import Html from 'common/text/Html'
import { type ForwardedRef, forwardRef } from 'react'
import { useImageSelectionContext } from '../ImageSelectionProvider'

const SelectedItem = forwardRef((_, ref: ForwardedRef<Element>) => {
  const {
    displayMode,
    selectedSearchResult,
    setSelectedSearchResult,
    setUpload,
    setUploadStep,
    onComplete,
    size,
    uploadVariant,
    valign,
  } = useImageSelectionContext()

  const handleClose = () => setSelectedSearchResult(undefined)

  const handleContinue = () => {
    if (selectedSearchResult?.id) {
      const upload =
        selectedSearchResult.uploadsMap?.[uploadVariant] ||
        selectedSearchResult.uploadsMap?.imageLarge ||
        selectedSearchResult.uploadsMap?.imageMedium ||
        selectedSearchResult.uploadsMap?.imageSmall ||
        selectedSearchResult.uploadsMap?.imageTiny

      if (displayMode === 'inline') {
        setUploadStep('annotate')
        setUpload(upload)
      } else {
        onComplete({
          displayMode,
          interactiveId: selectedSearchResult.id,
          size,
          upload,
          valign,
        })
      }
    }
    handleClose()
  }

  const url = getImageUrlFromUploadsMap(selectedSearchResult?.uploadsMap)

  return (
    <div>
      <Popover
        id="image-search-selected-item"
        onClose={handleClose}
        anchorEl={(ref as React.MutableRefObject<Element | null>)?.current}
        open={Boolean(selectedSearchResult)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <DialogActions>
          <Button
            variant="secondary"
            onClick={handleContinue}>
            Use this image
          </Button>

          <Button
            onClick={handleClose}
            variant="primary">
            Go back
          </Button>
        </DialogActions>

        <figure>
          <img
            src={url}
            alt={selectedSearchResult?.data?.altText}
            style={{
              objectFit: 'cover',
              width: '100%',
            }}
          />

          <figcaption>
            <Typography
              component="div"
              variant="feature-paragraph"
              px={2}>
              <Html body={selectedSearchResult?.data?.caption} />
            </Typography>
          </figcaption>
        </figure>
      </Popover>
    </div>
  )
})

export default SelectedItem
