import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import type { FormControlLabelProps } from '@mui/material/FormControlLabel'
import MuiRadio from '@mui/material/Radio'
import type { RadioProps as MuiRadioProps } from '@mui/material/Radio'
import Typography from '@mui/material/Typography'
import { type ReactNode, forwardRef, useId } from 'react'
import { rem } from 'styling/theming/base/mixins'

interface RadioProps extends FormControlLabelProps {
  value: number | string
  description?: ReactNode
  color: MuiRadioProps['color']
}

const Radio = forwardRef<HTMLLabelElement, RadioProps>((props, ref) => {
  const { color = 'primary', description, label, value, ...rest } = props
  const descriptionId = useId()

  return (
    <>
      <FormControlLabel
        aria-describedby={description ? descriptionId : undefined}
        data-controltype="radio"
        label={<Typography variant="multiple-choice">{label}</Typography>}
        {...rest}
        control={
          <MuiRadio
            color={color}
            data-color={color}
            sx={{
              padding: 2,
              width: rem(6),
              svg: {
                overflow: 'visible',
              },
            }}
          />
        }
        ref={ref}
        value={value}
      />
      {Boolean(description) && (
        <Box
          id={descriptionId}
          mb={1}
          ml={6.8}>
          {description}
        </Box>
      )}
    </>
  )
})

export default Radio
