import { styled } from '@mui/material/styles'
import withContainerQuerySize from 'hoc/withContainerQuerySize'

/** TODO: make this voiceover friendly in mobile
 * Safari doesn't read as a table when the display properties are messed with
 * https://adrianroselli.com/2018/05/functions-to-add-aria-to-tables-and-lists.html
 */
const ResponsiveTable = styled('table', { name: 'responsive-table' })(
  ({
    theme: {
      mixins: { interTextSizeR, borderS, important, rem },
      palette,
      typography: { variants },
    },
  }) => ({
    borderCollapse: 'collapse',
    counterReset: 'number',
    padding: rem(2),
    tableLayout: ' fixed',
    width: '100%',
    th: {
      padding: rem(1),
      ...variants['nav-item-uppercase-semibold'],
      background: important(palette.background.paper),
      ...borderS(palette.border[0]),
      textAlign: 'center',
      '&:last-child.remove': {
        background: important('transparent'),
        border: important('none'),
        width: 44,
      },
    },
    td: {
      ...borderS(palette.border[0]),
      background: important(palette.background.paper),
      padding: 10,
      position: 'relative',
      verticalAlign: 'top',
      '&:last-child.remove': {
        background: important('transparent'),
        border: important('none'),
        verticalAlign: 'middle',
      },
      wordWrap: 'break-word',
      p: { margin: 0 },
    },
    textarea: {
      background: 'transparent',
      border: 0,
      minHeight: 0,
      maxHeight: 'unset',
    },
    '.MuiInput-root::before': {
      border: 0,
    },
    '.DraftEditor-root': {
      // Make room for the help button by padding the right instead of the bottom.
      // This results in less empty space inside table cells.
      paddingRight: rem(4),
      '.DraftEditor-editorContainer [data-contents="true"]': {
        paddingBottom: 0,
      },
    },
    '&[data-containersize=xs],&[data-containersize=sm]': {
      // experiment showing table as stacked columns on smaller screens
      td: {
        border: 0,
        display: 'block',
        paddingTop: 25,
        position: 'relative',
        height: important('auto'),
        '&::before': {
          ...interTextSizeR(1.2, 'normal', 600),
          color: palette.grey[1],
          letterSpacing: '0.1em',
          textTransform: 'uppercase',
          content: 'attr(data-title)',
          display: 'block',
          marginBottom: 10,
          textAlign: 'center',
        },
      },
      thead: { display: 'none' },
      th: {
        padding: 0,
        display: 'block',
        textAlign: 'left',
      },
      tr: {
        display: 'block',
        padding: rem(0, 0, 4),
        '&::before': {
          ...interTextSizeR(1.4, 'normal', 600),
          letterSpacing: '0.1em',
          textTransform: 'uppercase',
          counterIncrement: 'number',
          content: '"Row #" counter(number)',
          display: 'block',
          marginBottom: rem(1),
        },
      },
      'tr:last-child': {
        padding: rem(0),
      },
    },
  }),
)

const WrappedTable = withContainerQuerySize(ResponsiveTable)

export default WrappedTable
