import actionTypes from 'reducers/actionTypes'
import type { Action } from 'redux'
import { failure, success } from 'sagas/utils'

// TODO: we should build an enum of action types
export const resolve = ({ type }: Action) =>
  type === success(actionTypes.UPLOAD_MARK_COMPLETE)

export const reject = ({ type }: Action) =>
  [
    failure(actionTypes.UPLOAD_INITIATE),
    failure(actionTypes.UPLOAD_MARK_COMPLETE),
    failure(actionTypes.UPLOAD_REQUEST),
  ].includes(type)

export const acceptMap = {
  document: 'document/*',
  image: 'image/*',
  video: 'video/*',
  audio: 'audio/*',
  vtt: 'text/plain,text/vtt,.txt,.vtt',
}

export const openFileDialog = inputRef => {
  inputRef?.current?.click()
}
