import 'swiper/css/bundle'
import type { Theme } from '@mui/material'
import { important as allImportant } from 'polished'

const plugin = ({ palette, zIndex, mixins: { important } }: Theme) => ({
  'ul.swiper-wrapper': {
    padding: 0,
    marginTop: 32,
  },

  '.swiper': {
    display: important('flex'),

    '.MuiIconButton-root': {
      borderColor: important(palette.border[1]),
    },
  },

  '.swiper-pagination': {
    ...allImportant({
      bottom: 'unset',
      left: 'unset',
      textAlign: 'right',
      paddingBottom: '24px',
    }),
  },

  '.swiper-pagination-bullet': {
    backgroundColor: palette.grey[2],
    opacity: important(1),
  },

  '.swiper-pagination-bullet-active': {
    backgroundColor: palette.pagination,
  },

  '.carousel-nav': {
    backgroundColor: palette.primary.contrastText,
    zIndex: zIndex.speedDial,
  },
})

export default plugin
