import type { Theme } from '@mui/material'

const overrides = ({ mixins: { borderS, rem }, palette }: Theme) => ({
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },

    variants: [
      {
        props: { variant: 'form-section-dense' },
        style: {
          ...borderS(palette.border[1]),
          backgroundColor: palette.border[2],
          padding: rem(2, 2, 2),
          borderTopStyle: 'none',
          '.MuiFormControl-marginNormal': {
            marginTop: 0,
            marginBottom: rem(1.5),
          },
        },
      },

      {
        props: { variant: 'interactive-grading' },
        style: {
          padding: rem(2, 0),
        },
      },
      {
        props: { variant: 'rubric-display' },
        style: {
          margin: rem(2),
          div: { marginBottom: rem(1) },
        },
      },
    ],
  },
})

export default overrides
