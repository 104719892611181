import ExpandLessRounded from '@mui/icons-material/ExpandLessRounded'
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded'
import Fab from '@mui/material/Fab'
import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { sidebarContext } from 'routing/shells/SidebarProvider'

const CollapseButton = styled(
  ({ className }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const context = useContext(sidebarContext)
    const { isSidebarShrunk, setSidebarShrunk, shrinkButtonRef, sidebarWidth } =
      context

    const handleClick = () => {
      if (isSidebarShrunk && location.search.match(/feedback/g)) {
        navigate(`${location.pathname}`)
      }
      setSidebarShrunk(!isSidebarShrunk)
    }

    return (
      <Tooltip title={`${isSidebarShrunk ? 'Expand' : 'Collapse'} Menu`}>
        <Fab
          aria-expanded={!isSidebarShrunk}
          aria-label="Toggle navigation size"
          className={className}
          data-testid="toc-collapse"
          onClick={handleClick}
          ref={shrinkButtonRef}
          size="small"
          style={{
            transform: `translate(${sidebarWidth - 15}px, 0) rotate(-90deg)`,
            boxShadow: 'none',
          }}>
          {isSidebarShrunk ? <ExpandMoreRounded /> : <ExpandLessRounded />}
        </Fab>
      </Tooltip>
    )
  },
  {
    name: 'AppSidebar-CollapseButton',
  },
)(
  ({
    theme: {
      mixins: { borderS, hideForPrint, important, size },
      palette,
      zIndex,
    },
  }) => ({
    ...size(30),
    transition: important('transform 300ms 0ms, background-color 300ms 0ms'),
    position: 'fixed',
    top: 25,
    zIndex: zIndex.drawer + 1,
    ...borderS(palette.border[4]),
    ...hideForPrint(),
  }),
)

export default CollapseButton
