import { isObject } from 'fp/objects'
import { useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'

const useStateWithDynamicDefault = <T>(defaultVal: T) => {
  const [state, setState] = useState<T>(defaultVal)

  /**
   * We're disabling this rule here because we ONLY want this to fire if defaultVal
   * changes, NOT when state does.
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies(state): expected
  useEffect(() => {
    if (!(isObject(defaultVal) && isEqual(defaultVal, state))) {
      setState(defaultVal)
    }
  }, [defaultVal])

  return [state, setState] as const
}

export default useStateWithDynamicDefault
