import type { Theme } from '@mui/material'
import { CARD_IMAGE_MAX_WIDTH } from 'hss/views/Card/PosterImage'
import { margin, padding } from 'polished'

const overrides = ({
  breakpoints,
  mixins: { borderS, rem, importantPx, importantRem },
  palette,
  shadows,
}: Theme) => ({
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 0,
        width: 'auto',
        minHeight: 318,

        '.tr-typography': {
          '&::before': {
            width: importantPx(12),
            top: -22,
            left: importantPx(-25),
            height: importantRem(6),
          },
        },

        '.MuiButton-root': {
          textTransform: 'none',
        },

        '.MuiCardHeader-root': {
          padding: rem(2.5),
          paddingBottom: 0,
        },
        '.MuiCardContent-root': {
          padding: rem(2.4),
          paddingBottom: rem(1),
          margin: 0,
        },
        '.MuiDivider-root': {
          ...margin(16, -24),
          borderColor: palette.border[2],
        },
        '.MuiTypography-root': {
          textAlign: 'left',
        },
        '.MuiCardActions-root': {
          justifyContent: 'space-evenly',
          paddingBottom: rem(3),
        },
        '.CardFooter': {
          padding: rem(2.5),
          paddingTop: rem(1),
        },

        a: {
          color: 'inherit',
        },
      },
    },
    variants: [
      {
        props: { variant: 'horizontal' },
        style: {
          boxShadow: shadows[3],
          display: 'flex',
          justifyContent: 'space-between',
          maxWidth: 'unset',
          width: '100%',
          '.MuiCardMedia-root': {
            maxWidth: CARD_IMAGE_MAX_WIDTH,
          },
          '.MuiCardHeader-root': {
            padding: rem(5),
            paddingBottom: 0,
          },
          '.MuiCardContent-root': {
            padding: rem(2, 5),
          },
          '.MuiDivider-root': margin(0),
          '.MuiCardActions-root': {
            justifyContent: 'flex-start',
            padding: rem(5),
            paddingTop: 0,
          },
          '.CardFooter': {
            padding: rem(3, 5),
          },
          [breakpoints.down('sm')]: {
            flexDirection: 'column',
            maxWidth: 294,
            maxHeight: 'unset',
          },
        },
      },

      {
        props: { 'data-contained': 'true' },
        style: { maxWidth: 875 },
      },

      {
        props: { variant: 'vertical-flex' },
        style: { display: 'flex', flexDirection: 'column' },
      },

      {
        props: { variant: 'vertical-grid' },
        style: { maxWidth: 'unset' },
      },

      {
        props: { variant: 'simple' },
        style: {
          background: 'none',
          boxShadow: 'none',
          '.MuiCardContent-root': padding(16, 0),
        },
      },

      {
        props: { variant: 'table' },
        style: {
          ...borderS(palette.grey[5]),
          boxShadow: shadows[3],
          maxWidth: 'unset',
          width: 'calc(100% - 1rem)',
          padding: rem(1.2),
          margin: rem(1, 0),
          '.MuiFormControl-fullWidth': {
            margin: rem(1, 0),
            width: 220,
            '.MuiAutocomplete-input': {
              position: 'relative',
              top: -2,
            },
            fieldset: {
              maxHeight: 44,
              top: 0,
              legend: { display: 'none' },
            },
          },
        },
      },

      {
        props: { 'data-direction': 'reverse' },
        style: {
          [breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
          },
        },
      },
    ],
  },
})

export default overrides
