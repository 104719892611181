import type { IconButtonProps } from '@mui/material'
import { IconButton } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import cl from 'classnames'
import withProps from 'hoc/withProps'
import { border, position } from 'polished'
import type { Theme } from '@mui/material'

export const StyledToolButton = styled(
  withProps(IconButton, {
    size: 'large',
  }),
  { name: 'ToolbarPlugin-ToolButton' },
)(
  ({
    theme: {
      mixins: { important, importantPx, rem, size, transition },
      palette,
    },
  }: { theme: Theme }) => ({
    ...size(47.4),
    ...transition('all', 250),

    border: '1px solid',
    borderColor: palette.grey[0],
    borderRadius: important(0),
    fontSize: rem(1.6),
    marginRight: importantPx(-1),
    color: important(palette.text.secondary),
    svg: { color: 'inherit' },
    '&:disabled': { color: important(palette.text.disabled) },
    '&:hover': {
      backgroundColor: palette.grey.contrastText,
      color: important(palette.grey[0]),
    },

    '&.active': {
      backgroundColor: palette.grey[0],
      color: important(palette.grey.contrastText as string),
      '&:hover': {
        backgroundColor: important(palette.grey[1]),
      },
    },

    '&.showDropdownIndicator::after': {
      content: '""',
      ...size(0),
      ...border('bottom', 5, 'solid', 'currentColor'),
      ...border('left', 5, 'solid', 'transparent'),
      ...position('absolute', null, 3, 3, null),
    },
  }),
) as unknown as typeof IconButton

export interface ToolButtonProps extends IconButtonProps {
  active?: boolean
  className?: string
  children: React.ReactNode
  disabled?: boolean
  excludeToolButtons?: string[]
  label?: string
  onClick?: () => void
  showDropdownIndicator?: boolean
}

const ToolButton: React.FC<ToolButtonProps> = props => {
  const {
    active,
    children,
    disabled,
    excludeToolButtons,
    label,
    showDropdownIndicator,
    ...rest
  } = props

  const btnClassName = cl({
    active,
    showDropdownIndicator,
  })

  return (
    <Tooltip title={disabled ? '' : label}>
      <StyledToolButton
        {...rest}
        aria-label={label}
        className={btnClassName}
        disabled={disabled}>
        {children}
      </StyledToolButton>
    </Tooltip>
  )
}

export default ToolButton
