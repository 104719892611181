import Typography from '@mui/material/Typography'
import useNavigation from 'hooks/useNavigation'
import { forwardRef, useMemo } from 'react'
import type { To } from 'react-router-dom'

/**
 * Largely derived from react-router-dom/Link
 * https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/Link.js
 *
 * Modified so that it plays nice with pinned routes
 */

const isModifiedEvent = (event: React.MouseEvent<HTMLAnchorElement>) =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

type LinkProps = React.HTMLAttributes<HTMLAnchorElement> & {
  disabled?: boolean
  href?: string
  replace?: boolean
  skipTransition?: boolean
  text?: boolean
  to: To
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      onClick,
      replace,
      skipTransition,
      style: propStyle,
      text,
      to,
      disabled,
      ...rest
    },
    ref,
  ) => {
    const { hrefFromTo, navigate } = useNavigation()

    const href = useMemo(() => hrefFromTo(to), [hrefFromTo, to])

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
      try {
        if (onClick) onClick(event)
      } catch (ex) {
        event.preventDefault()
        throw ex
      }

      if (
        !event.defaultPrevented && // onClick prevented default
        event.button === 0 && // ignore everything but left clicks
        !isModifiedEvent(event) // ignore clicks with modifier keys
      ) {
        event.preventDefault()

        if (rest.href !== '#') {
          navigate(to, { replace, skipTransition })
        }
      }
    }

    const style = {
      ...propStyle,
      ...(text ? { color: 'currentColor' } : null),
    }

    return (
      <Typography
        {...{
          ...rest,
          role: 'link',
          ...(disabled
            ? {
                disabled: true,
                variant: 'nav-item-uppercase-semibold',
              }
            : {
                component: 'a',
                variant: 'link',
                href,
                onClick: handleClick,
                ref,
                style,
              }),
        }}
      />
    )
  },
)

export default Link

export { isModifiedEvent }
