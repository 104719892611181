import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { componentShape } from 'core/shapes'
import Breadcrumbs from 'hss/sections/Breadcrumbs'
import { useContext } from 'react'
import { hideForPrint } from 'styling/theming/base/mixins'
import { navDialogsContext } from '../NavDialogsProvider'
import ProfileUserAlertsMenu from './ProfileUserAlertsMenu'
import UserMenu from './UserMenu'

const Banner = ({ SearchComponent }) => {
  const {
    profileUserAlertsIsOpen: showProfileAlerts,
    toggleProfileUserAlertsIsOpen,
  } = useContext(navDialogsContext)
  return (
    <AppBar
      position="sticky"
      sx={hideForPrint()}>
      <Toolbar variant="appbar">
        <Breadcrumbs />

        <div style={{ display: 'flex' }}>
          {SearchComponent}

          <ProfileUserAlertsMenu
            showProfileAlerts={showProfileAlerts}
            toggleProfileUserAlertsIsOpen={toggleProfileUserAlertsIsOpen}
          />

          <UserMenu />
        </div>
      </Toolbar>
    </AppBar>
  )
}

Banner.propTypes = {
  SearchComponent: componentShape,
}

export default Banner
