import Typography from '@mui/material/Typography'
import cl from 'classnames'
import { useMatch } from 'react-router-dom'
import type { NavTreeEntry } from './TreeEntry'

const TreeEntryLabel = ({ entry }: { entry: NavTreeEntry }) => {
  const match = useMatch({ path: entry.to || '/404' })

  const leaf = !entry.items

  return (
    <Typography
      className={cl({ leaf })}
      component="div"
      variant={
        match
          ? leaf
            ? 'nav-item-semibold'
            : 'nav-item-uppercase-semibold'
          : leaf
            ? 'nav-item'
            : 'nav-item-uppercase'
      }>
      {entry.label}
    </Typography>
  )
}

export default TreeEntryLabel
