import Icon from '@mui/material/Icon'
import IconButton, { type IconButtonOwnProps } from '@mui/material/IconButton'
import { Maximize2 } from 'react-feather'

const MaximizeIconButton = (props: IconButtonOwnProps) => (
  <IconButton
    aria-label="Maximize"
    data-bodyvariant="body1"
    data-expansionbutton="true"
    style={{
      position: 'absolute',
      height: 48,
      width: 48,
      top: 20,
      right: 20,
      backgroundColor: 'white',
      opacity: 0.8,
      padding: 8,
      zIndex: 2,
    }}
    {...props}>
    <Icon>
      {' '}
      <Maximize2 />{' '}
    </Icon>
  </IconButton>
)

export default MaximizeIconButton
