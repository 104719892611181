import scrollbarMeasure from 'scrollbar-size'

type ThemeConstructor = <T>(theme: T) => T

const add =
  <T>(key: keyof T, value: T[keyof T]) =>
  (theme: T) => ({ ...theme, [key]: value })

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
}

const containedPaddingLeft = '7.1rem'
const containedPaddingRight = '6rem'

const echoTabSize = 96
const echoMaxPaneWidth = 567

const featuredContentMaxWidth = 718

const scrollbarSize = scrollbarMeasure()

export {
  add,
  breakpoints,
  containedPaddingLeft,
  containedPaddingRight,
  echoTabSize,
  echoMaxPaneWidth,
  featuredContentMaxWidth,
  scrollbarSize,
}

export type { ThemeConstructor }
