import type { Theme } from '@mui/material'

import { arraySequence } from 'fp/arrays'
import { produce } from 'immer'
import { transparentize } from 'polished'

const plugin = (theme: Theme & { palette: { shadow: string } }) =>
  produce(theme, draft => {
    const shadowOpacity = 0.9

    const createShadow = (...px: number[]) =>
      `${px[0]}px ${px[1]}px ${px[2]}px ${px[3]}px ${transparentize(
        px[4] || shadowOpacity,
        theme.palette.shadow,
      )}`
    const border = '0 0 0 2px'
    const borderInset = '0 0 0 2px inset'
    const borderInsetButton = '0 0 0 1px inset'
    const activeBorder = '0 4px 4px 0'
    /**
     * NOTE:
     * Only use shadows 0 through 4.  The rest are there just to satisfy MUI and are
     * all copies of #4
     */
    const activeShadows = [
      'none',
      createShadow(0, 0, 0, 1),
      createShadow(0, 4, 10, 0),
      createShadow(0, 4, 16, 0),
      createShadow(0, 4, 40, 0),
      createShadow(0, 4, 10, 0, 0.2),
      border,
      borderInset,
      borderInsetButton,
      activeBorder,
    ]

    const shadows = Object.assign(
      [...activeShadows, ...arraySequence(19).map(() => activeShadows[4])],
      {
        border,
        borderInset,
        borderInsetButton,
        activeBorder,
      },
    )

    // adding it both in the array as well as a named option for ease
    shadows.border = border
    shadows.borderInset = borderInset
    shadows.borderInsetButton = borderInsetButton
    shadows.activeBorder = activeBorder

    draft.shadows = shadows as unknown as Theme['shadows']
  })

export default plugin
