import { curry, fallbackTo, isDefined, isUndefined } from 'fp/utils'
import { useCallback, useEffect } from 'react'
// istanbul ignore file
import { useDispatch, useSelector } from 'react-redux'
import {
  type RootKey,
  type SettingKey,
  type StorableType,
  localSettingsActions,
} from 'reducers/localSettings'
import { compose } from 'redux'
import { getLocalSetting } from 'selectors/localSettings'
import useCurrentUser from './useCurrentUser'

const useLocalSetting = <K extends SettingKey>(
  key: K,
  initialValue?: StorableType<K>,
) => {
  if (isUndefined(key)) throw new Error('useLocalSetting requires a key')
  const dispatch = useDispatch()

  /**
   * We may or may not be within a Redux context here so we can't rely on
   * useSelector to get the current user.  Instead, we'll try to get the
   * current user from the useCurrentUser hook, and if that fails we'll
   * fallback to 'global'.
   *
   * This mostly is to account for video-js which mounts things like the
   * SettingsButton outside of the Redux context, via mountIndeterminateComponent.
   */

  let rootKey: RootKey = 'global'
  try {
    // biome-ignore lint/correctness/useHookAtTopLevel: <explanation>
    rootKey = useCurrentUser()?.user?.id || 'global'
  } catch (_) {}

  // TODO:  we shouldn't need to type either of these, once the store and fp are typed
  const reduxValue: StorableType<K> = useSelector(getLocalSetting(key))
  const value: StorableType<K> = fallbackTo(initialValue)(reduxValue)

  const setter = useCallback(
    (v: StorableType<K>) => {
      compose(dispatch, curry(localSettingsActions.set, 3, rootKey, key))(v)
    },
    [dispatch, key, rootKey],
  )

  useEffect(() => {
    if (isUndefined(reduxValue && isDefined(value))) {
      setter(value)
    }
  }, [reduxValue, setter, value])

  return [value, setter] as const
}

export default useLocalSetting
