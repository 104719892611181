import Button from '@mui/material/Button'
import AdvancedList from 'common/formControls/lists/AdvancedList'
import { identity, takeSecond } from 'fp/utils'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { compose } from 'redux'
import Item from './Item'

const WordBank = ({ name, newWordSet }) => {
  const { getValues } = useFormContext()

  return (
    <AdvancedList
      allowReordering
      getter={identity}
      ItemRenderer={Item}
      items={getValues('contentWordBank')}
      name={name}
      setter={takeSecond}
      noLabel
      utilizeDragHandle>
      {({ addItem, disabled }) => (
        <Button
          disabled={disabled}
          onClick={compose(addItem, newWordSet)}>
          Add Word Set
        </Button>
      )}
    </AdvancedList>
  )
}

WordBank.propTypes = {
  name: PropTypes.string.isRequired,
  newWordSet: PropTypes.func.isRequired,
}

export default WordBank
