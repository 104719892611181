import { TreeItem2 } from '@mui/x-tree-view'
import { CONTENT_TYPE_SECTION } from 'core/consts'
import type { Content } from 'reducers/@types/content'
import type { TreeEntryProps } from 'routing/shells/NavShell/NavSidebar/Nav/TreeEntry'
import type { TreeEntryContentProps } from 'routing/shells/NavShell/NavSidebar/Nav/TreeEntryContent'
import ItemContent from './ItemContent'

const Item = ({
  children,
  item,
  itemId,
  parentContent,
  ...rest
}: Omit<TreeEntryProps, 'entry'> & {
  item: Content
  parentContent: Content
}) => {
  const { tabbed } = parentContent.data || item.data || {}
  const isNestedSection =
    parentContent.id !== item.id &&
    parentContent.contentType === CONTENT_TYPE_SECTION &&
    item.contentType === CONTENT_TYPE_SECTION

  return (
    <TreeItem2
      slots={{
        // we turn off the default icons as we provide our own
        collapseIcon: () => null,
        expandIcon: () => null,
        // this \could\ be broken up into separate slots.
        // right now the label, icon, etc are all done within ItemContent
        content: ItemContent,
      }}
      slotProps={
        {
          content: { isNestedSection, item },
        } as unknown as TreeEntryContentProps
      }
      data-subvariant="content-viewer"
      itemId={itemId}
      variant="nav-shell"
      {...rest}>
      {tabbed ? null : children}
    </TreeItem2>
  )
}

export default Item
