import CollapsibleSection from 'common/layout/CollapsibleSection'
import useAbilityCheck from 'hooks/useAbilityCheck'
import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'
import { ABILITY_SELF_CONTENT_AUTHORING } from 'shared/consts'
import {
  AcademicVocabPicker,
  ContentVocabPicker,
  FootnoteVocabPicker,
} from './VocabularyPicker'

const Vocabulary = ({ disabled = false, namePrefix = '', ...rest }) => {
  const hasContentAuthoringAbility = useAbilityCheck(
    ABILITY_SELF_CONTENT_AUTHORING,
  )

  const existingVocabulary = useWatch({
    name: [
      `${namePrefix}contentVocabPhraseIds`,
      `${namePrefix}academicVocabPhraseIds`,
      `${namePrefix}footnoteVocabPhraseIds`,
    ],
  })

  const empty = !existingVocabulary.flat().length
  const createType = hasContentAuthoringAbility ? undefined : 'VOCAB'

  return (
    <CollapsibleSection
      empty={empty}
      initiallyExpanded={false}
      label="Vocabulary"
      mt={3}
      style={{ padding: 0 }}
      {...rest}>
      <ContentVocabPicker
        createType={createType}
        disabled={disabled}
        name={`${namePrefix}contentVocabPhrases`}
        style={{ marginTop: 0 }}
        valueField={`${namePrefix}contentVocabPhraseIds`}
      />

      <AcademicVocabPicker
        createType={createType}
        disabled={disabled}
        name={`${namePrefix}academicVocabPhrases`}
        style={{ marginTop: 0 }}
        valueField={`${namePrefix}academicVocabPhraseIds`}
      />

      <FootnoteVocabPicker
        createType={createType}
        disabled={disabled}
        name={`${namePrefix}footnoteVocabPhrases`}
        style={{ marginTop: 0 }}
        valueField={`${namePrefix}footnoteVocabPhraseIds`}
      />
    </CollapsibleSection>
  )
}

Vocabulary.propTypes = {
  disabled: PropTypes.bool,
  namePrefix: PropTypes.string,
}

export default Vocabulary
