/* istanbul ignore file */

import type { CustomTypographyVariant, TypographyVariant } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import cl from 'classnames'
import { filterKeyedObject, omit } from 'fp/objects'
import { prefix as prefixer, suffix } from 'fp/strings'
import { identity, isDefined, isUndefined } from 'fp/utils'
import type React from 'react'
import { forwardRef, useContext, useMemo } from 'react'
import { Section, useLevel } from 'react-headings'
import { compose } from 'redux'
import { headlineStyleOffsetContext } from './HeadlineStyleOffset'
import withColorSwatch from './withColorSwatch'

const base = 'tr-headline--'
const prefix = prefixer(base)

type StyledHeadlineProps = React.HTMLAttributes<HTMLHeadingElement> & {
  color?: string
  Component?: React.ElementType
  contained?: boolean
  disabled?: boolean
  dividerBelow?: boolean
  gutterBottom?: boolean
  hasSubtitle?: boolean
  hr?: boolean
  light?: boolean
  mb?: number
  ml?: number
  mr?: number
  mt?: number
  noWrap?: boolean
  size?: number
  swatch?: boolean
  textAlign?: React.CSSProperties['textAlign']
  textTransform?: React.CSSProperties['textTransform']
  variant?: TypographyVariant | CustomTypographyVariant
  weight?: React.CSSProperties['fontWeight']
}

const StyledHeadline = forwardRef<HTMLDivElement, StyledHeadlineProps>(
  (props, ref) => {
    const {
      className,
      contained = false,
      Component: ComponentProp,
      disabled = false,
      dividerBelow = false,
      hasSubtitle = false,
      hr = false,
      light = false,
      mb,
      mt,
      size,
      swatch = false,
      textTransform,
      variant,
      weight,
      ...rest
    } = props

    const { offset } = useContext(headlineStyleOffsetContext) || { offset: 0 }
    const Wrapper: React.ElementType = swatch ? withColorSwatch(Box) : Box

    const { Component, level } = useLevel()

    const builtClassName = useMemo(() => {
      const boolOptions = {
        disabled,
        dividerBelow,
        contained,
        hasSubtitle,
        hr,
        swatch,
      }

      const boolOrNumberOptions = {
        mb,
        mt,
      }

      const trueSize = Math.min(6, (size || 0) + offset)

      return cl(
        className,
        'tr-typography',
        {
          [prefix(`textTransform-${textTransform}`)]: isDefined(textTransform),
          [prefix(`weight-${weight}`)]: weight,
          [prefix('weight-light')]: light,
        },
        !variant && {
          [prefix('size-1')]: trueSize === 1,
          [prefix('size-2')]: trueSize === 2,
          [prefix('size-3')]: trueSize === 3,
          [prefix('size-4')]: trueSize === 4,
          [prefix('size-5')]: trueSize === 5,
          [prefix('size-6')]: trueSize === 6,
        },
        Object.keys(filterKeyedObject(boolOptions, identity)).map(prefix),
        Object.keys(filterKeyedObject(boolOrNumberOptions, identity))
          .map(key =>
            typeof boolOrNumberOptions[
              key as keyof typeof boolOrNumberOptions
            ] === 'boolean'
              ? key
              : compose(
                  suffix(
                    boolOrNumberOptions[
                      key as keyof typeof boolOrNumberOptions
                    ],
                  ),
                  suffix('-'),
                )(key),
          )
          .map(prefix),
      )
    }, [
      className,
      contained,
      disabled,
      dividerBelow,
      hasSubtitle,
      hr,
      light,
      mb,
      mt,
      offset,
      size,
      swatch,
      textTransform,
      variant,
      weight,
    ])

    const fallbackHeading: StyledHeadlineProps['variant'] = `h${Math.max(
      1,
      Math.min(level, 6),
    )}` as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

    return (
      <Wrapper>
        <Typography
          className={builtClassName}
          component={ComponentProp || Component}
          ref={ref}
          variant={variant || fallbackHeading}
          {...omit('Component')({ textAlign: 'inherit', ...rest })}
        />
      </Wrapper>
    )
  },
)

type HeadlineProps = StyledHeadlineProps & {
  children?: React.ReactNode
  component?: React.ElementType
  contained?: boolean
  containerProps?: object
  // title?: React.ReactNode
}

const Headline = forwardRef<HTMLDivElement, HeadlineProps>(
  ({ children, component, contained, containerProps, title, ...rest }, ref) => {
    if (isUndefined(component) && isUndefined(title)) {
      // biome-ignore lint/suspicious/noConsole: okay
      console.error(
        '%c INVALID PROPS! %c Headline requires either `component` or `title` be provided',
        'color: red',
        '',
      )
      return '!! ERROR !!'
    }

    if (isDefined(component) && isDefined(title)) {
      // biome-ignore lint/suspicious/noConsole: okay
      console.error(
        '%c INVALID PROPS! %c Headline requires either `component` or `title` be provided, but not both!',
        'color: red',
        '',
      )
      return '!! ERROR !!'
    }

    return isDefined(title) ? (
      <Section
        component={
          <StyledHeadline
            ref={ref}
            contained={contained}
            {...rest}>
            {title}
          </StyledHeadline>
        }>
        {contained ? (
          <Container {...containerProps}>{children}</Container>
        ) : (
          children
        )}
      </Section>
    ) : (
      <Section
        {...rest}
        component={component as React.ReactNode}>
        {contained ? (
          <Container {...containerProps}>{children}</Container>
        ) : (
          children
        )}
      </Section>
    )
  },
)

export default Headline

export { StyledHeadline }
