import type { Theme } from '@mui/material'
import { important } from 'polished'
import type { Styles } from 'polished/lib/types/style'

const overrides = ({
  mixins: { px, rem, transition },
  palette,
  typography,
}: Theme) => ({
  MuiSimpleTreeView: {
    defaultProps: {
      className: 'tree-nav',
    },

    styleOverrides: {
      root: {
        paddingLeft: px(4),

        '[aria-selected="true"]': {
          '&.leaf': {
            fontWeight: 800,
            textDecoration: 'underline',
            textUnderlineOffset: rem(0.4),
            ...transition(),
          },
        },

        '.MuiCollapse-root': {
          paddingLeft: 20,
        },
      },
    },

    variants: [
      {
        props: { variant: 'nav-shell' },
        style: {
          marginTop: 15,
          marginLeft: -10,

          '.MuiTreeItem2-content.Mui-selected.leaf': {
            textDecoration: 'underline',
          },

          '.MuiCollapse-wrapperInner li:first-of-type  .MuiTreeItem2-content': {
            paddingTop: 0,
            marginTop: 18,
          },

          '.MuiCollapse-wrapperInner li:last-of-type  .MuiTreeItem2-content': {
            paddingBottom: 0,
            marginBottom: 18,
          },
        },
      },

      {
        props: { variant: 'content-selections' },
        style: {
          li: {
            '&[aria-expanded="true"], &[aria-expanded="false"]': {
              ul: {
                marginLeft: 36,
              },
            },
          },

          '.MuiCollapse-root': {
            paddingLeft: 0,
          },

          '.MuiTreeItem2-content': {
            padding: px(12, 0),
            backgroundColor: 'transparent',

            '.MuiTreeItem2-iconContainer': {
              width: 18,
              marginRight: 0,
            },

            '.MuiTreeItem2-label': {
              textDecoration: 'line-through',
              ...important(typography.variants['toc-title'] as Styles),
            },

            '&.Mui-selected .MuiTreeItem2-label': {
              textDecoration: 'none',
            },

            '&.Mui-expanded, &.Mui-focused, &.Mui-selected:hover': {
              backgroundColor: 'transparent',
            },

            'div:first-of-type': {
              width: '100%',
            },
          },
        },
      },

      {
        props: { 'data-subvariant': 'content-viewer' },
        style: {
          marginTop: 5,
          marginLeft: 18,
          marginRight: 2,

          '&::-webkit-scrollbar': {
            width: 8,
          },

          '&::-webkit-scrollbar-track': {
            backgroundColor: 'inherit',
            borderRadius: 4,
          },

          '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: palette.primary.contrastText,
          },

          '.MuiCollapse-wrapperInner li:first-of-type  .MuiTreeItem2-content': {
            paddingTop: 0,
            marginTop: 0,
          },

          '.MuiCollapse-wrapperInner li:last-of-type  .MuiTreeItem2-content': {
            paddingBottom: 0,
            marginBottom: 0,
          },
        },
      },
    ],
  },
})

export default overrides
