import type { Theme } from '@mui/material'
import { produce } from 'immer'
import Accordion from './Accordion'
import AccordionDetails from './AccordionDetails'
import AccordionSummary from './AccordionSummary'
import AppBar from './AppBar'
import Autocomplete from './Autocomplete'
import Avatar from './Avatar'
import AvatarGroup from './AvatarGroup'
import Button from './Button'
import ButtonBase from './ButtonBase'
import Card from './Card'
import CardHeader from './CardHeader'
import CardMedia from './CardMedia'
import Checkbox from './Checkbox'
import Container from './Container'
import Dialog from './Dialog'
import DialogActions from './DialogActions'
import DialogContent from './DialogContent'
import DialogTitle from './DialogTitle'
import Divider from './Divider'
import Drawer from './Drawer'
import Fab from './Fab'
import FilledInput from './FilledInput'
import FormControl from './FormControl'
import FormControlLabel from './FormControlLabel'
import FormHelperText from './FormHelperText'
import FormLabel from './FormLabel'
import Icon from './Icon'
import IconButton from './IconButton'
import Input from './Input'
import InputBase from './InputBase'
import InputLabel from './InputLabel'
import LinearProgress from './LinearProgress'
import List from './List'
import ListItem from './ListItem'
import Menu from './Menu'
import MenuItem from './MenuItem'
import OutlinedInput from './OutlinedInput'
import Paper from './Paper'
import Radio from './Radio'
import Rating from './Rating'
import Select from './Select'
import SimpleTreeView from './SimpleTreeView'
import Slider from './Slider'
import Switch from './Switch'
import Tab from './Tab'
import TabPanel from './TabPanel'
import Table from './Table'
import TableContainer from './TableContainer'
import TablePagination from './TablePagination'
import Tabs from './Tabs'
import TextField from './TextField'
import Timeline from './Timeline'
import ToggleButton from './ToggleButton'
import ToggleButtonGroup from './ToggleButtonGroup'
import Toolbar from './Toolbar'
import Tooltip from './Tooltip'
import TreeItem2 from './TreeItem2'
import Typography from './Typography'

/** ****************************************************************************
 *                                                                             *
 *                                N O T I C E                                  *
 *                                                                             *
 * None of these component overrides should explicitly declare typography      *
 * changes.  Anything affecting text, font, line heights, etc. should be       *
 * declared in src/styling/theming/base/typography.js                          *
 *                                                                             *
 * To affect typography within component overrides, use                        *
 * `theme.typography.variants`, either directly or via a spread operation.     *
 *                                                                             *
 * For simple cases, using `<Typography variant="whatever" />` can help avoid  *
 * needing to extend these component overrides in the first place.             *
 *                                                                             *
 * ************************************************************************** */

const overrides = [
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Autocomplete,
  Avatar,
  AvatarGroup,
  Button,
  ButtonBase,
  Card,
  CardHeader,
  CardMedia,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  Fab,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputBase,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  Rating,
  Select,
  SimpleTreeView,
  Slider,
  Switch,
  Tab,
  TabPanel,
  Table,
  TableContainer,
  TablePagination,
  Tabs,
  TextField,
  Timeline,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  TreeItem2,
  Typography,
]

const plugin = (theme: Theme) =>
  produce(theme, draft => {
    draft.components = overrides.reduce(
      (acc, override) => ({
        ...acc,
        ...override(theme),
      }),
      {},
    )
  })

export default plugin
