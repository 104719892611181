import { set } from 'fp/objects'
import actionTypes from './actionTypes'
import { createReducer } from './utils'

type LocalSettingValues = {
  'content-viewer-exit': { url: string }

  'content-viewer-last-viewed': { url: string }

  'NavShell-expanded': string[]

  'user-language-id': number
}

type RootKey = 'global' | number

type SettingKey = keyof LocalSettingValues

type StorableType<K extends SettingKey> = LocalSettingValues[K]

type LocalSettingsState = Record<
  RootKey,
  Record<SettingKey, StorableType<SettingKey>>
>

// TODO: remove these consts
const SETTING_KEY_CONTENT_VIEWER_EXIT = 'content-viewer-exit'
const SETTING_KEY_CONTENT_VIEWER_LAST_VIEWED = 'content-viewer-last-viewed'
const SETTING_KEY_LANGUAGE_ID = 'user-language-id'

type LocalSettingsInitAction = {
  type: typeof actionTypes.LOCAL_SETTINGS_INIT
  settings: LocalSettingsState
}

type LocalSettingsSetAction = {
  type: typeof actionTypes.LOCAL_SETTINGS_SET
  rootKey: RootKey
  key: SettingKey
  value: StorableType<SettingKey>
}

const localSettingsActions = {
  init: (settings: LocalSettingsState): LocalSettingsInitAction => ({
    type: actionTypes.LOCAL_SETTINGS_INIT,
    settings,
  }),
  set: (
    rootKey: RootKey,
    key: SettingKey,
    value: StorableType<SettingKey>,
  ): LocalSettingsSetAction => ({
    type: actionTypes.LOCAL_SETTINGS_SET,
    rootKey,
    key,
    value,
  }),
}

const handleInit = (
  state: LocalSettingsState,
  { settings }: LocalSettingsInitAction,
): LocalSettingsState => ({ ...state, ...settings })

const handleSet = (
  state: LocalSettingsState,
  { rootKey, key, value }: LocalSettingsSetAction,
): LocalSettingsState => set(`${rootKey}.${key}`, value)(state)

const localSettings = createReducer(
  {},
  {
    [actionTypes.LOCAL_SETTINGS_INIT]: handleInit,
    [actionTypes.LOCAL_SETTINGS_SET]: handleSet,
  },
)

export default localSettings

export {
  localSettingsActions,
  // TODO: remove these exports and the consts
  SETTING_KEY_CONTENT_VIEWER_EXIT,
  SETTING_KEY_CONTENT_VIEWER_LAST_VIEWED,
  SETTING_KEY_LANGUAGE_ID,
}

export type { LocalSettingsState, RootKey, StorableType, SettingKey }
