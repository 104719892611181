import useAbilityChecker from 'hooks/useAbilityChecker'
import type { AbilityFlag } from 'hooks/useAbilityChecker'
import { useSelector } from 'react-redux'
import type { Content } from 'reducers/@types/content'
import { contentIsViewableByUser, contentStateIsDraft } from 'selectors/content'

const withAbilityCheck = <P,>(
  WrappedComponent: React.ComponentType<P & { content?: Content }>,
  abilityFlags: AbilityFlag | AbilityFlag[] | null = null,
  content: Content | null = null,
) => {
  const Enhanced = (props: P) => {
    const has: (flags: AbilityFlag | AbilityFlag[]) => boolean =
      useAbilityChecker()

    const viewable = useSelector(contentIsViewableByUser(content || {}))
    const isDraft = useSelector(contentStateIsDraft(content || {}))

    return (!abilityFlags || has(abilityFlags)) && (!content || viewable) ? (
      <WrappedComponent
        {...(content ? { 'data-draft': isDraft ? 'true' : 'false' } : null)}
        {...props}
      />
    ) : null
  }

  return Enhanced
}

export default withAbilityCheck
