import rangy from 'rangy/lib/rangy-core'
import { compose } from 'redux'
import 'rangy/lib/rangy-highlighter'
import 'rangy/lib/rangy-classapplier'
import 'rangy/lib/rangy-textrange'
import * as Sentry from '@sentry/react'
import { arraySequence, filter, last, map } from 'fp/arrays'
import {
  findAncestor,
  findChildTextNodes,
  getNodeByContentId,
  getTextNodesThatContain,
  hasClass,
  unwrap,
} from 'fp/dom'
import { increment } from 'fp/numbers'
import { escapeStringRegexp, prefix } from 'fp/strings'
import { isDefined, unary } from 'fp/utils'

const getOccurrenceIndex = (selectedText, nodeToSearch, indexFilterFn) =>
  compose(
    a => a.findIndex(indexFilterFn),
    Array.from,
    unary(nodeToSearch.matchAll.bind(nodeToSearch)),
    escapeStringRegexp,
  )(selectedText)

const findOriginalNode = (rootNode, data) => {
  const { nodeIndex, parserHash, selectedPortion } = data

  // first try to find the node that has attrib data-parser_hash equal to parserHash
  // if that fails, try to find the node that contains selectedPortion
  // if that fails, try to find the node at nodeIndex

  const contentNode = rootNode
  const nodes = getTextNodesThatContain(contentNode, selectedPortion)

  let node = document.querySelector(`[data-parser_hash="${parserHash}"]`)
    ?.childNodes?.[0]

  if (!node) {
    node = nodes[nodeIndex]
    // we couldn't find the node via the hash
    // if the node is already annotated, we don't want that text node, but the parent of the one above the annotation element
    if (
      node.parentElement.nodeName === 'MARK' &&
      node.parentElement.classList.contains('annotation')
    ) {
      node = node.parentElement.parentElement
    }
  }

  return node
}
const getNodeAndOffset = (node, interactionNode) => {
  const { offset } = interactionNode
  // the correct text node is selected
  if (
    node.nodeType === Node.TEXT_NODE &&
    node.length >= offset &&
    node.parentNode.firstChild === node
  ) {
    return [node, offset]
  }

  // there are multiple text nodes within the span which causes the nodes to be split up
  // e.g. there is already another annotation, or perhaps a vocab word
  const childTextNodes = findChildTextNodes(node.parentElement)
  let length = 0
  for (const currentNode of childTextNodes) {
    length += currentNode?.textContent?.length || 0

    if (length >= offset) {
      return [currentNode, offset - length + currentNode.textContent.length]
    }
  }
  return null
}

const setOffset = (node, offset) => {
  const parent =
    findAncestor(node, n => n.hasAttribute?.('data-parser_hash')) ||
    node.parentElement
  // there's only one text node, we can use the given offset
  if (parent.childNodes.length === 1) {
    return offset
  }
  // there are existing annotations, and we need to calculate the offset based on text content only
  const childTextNodes = findChildTextNodes(parent)
  let childNode = childTextNodes.shift()
  let length = 0

  while (childNode !== node) {
    length += childNode.textContent.length
    childNode = childTextNodes.shift()
  }
  return length + offset
}

export const createRangeForAnnotation = interaction => {
  let range
  const {
    contextContentId,
    interactionData: {
      selectionData: { anchor, focus },
    },
  } = interaction

  // contextContentId is the top-level (e.g. subsection)
  const contentNode = getNodeByContentId(contextContentId)

  if (contentNode) {
    const anchorNode = findOriginalNode(contentNode, anchor)
    const focusNode = findOriginalNode(contentNode, focus)

    if (anchorNode && focusNode) {
      range = rangy.createRange(contentNode)
      const start = getNodeAndOffset(anchorNode, anchor)
      const end = getNodeAndOffset(focusNode, focus)

      if (isDefined(start) && isDefined(end)) {
        range.setStart(...start)
        range.setEnd(...end)
        return range
      }
    }
  }

  return null
}

export const highlightRange = (
  range,
  colorId,
  elementAttributes,
  disablePointer = false,
  hasNote = false,
) => {
  try {
    if (!range.isValid()) return

    removeAllHighlightsFromRange(range)
    const commonClassApplierProps = {
      applyToEditableOnly: false,
      elementAttributes,
      ignoreWhiteSpace: true,
    }

    rangy
      .createClassApplier('annotation', {
        ...commonClassApplierProps,
        elementTagName: 'mark',
        tagNames: ['span', 'a', 'mark', 'h1', 'h2', 'h3'],
        useExistingElements:
          range.startContainer.parentElement.tagName === 'MARK', // this will create wrapper elements
      })
      .applyToRange(range)

    // Rangy can only apply one class at a time.
    const otherClasses = [
      `annotation-${colorId}`,
      hasNote && 'annotation-note',
      disablePointer && 'disable-pointer',
    ].filter(Boolean)

    otherClasses.forEach(className => {
      rangy
        .createClassApplier(className, {
          ...commonClassApplierProps,
          tagNames: ['mark'],
          useExistingElements: true, // apply to the mark elements that we just created
        })
        .applyToRange(range)
    })
  } catch (ex) {
    Sentry.captureException(ex)
  }
}

export const isTeacherAnnotation = annotation =>
  !!annotation?.sharedForAssignmentId

const nodeContainsAnnotation = node =>
  hasClass('annotation')(node) ||
  Array.from(node.childNodes)?.some?.(nodeContainsAnnotation)

export const removeAllHighlights = parent => {
  compose(map(removeHighlightWrapper), Array.from, node =>
    node.querySelectorAll('.annotation'),
  )(parent || document)

  parent?.normalize()
}

const removeHighlightWrapper = node => {
  if (hasClass('annotation')(node)) {
    unwrap(node)
  }
}

const removeAllHighlightsFromRange = range => {
  if (range.isValid()) {
    arraySequence(6)
      .map(increment)
      .map(prefix('annotation-'))
      .forEach(className => {
        const applier = rangy.createClassApplier(className, {
          ignoreWhiteSpace: true,
          tagNames: ['mark'],
        })
        applier.undoToRange(range)
      })
  }
  removeOrphanedHighlights()
}

export const removeOrphanedHighlights = parent => {
  compose(
    map(removeHighlightWrapper),
    filter(node => !node.hasAttribute('data-annotationid')),
    Array.from,
    node => node.querySelectorAll('.annotation'),
  )(parent || document)
}

export const rangeIntersectsAnnotations = range =>
  nodeContainsAnnotation(range.cloneContents())

const findHash = node => {
  while (node) {
    const md5 =
      node.nodeType === Node.ELEMENT_NODE
        ? node.getAttribute('data-parser_hash')
        : null

    if (md5) {
      return md5
    }
    node = node.parentNode
  }
  return null
}

export const findAllTextNodesBetween = (startNode, endNode) => {
  const reverseSelection =
    startNode.compareDocumentPosition(endNode) ===
    Node.DOCUMENT_POSITION_PRECEDING
  const [firstNode, lastNode] = reverseSelection
    ? [endNode, startNode]
    : [startNode, endNode]

  // Helper function to get the next node in a way that considers deep traversal
  const getNextNode = node => {
    if (node.firstChild) {
      return node.firstChild
    }
    while (node) {
      if (node.nextSibling) {
        return node.nextSibling
      }
      node = node.parentNode
    }
    return null
  }

  const textNodes = []
  // Check if firstNode is a text node and include it if so
  if (firstNode.nodeType === Node.TEXT_NODE) {
    textNodes.push(firstNode)
    if (firstNode === lastNode) {
      return textNodes
    }
  }

  let currentNode = getNextNode(firstNode)

  while (currentNode && currentNode !== lastNode) {
    if (currentNode.nodeType === Node.TEXT_NODE) {
      textNodes.push(currentNode)
    }
    currentNode = getNextNode(currentNode)
  }

  // Check if endNode is a text node and include it if so
  if (lastNode.nodeType === Node.TEXT_NODE) {
    textNodes.push(lastNode)
  }

  return textNodes.filter(({ wholeText }) => wholeText.trim().length > 0)
}

export const acquireHashesAndIndexes = (
  selection,
  textSelection,
  containerNode,
) => {
  try {
    const {
      anchorNode,
      anchorOffset: anchorOffsetFromSelection,
      focusNode,
      focusOffset: focusOffsetFromSelection,
    } = selection
    let anchorNodeIndex = -1
    let focusNodeIndex = -1
    let anchorOccurrenceIndex = -1
    let focusOccurrenceIndex = -1
    let selectedTextPortionWithinAnchorNode
    let selectedTextPortionWithinFocusNode

    const selectedText = textSelection.trim().replaceAll('\n', '  ')
    let [textAnchorNode, textFocusNode, anchorOffset, focusOffset] = [
      anchorNode,
      focusNode,
      anchorOffsetFromSelection,
      focusOffsetFromSelection,
    ]

    const reverseSelection =
      anchorNode.compareDocumentPosition(focusNode) ===
        Node.DOCUMENT_POSITION_PRECEDING ||
      (anchorNode === focusNode && anchorOffset > focusOffset)

    if (reverseSelection) {
      ;[textAnchorNode, textFocusNode, anchorOffset, focusOffset] = [
        textFocusNode,
        textAnchorNode,
        focusOffset,
        anchorOffset,
      ]
    }

    // this all boils down to the fact that we can't assume the selection anchor/focus are text nodes
    // so try to find the closest ones
    const [firstTextNode, ...rest] = findAllTextNodesBetween(
      textAnchorNode,
      textFocusNode,
    )

    if (textAnchorNode.nodeType !== Node.TEXT_NODE) {
      textAnchorNode = firstTextNode
    }
    if (textFocusNode.nodeType !== Node.TEXT_NODE) {
      textFocusNode = last(rest) || textAnchorNode
      // if the selected focus node was not a text node, the previous text node should be selected entirely
      focusOffset = textFocusNode.textContent.length
    }
    if (textAnchorNode === textFocusNode) {
      // the selection starts and ends in the same node
      const nodes = getTextNodesThatContain(containerNode, selectedText)
      anchorNodeIndex = nodes.indexOf(textAnchorNode)
      focusNodeIndex = anchorNodeIndex

      selectedTextPortionWithinAnchorNode = selectedText
      selectedTextPortionWithinFocusNode = selectedText

      const searchWithin =
        textAnchorNode.wholeText || textAnchorNode.textContent
      anchorOccurrenceIndex = getOccurrenceIndex(
        selectedText,
        searchWithin,
        ({ index }) => index === anchorOffset,
      )
    } else {
      // the selection spans multiple nodes.
      // we handle these the same, except we need to figure out which portion of
      // the selectedText is within each node.

      const anchorSearchWithin = textAnchorNode.textContent
      const focusSearchWithin = textFocusNode.textContent

      selectedTextPortionWithinAnchorNode =
        anchorSearchWithin.substring(anchorOffset)
      selectedTextPortionWithinFocusNode = focusSearchWithin.substring(
        0,
        focusOffset,
      )

      const anchorNodes = getTextNodesThatContain(
        containerNode,
        selectedTextPortionWithinAnchorNode,
      )
      const focusNodes = getTextNodesThatContain(
        containerNode,
        selectedTextPortionWithinFocusNode,
      )

      anchorNodeIndex = anchorNodes.indexOf(textAnchorNode)
      focusNodeIndex = focusNodes.indexOf(textFocusNode)

      focusOccurrenceIndex = getOccurrenceIndex(
        selectedTextPortionWithinFocusNode,
        focusSearchWithin,
        ({ index }) =>
          index === focusOffset - selectedTextPortionWithinFocusNode.length,
      )
    }
    const anchor = {
      parserHash: findHash(textAnchorNode),
      nodeIndex: anchorNodeIndex,
      occurrenceIndex: anchorOccurrenceIndex,
      selectedPortion: selectedTextPortionWithinAnchorNode,
      offset: setOffset(textAnchorNode, anchorOffset),
    }
    const focus = {
      parserHash: findHash(textFocusNode),
      nodeIndex: focusNodeIndex,
      occurrenceIndex: focusOccurrenceIndex,
      selectedPortion: selectedTextPortionWithinFocusNode,
      offset: setOffset(textFocusNode, focusOffset),
    }

    return {
      selectedText,
      focus,
      anchor,
    }
  } catch (ex) {
    // hopefully we never get here
    Sentry.captureException(ex)
    return null
  }
}
