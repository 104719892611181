import { createSelector } from '@comfy/redux-selectors'
import { capitalize } from '@mui/material'
import type {
  AssetUploadedProps,
  ImageInteractiveData,
} from 'common/formControls/textInputs/RichTextEdit2/nodes/ImageNode/@types'
import { CONTENT_STATE_PUBLISHED } from 'core/consts'
import { filter, first } from 'fp/arrays'
import { get } from 'fp/objects'
import { isUndefined } from 'fp/utils'
import type { Either } from 'monet'
import actionTypes from 'reducers/actionTypes'
import { compose } from 'redux'
import { replace } from 'redux-first-history'
import type { AnyAction } from 'redux-saga'
import { call, put, select, take, takeEvery } from 'redux-saga/effects'
import {
  contentAuthorUrl,
  getTeacherContentAuthoringRoute,
} from 'routing/consts'
import { getLocation, matchPathSelector } from 'selectors/routing'
import { CONTENT_TYPE_BLOCK } from 'shared/consts'
import { dangerouslyCallApi } from './api'
import { preparePayloadForSave } from './contentSaving'
import { actionFailed, failure, success } from './utils'

interface SaveAttemptAction extends AnyAction {
  payload: {
    blockId?: string
    body: string
    contentId?: string
    contentSubType: string
    contentType: string
    name: string
    variant: string
  }
}

interface AssetSaveAction extends AnyAction {
  payload: {
    data: ImageInteractiveData
    upload: AssetUploadedProps
  }
}

export function* handleSaveAttempt(
  action: SaveAttemptAction,
): Generator<AnyAction, void, AnyAction> {
  const { payload } = action

  const {
    blockId,
    body,
    contentId,
    contentSubType,
    contentType,
    name,
    variant,
    ...rest
  } = payload

  // step 1: save the block, creating it if necessary
  yield put({
    type: actionTypes.CONTENT_SAVE,
    payload: {
      ...(blockId ? { id: blockId } : {}),
      body, // `body` is expected as a top-level field for `assertBlockChildren`
      children: [],
      contentState: CONTENT_STATE_PUBLISHED, // auto publish user content
      contentType: CONTENT_TYPE_BLOCK,
      variant,
    },
    suppressAlert: true,
  })

  let result = yield take([
    success(actionTypes.CONTENT_SAVE),
    failure(actionTypes.CONTENT_SAVE),
  ])

  if (actionFailed(result)) {
    // error is handled by the api saga
    return
  }

  const actualBlockId = result.response.id

  // step 2: save the top-level item, adding the block as a child
  yield put({
    type: actionTypes.CONTENT_SAVE,
    payload: {
      ...rest,
      ...(contentId ? { id: contentId } : {}),
      children: [
        {
          id: actualBlockId,
        },
      ],
      contentState: CONTENT_STATE_PUBLISHED, // auto publish user content
      contentType,
      contentSubType,
      name,
    },
    suppressAlert: true,
  })

  result = yield take([
    success(actionTypes.CONTENT_SAVE),
    failure(actionTypes.CONTENT_SAVE),
  ])

  if (actionFailed(result)) {
    return
  }

  // let the form know the save succeeded.  this needs to happen before the
  // redirect so it doesn't present the dirty nav warning to the user
  yield put({ type: success(actionTypes.AUTHORING_SAVE) })

  // ensure they are on the "edit" page
  const actualContentId = result.response.id

  const params = yield select(
    createSelector(
      matchPathSelector({ path: `${contentAuthorUrl}/create/:contentType` }),
      matchPathSelector({
        path: `${contentAuthorUrl}/create/:contentType/type/:contentSubType`,
      }),
      matchPathSelector({
        path: `${contentAuthorUrl}/edit/:contentType/type/:contentSubType`,
      }),
      (...all: object[]) => compose(get('params'), first, filter(Boolean))(all),
    ),
  )

  if (isUndefined(params)) return

  const { hash } = yield select(getLocation)

  yield put(
    replace(
      getTeacherContentAuthoringRoute(
        {
          contentType,
          contentSubType,
          id: actualContentId,
        },
        hash,
      ),
    ),
  )
}

function* handleAssetSave(
  action: AssetSaveAction,
): Generator<AnyAction, void, AnyAction> {
  // SAVE THE INTERACTIVE
  const preparedAction = yield call(preparePayloadForSave, {
    payload: {
      contentType: 'interactive',
      contentSubType: action.payload.upload.type,
      contentState: CONTENT_STATE_PUBLISHED,
      data: action.payload.data,
      uploadsMap: {
        [`${action.payload.upload.type}${capitalize(action.payload.upload.variant)}`]:
          action.payload.upload,
      },
    },
    suppressAlert: true,
    suppressUpdate: true,
  })

  const result = (yield call(dangerouslyCallApi, {
    ...preparedAction,
    // biome-ignore lint/suspicious/noExplicitAny: <fix once we know the right pattern to use>
  })) as unknown as Either<any, any>

  if (actionFailed(result)) {
    yield put({
      type: failure(actionTypes.AUTHORING_ASSET_SAVE),
      payload: result.left(),
    })
  } else {
    yield put({
      type: success(actionTypes.AUTHORING_ASSET_SAVE),
      payload: result.right(),
    })
  }
}

/* istanbul ignore next line */
function* contentAuthoringSaga() {
  yield takeEvery(actionTypes.AUTHORING_SAVE, handleSaveAttempt)
  yield takeEvery(actionTypes.AUTHORING_ASSET_SAVE, handleAssetSave)
}

export default contentAuthoringSaga
