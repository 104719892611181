import type { Theme } from '@mui/material'

const overrides = ({
  mixins: { px, rem },
  palette,
  shadows,
  typography: { variants },
}: Theme) => ({
  MuiMenuItem: {
    styleOverrides: {
      root: {
        ...variants['nav-item'],
        color: palette.grey[2],
        padding: rem(1.8, 2.4),

        '&:hover': {
          color: palette.text.primary,
          backgroundColor: palette.background.paper,
          boxShadow: shadows.borderInset,
        },

        '&.Mui-selected': {
          ...variants['nav-item-semibold'],
          color: palette.grey[0],
          backgroundColor: 'transparent',
        },
      },
    },

    variants: [
      {
        props: { variant: 'collapsed-tablist' },
        style: variants['tab-title'],
      },

      {
        props: { variant: 'checkbox-multi-select' },
        style: {
          '&.MuiMenuItem-root': { paddingLeft: '14px' },
          '&.with-border-top': {
            borderTop: `1px solid ${palette.common.lightGrey}`,
          },
        },
      },

      {
        props: { variant: 'with-tooltip' },
        style: {
          padding: 0,
          '.content': {
            padding: rem(1.8, 2.4),
            width: '100%',
          },
        },
      },

      {
        props: { variant: 'multiselect-checkbox' },
        style: {
          padding: px(8, 24),
          '.MuiListItemText-primary': { color: palette.text.primary },
        },
      },

      {
        props: { 'data-variant': 'swatch' },
        style: {
          padding: 0,
          margin: rem(1, 0),
        },
      },

      {
        props: { variant: 'selected' },
        style: {
          '&.Mui-selected': {
            backgroundColor: palette.common.black,
            color: palette.common.white,
          },
        },
      },
    ],
  },
})

export default overrides
