import {
  ThemeProvider as MuiThemeProvider,
  type Theme,
} from '@mui/material/styles'
import hss from 'apps/hss/themes/hss'
import hsshc from 'apps/hss/themes/hss-hc'
import useEffectOnce from 'hooks/useEffectOnce'
import useThemeName from 'hooks/useThemeName'
import { createContext, memo, useState } from 'react'
import { isDevEnv } from 'selectors/index'
import type { BrandColor } from 'src/@types/mui-palette'
import type { ThemeConstructor } from './base'

type AccentColor = BrandColor | 'grey'

let loggedHero = false

const themes: Record<string, ThemeConstructor> = {
  hss,
  'hss-hc': hsshc,
  ss: hss, // can be removed once we have the user api
  usa: hsshc, // can be removed once we have the user api
}

/* istanbul ignore next line */
const consoleHero = () => {
  if (loggedHero) return
  loggedHero = true
  const asciiFont = 'font-family:monospace;font-size:8px'
  const black = `color:inherit;${asciiFont}`
  const blue = `color:#1C5BFF;${asciiFont}`
  const bylineFont =
    'font-family:monospace;font-size:13px;line-height: 1.5;padding-top:10px'
  const bolded = `font-weight:bold;text-decoration:underline;${bylineFont}`
  const normal = bylineFont

  // biome-ignore lint/suspicious/noConsole: intended
  console.log(
    `%c
▐████████▌    ██████▄         ██▌      ██      ██████████████     ██████▄       ▄█████▄     ███████
    ██        ██    ██       ██▀█▌      ██    ▐█▌                 ██    ██     ██    ▀▀     ██
    ██        ██   ▄█▀      ▐█▌ ▀█       █▌  ▐█▌      %c▄▄▄▄▄▄▄%c     ██   ▄█▀     ▀███▄▄       ██▄▄▄▄▄
    ██        ██▀▀██        █▌   ██      ▐█▌ ██       %c▀▀▀▀▀▀▀%c     ██▀▀██          ▀▀▀█▄     ██▀▀▀▀▀
    ██        ██   █▄      ██     ██      ▀███                    ██   █▄      ██▄   ██     ██
    ██        ██    ▀█▄   ██       ██      ██         ██████████████    ▀█▄     ▀████▀      ███████

 %cTip%c: Type \`theme\` to inspect it directly within the console...
  `,

    black,
    blue,
    black,
    blue,
    black,
    bolded,
    normal,
  )
}

type Context = {
  setAccentColor: (color: AccentColor) => void
}

// biome-ignore lint/style/noNonNullAssertion: for context
const context = createContext<Context>(null!)

type NamedProviderProps = {
  children: React.ReactNode
  themeName: string
  sbAccentColor?: AccentColor
}

const NamedProvider = memo(
  ({ themeName, children, sbAccentColor }: NamedProviderProps) => {
    const [accentColor, setAccentColor] = useState<AccentColor>('grey') // app default

    const theme = themes[themeName]({
      accentColor: sbAccentColor || accentColor,
    }) as unknown as Theme

    useEffectOnce(() => {
      /* istanbul ignore next line */
      if (isDevEnv()) {
        consoleHero()

        if (window.top) window.top.theme = theme
      }
    })

    return (
      <context.Provider value={{ setAccentColor }}>
        <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
      </context.Provider>
    )
  },
)

const ConnectedProvider = ({ children }: { children: React.ReactNode }) => {
  const themeName = useThemeName()

  return <NamedProvider themeName={themeName}>{children}</NamedProvider>
}

const ThemeProvider = ({
  children,
  override,
}: {
  children: React.ReactNode
  override?: string
}) =>
  override ? (
    <NamedProvider themeName={override}>{children}</NamedProvider>
  ) : (
    <ConnectedProvider>{children}</ConnectedProvider>
  )

export default ThemeProvider

export { context, NamedProvider }
