import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Stack from '@mui/material/Stack'
import {
  ABILITY_STUDENT_INTERFACE,
  ABILITY_TEACHER_INTERFACE,
  TOGGLE_STATE_PRESENTER_MODE,
} from 'core/consts'
import { refShape } from 'core/shapes'
import { asPercentageString } from 'fp/numbers'
import { get } from 'fp/objects'
import { identity, matches, not } from 'fp/utils'
import useAbilityChecker from 'hooks/useAbilityChecker'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { getContextualAssignment } from 'selectors/assignments'
import { getLocalSetting } from 'selectors/localSettings'
import { getUserAssignment } from 'selectors/userAssignments'
import Grade from './Grade'
import Learn from './Learn'
import Teach from './Teach'

const panes = [
  {
    abilityFlags: ABILITY_TEACHER_INTERFACE,
    label: 'Teach',
    Component: Teach,
  },
  {
    abilityFlags: ABILITY_STUDENT_INTERFACE,
    label: 'Grade',
    Component: Grade,
  },
  {
    abilityFlags: [ABILITY_STUDENT_INTERFACE, ABILITY_TEACHER_INTERFACE],
    label: 'Learn',
    Component: Learn,
  },
]

const Panes = ({
  actionsRef,
}: {
  actionsRef: React.RefObject<HTMLDivElement>
}) => {
  const has = useAbilityChecker()
  const hasContentViewTeach = has(ABILITY_TEACHER_INTERFACE)
  const [expanded, setExpanded] = useState(panes[1].label) // only matters for students

  const presenterModeEnabled = useSelector(
    getLocalSetting(TOGGLE_STATE_PRESENTER_MODE),
  )
  const assignment = useSelector(getContextualAssignment)
  const { score, submittedDate } = useSelector(getUserAssignment) || {}
  const inAssignment = !!assignment
  const { maxScore } = assignment || {}
  const grade = asPercentageString(maxScore === 0 ? 0 : score / maxScore)

  if (hasContentViewTeach && presenterModeEnabled) {
    // only ever show the "Learn" pane when in presenter mode
    return <Learn actionsRef={actionsRef} />
  }

  const availablePanes = panes
    .filter(compose(has, get('abilityFlags')))
    .filter(submittedDate ? identity : compose(not, matches('label', 'Grade')))

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : '')
    }

  return availablePanes.map(({ label, Component }) => (
    <Accordion
      defaultExpanded
      key={label}
      {...(hasContentViewTeach
        ? null
        : {
            expanded: expanded === label,
            onChange: handleChange(label),
          })}
      {...(availablePanes.length === 1 ? { variant: 'single' } : null)}>
      {Boolean(inAssignment && (submittedDate || hasContentViewTeach)) && (
        <AccordionSummary aria-controls={`${label}-details`}>
          <Stack
            direction="row"
            justifyContent="space-between"
            mr={1}
            width="100%">
            <div>{label}</div>
            {label === 'Grade' && <div>{grade}</div>}
          </Stack>
        </AccordionSummary>
      )}

      <AccordionDetails id={`${label}-details`}>
        <Component actionsRef={actionsRef} />
      </AccordionDetails>
    </Accordion>
  ))
}

Panes.propTypes = {
  actionsRef: refShape.isRequired,
}

export default Panes
