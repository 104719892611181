import {
  COMPLETION_DONE,
  COMPLETION_INDETERMINATE,
  COMPLETION_NONE,
} from 'projections/content'
import {
  Check as Complete,
  Circle as Incomplete,
  Circle as Indeterminate, // styled in components/TreeItem
} from 'react-feather'
import { useSelector } from 'react-redux'
import type { Content } from 'reducers/@types/content'
import { includeProgress } from 'selectors/contentViewer'

const Icons = {
  [COMPLETION_NONE]: Incomplete,
  [COMPLETION_INDETERMINATE]: Indeterminate,
  [COMPLETION_DONE]: Complete,
}

const ItemIcon = ({ item }: { item: Content }) => {
  const { calculatedCompletion = COMPLETION_NONE } = item
  const relevant = useSelector(includeProgress)
  const Icon = Icons[calculatedCompletion] || Incomplete

  return relevant ? (
    <div className="icon-container">
      <Icon
        data-complete={calculatedCompletion === COMPLETION_DONE}
        data-viewed={calculatedCompletion === COMPLETION_INDETERMINATE}
      />
    </div>
  ) : null
}

export default ItemIcon
