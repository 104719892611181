import type { Theme } from '@mui/material'

const overrides = ({ mixins: { em, rem }, palette }: Theme) => ({
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: palette.grey[0],
        fontSize: rem(1.6),
        lineHeight: '157%',
        letterSpacing: em(-0.02),
      },
    },

    variants: [],
  },
})

export default overrides
