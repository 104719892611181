import { addSeconds } from 'date-fns'
import { now } from 'locale/dates'
import { failure, success } from 'sagas/utils'
import actionTypes from './actionTypes'
import { createReducer } from './utils'

const initialState = {
  authExpires: 0,
  authProvider: undefined,
  authWarning: 0,
  endsAt: undefined,
  id: undefined,
  profileId: undefined,
  sessionActive: false,
  sessionChecked: false,
  startsAt: undefined,
  userId: undefined,
  warnAt: undefined,
  warningVisible: false,
  returnLink: undefined,
  logoutLink: undefined,
}

export const actions = {
  keepAlive: () => ({ type: actionTypes.SESSION_KEEP_ALIVE }),
  resetTimeout: () => ({ type: actionTypes.SESSION_RESET_TIMEOUT }),
  logout: redirect => ({ type: actionTypes.SESSION_LOGOUT, redirect }),
  sessionCheck: () => ({ type: actionTypes.SESSION_CHECK }),
  showWarning: () => ({ type: actionTypes.SESSION_SHOW_TIMEOUT_WARNING }),
}

const resetState = () => ({
  ...initialState,
})

const handleLoginOrCheckSuccess = (
  _,
  {
    response: {
      authExpires,
      authProvider,
      authWarning,
      returnLink,
      logoutLink,
      id,
      profileId,
      user: { id: userId },
    },
  },
) => ({
  authExpires,
  authProvider,
  authWarning,
  returnLink,
  logoutLink,
  endsAt: addSeconds(now(), authExpires),
  id,
  profileId,
  sessionActive: true,
  sessionChecked: true,
  startsAt: now(),
  userId,
  warnAt: addSeconds(now(), authExpires - authWarning),
  warningVisible: false,
})

const handleSessionFailure = () => ({
  ...initialState,
  sessionActive: false,
  sessionChecked: true,
})

const handleShowWarning = state => ({ ...state, warningVisible: true })

const handleResetTimeout = state => ({
  ...state,
  endsAt: addSeconds(now(), state.authExpires),
  sessionActive: true,
  startsAt: now(),
  warnAt: addSeconds(now(), state.authExpires - state.authWarning),
  warningVisible: false,
})

const session = createReducer(initialState, {
  [success(actionTypes.SESSION_CHECK)]: handleLoginOrCheckSuccess,
  [failure(actionTypes.SESSION_CHECK)]: handleSessionFailure,
  [success(actionTypes.SESSION_LOGIN)]: handleLoginOrCheckSuccess,
  [success(actionTypes.SESSION_LOGOUT)]: resetState,
  [success(actionTypes.SESSION_KEEP_ALIVE)]: handleLoginOrCheckSuccess,
  [actionTypes.SESSION_RESET_TIMEOUT]: handleResetTimeout,
  [actionTypes.SESSION_SHOW_TIMEOUT_WARNING]: handleShowWarning,
})

export default session
