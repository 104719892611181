import { withOptions } from '@comfy/redux-selectors'
import {
  CONTENT_SUB_TYPE_STANDALONE,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
} from 'core/consts'
import { filter, first } from 'fp/arrays'
import { get, set } from 'fp/objects'
import { isEmptyString, startsWith } from 'fp/strings'
import { curryRight, identity, isDefined, not } from 'fp/utils'
import { Maybe } from 'monet'
import { compose } from 'redux'
import {
  assignmentCreatorUrl,
  assignmentEditorUrl,
  contentBuilderUrl,
  contentViewerUrl,
  studentAssignmentsUrl,
  studentBinderUrl,
} from 'routing/consts'
import { createSelector } from '.'
import {
  getPreviousLocation,
  matchPathSelector as matchPathSelectorPrime,
} from './routing'

const constructContentViewMatchPathSelectors = ({
  isPinned,
  urlOtherThanCurrent,
}) => {
  const matchPathSelector = curryRight(
    matchPathSelectorPrime,
    isPinned,
    urlOtherThanCurrent,
  )

  return [
    // assignment-creator
    matchPathSelector({
      path: `${assignmentCreatorUrl}/:contentType/:contentId/${CONTENT_TYPE_ECHO}/:echoId`,
    }),
    matchPathSelector({
      path: `${assignmentCreatorUrl}/:contentType/:contentId/${CONTENT_TYPE_SOURCE}/:sourceId`,
    }),
    matchPathSelector({
      path: `${assignmentCreatorUrl}/:contentType/:contentId/${CONTENT_SUB_TYPE_STANDALONE}/:sourceId`,
    }),
    matchPathSelector({
      path: `${assignmentCreatorUrl}/:contentType/:contentId/${CONTENT_TYPE_SUBSECTION}/:subsectionId`,
    }),
    matchPathSelector({
      path: `${assignmentCreatorUrl}/:contentType/:contentId/*`,
    }),

    // assignment-editor
    matchPathSelector({
      path: `${assignmentEditorUrl}/:assignmentId/${CONTENT_TYPE_ECHO}/:echoId`,
    }),
    matchPathSelector({
      path: `${assignmentEditorUrl}/:assignmentId/${CONTENT_TYPE_SOURCE}/:sourceId`,
    }),
    matchPathSelector({
      path: `${assignmentEditorUrl}/:assignmentId/${CONTENT_TYPE_SOURCE}/:parentSourceId/${CONTENT_SUB_TYPE_STANDALONE}/:sourceId`,
    }),
    matchPathSelector({
      path: `${assignmentEditorUrl}/:assignmentId/${CONTENT_TYPE_SOURCE}/:sourceId/${CONTENT_TYPE_SUBSECTION}/:subsectionId`,
    }),
    matchPathSelector({
      path: `${assignmentEditorUrl}/:assignmentId/${CONTENT_TYPE_SUBSECTION}/:subsectionId`,
    }),
    matchPathSelector({ path: `${assignmentEditorUrl}/:assignmentId/*` }),

    // content-builder
    matchPathSelector({
      path: `${contentBuilderUrl}/curriculum/:contentType/:contentId`,
    }),

    // content-viewer
    matchPathSelector({
      path: `${contentViewerUrl}/:contentType/:contentId/${CONTENT_TYPE_ECHO}/:echoId`,
    }),
    matchPathSelector({
      path: `${contentViewerUrl}/:contentType/:contentId/${CONTENT_TYPE_SOURCE}/:sourceId`,
    }),
    matchPathSelector({
      path: `${contentViewerUrl}/:contentType/:contentId/${CONTENT_TYPE_SOURCE}/:parentSourceId/${CONTENT_SUB_TYPE_STANDALONE}/:sourceId`,
    }),
    matchPathSelector({
      path: `${contentViewerUrl}/:contentType/:contentId/${CONTENT_TYPE_SOURCE}/:sourceId/${CONTENT_TYPE_SUBSECTION}/:subsectionId`,
    }),
    matchPathSelector({
      path: `${contentViewerUrl}/${CONTENT_TYPE_SOURCE}/:contentId/${CONTENT_SUB_TYPE_STANDALONE}/:sourceId`,
    }),
    matchPathSelector({
      path: `${contentViewerUrl}/:contentType/:contentId/${CONTENT_TYPE_SUBSECTION}/:subsectionId`,
    }),
    matchPathSelector({
      path: `${contentViewerUrl}/:contentType/:contentId/*`,
    }),

    // assignments
    matchPathSelector({
      path: `${studentAssignmentsUrl}/:userAssignmentId/${CONTENT_TYPE_ECHO}/:echoId`,
    }),
    matchPathSelector({
      path: `${studentAssignmentsUrl}/:userAssignmentId/${CONTENT_TYPE_SOURCE}/:sourceId`,
    }),
    matchPathSelector({
      path: `${studentAssignmentsUrl}/:userAssignmentId/${CONTENT_TYPE_SOURCE}/:parentSourceId/${CONTENT_SUB_TYPE_STANDALONE}/:sourceId`,
    }),
    matchPathSelector({
      path: `${studentAssignmentsUrl}/:userAssignmentId/${CONTENT_TYPE_SOURCE}/:sourceId/${CONTENT_TYPE_SUBSECTION}/:subsectionId`,
    }),
    matchPathSelector({
      path: `${studentAssignmentsUrl}/:userAssignmentId/${CONTENT_TYPE_SUBSECTION}/:subsectionId`,
    }),
    matchPathSelector({
      path: `${studentAssignmentsUrl}/:userAssignmentId/${CONTENT_TYPE_ECHO}/:echoId`,
    }),
    matchPathSelector({ path: `${studentAssignmentsUrl}/:userAssignmentId/*` }),

    // binder
    matchPathSelector({
      path: `${studentBinderUrl}/:userAssignmentId/${CONTENT_TYPE_ECHO}/:echoId`,
    }),
    matchPathSelector({
      path: `${studentBinderUrl}/:userAssignmentId/${CONTENT_TYPE_SOURCE}/:sourceId`,
    }),
    matchPathSelector({
      path: `${studentBinderUrl}/:userAssignmentId/${CONTENT_TYPE_SOURCE}/:parentSourceId/${CONTENT_SUB_TYPE_STANDALONE}/:sourceId`,
    }),
    matchPathSelector({
      path: `${studentBinderUrl}/:userAssignmentId/${CONTENT_TYPE_SOURCE}/:sourceId/${CONTENT_TYPE_SUBSECTION}/:subsectionId`,
    }),
    matchPathSelector({
      path: `${studentBinderUrl}/:userAssignmentId/${CONTENT_TYPE_SUBSECTION}/:subsectionId`,
    }),
    matchPathSelector({ path: `${studentBinderUrl}/:userAssignmentId/*` }),
  ]
}

export const isInContentViewer = createSelector('isInContentViewer')(
  ...constructContentViewMatchPathSelectors({ isPinned: false }),
  (...selectors) =>
    !!selectors
      .filter(Boolean)
      .filter(compose(not, startsWith(contentBuilderUrl), get('pathname')))
      .length,
)

const constructMatchersForOtherUrl = withOptions(urlOtherThanCurrent =>
  createSelector('constructForOtherUrl')(
    ...constructContentViewMatchPathSelectors({
      isPinned: false,
      urlOtherThanCurrent,
    }),
    (...selectors) =>
      isEmptyString(urlOtherThanCurrent) ? false : selectors.some(Boolean),
  ),
)

export const wasInContentViewer = createSelector('wasInContentViewer')(
  identity,
  getPreviousLocation,
  (state, prev) =>
    isEmptyString(prev?.pathname) || prev.pathname.includes(contentBuilderUrl)
      ? false
      : constructMatchersForOtherUrl(prev.pathname)(state),
)

export const wouldBeInContentViewer = withOptions(urlOtherThanCurrent =>
  createSelector('wouldBeInContentViewer')(identity, state =>
    constructMatchersForOtherUrl(urlOtherThanCurrent)(state),
  ),
)

// an argument could be made that this really belongs in ./routing

export const getContentViewerParams = withOptions((options = {}) =>
  createSelector('getContentViewerParams')(
    'assignments',
    'userAssignments',

    ...constructContentViewMatchPathSelectors(options),

    (assignments, userAssignments, ...rest) =>
      compose(
        params =>
          Maybe.fromUndefined(params?.assignmentId)
            .orElse(
              Maybe.fromUndefined(params?.userAssignmentId)
                .map(id => Maybe.fromUndefined(userAssignments[id]))
                .fold(Maybe.Some({}))(identity)
                .flatMap(obj => Maybe.fromUndefined(obj?.assignmentId)),
            )
            .map(id => assignments[id] || /* istanbul ignore next */ {})
            .map(assignment => ({
              ...params,
              contentId: assignment.contentId,
            }))
            .orJust(params),
        params =>
          !isDefined(params?.navTreeSubsectionId) && isDefined(params?.sourceId)
            ? compose(set('navTreeSubsectionId', params.sourceId))(params)
            : params,
        /**
         * If this is a subsection, we set a shadow param for the TE copy in case there
         * are duplicates within the nav tree (chapter summaries, for example)
         */
        params =>
          isDefined(params?.subsectionId)
            ? compose(
                set('subsectionId', params.subsectionId.replace('TE-', '')),
                set('navTreeSubsectionId', params.subsectionId),
              )(params)
            : params,
        get('params'),
        first,
        filter(Boolean),
      )(rest),
  ),
)
