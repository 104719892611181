import type { Theme } from '@mui/material'
import CheckedHover from 'hss/images/controls/checkbox/checkbox-checked-hover.svg'
import Checked from 'hss/images/controls/checkbox/checkbox-checked.svg'
import UncheckedHover from 'hss/images/controls/checkbox/checkbox-hover.svg'
import Unchecked from 'hss/images/controls/checkbox/checkbox.svg'
import IndeterminateHover from 'hss/images/controls/checkbox/indeterminate-checked-hover.svg'
import Indeterminate from 'hss/images/controls/checkbox/indeterminate-checked.svg'
import { radiosAndCheckboxes } from './FormControlLabel'

const overrides = (theme: Theme) => {
  const {
    mixins: { sizeR, px },
    palette,
  } = theme

  return {
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: (
          <>
            <Checked className="checked" />
            <CheckedHover className="checked-hover" />
          </>
        ),
        color: 'primary',
        'data-color': 'primary',
        disableRipple: true,
        icon: (
          <>
            <Unchecked className="unchecked" />
            <UncheckedHover className="unchecked-hover" />
          </>
        ),
        indeterminateIcon: (
          <>
            <Indeterminate className="indeterminate" />
            <IndeterminateHover className="indeterminate-hover" />
          </>
        ),
      },

      styleOverrides: {
        root: {
          ...sizeR(2.4, 4.8),
          '& > svg': {
            minWidth: px(24),
            minHeight: px(24),
          },
          '&.Mui-disabled:not(.Mui-checked) svg:not(.indeterminate)': {
            color: palette.grey[3],
          },
        },
      },

      variants: [
        {
          props: { 'data-color': 'primary' },
          style: radiosAndCheckboxes(theme, palette.cobalt),
        },

        {
          props: { 'data-color': 'secondary' },
          style: radiosAndCheckboxes(theme, palette.grey),
        },
        {
          props: { variant: 'hide-control' },
          style: {
            display: 'none',
          },
        },
      ],
    },
  }
}

export default overrides
