import type { Theme } from '@mui/material'
import AlertTriangle from 'hss/images/controls/textfield/alert-triangle.png'

const overrides = ({
  mixins: { em, important, importantPx, importantRem, percentage },
  palette,
  typography,
}: Theme) => ({
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        color: palette.text.primary,
        letterSpacing: em(-0.02),
        ...typography.variants.small,
        fontStyle: important('initial'),
        fontWeight: 300,
        // important because typography targets the `p` element, which has higher specificity than the MUI class
        marginBottom: important(0),
        paddingTop: 5,
        '&.Mui-error': {
          display: 'inline-flex',
          alignItems: 'center',
          width: percentage(100),
          '&::before': {
            content: `url(${AlertTriangle})`,
            marginRight: 6,
            zoom: percentage(50), // make the icon a similar size to the font
          },
        },

        '&.Mui-disabled': {
          color: palette.grey[3],
        },
      },

      contained: { marginLeft: 0 },
    },

    variants: [
      {
        props: { 'data-variant': 'block' },
        style: {
          display: important('block'),
          margin: importantRem(1.5, 1),
          '&::before': {
            position: 'relative',
            top: 6,
          },
        },
      },

      {
        props: { 'data-variant': 'group' },
        style: {
          '&.MuiFormHelperText-root:not(:empty)': {
            marginBottom: importantRem(2),
          },
        },
      },

      {
        props: { 'data-variant': 'switch' },
        style: {
          marginLeft: importantPx(57),
        },
      },
    ],
  },
})

export default overrides
