import type { Theme } from '@mui/material'

const overrides = ({ mixins: { important }, palette }: Theme) => ({
  MuiMenu: {
    defaultProps: {
      MenuListProps: {
        style: { minWidth: 171 },
      },
    },

    styleOverrides: {
      paper: {
        backgroundColor: important(palette.background.default),
        boxShadow: important('none'),
        borderRadius: 0,
        filter: 'drop-shadow(2px 0px 4px rgba(0, 0, 0, 0.1))',
        paddingTop: important(0),
        paddingBottom: important(0),
      },
      list: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
})

export default overrides
