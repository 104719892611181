import type { Theme } from '@mui/material'

const overrides = ({ breakpoints, mixins: { rem } }: Theme) => ({
  MuiDialogContent: {
    styleOverrides: {
      root: {
        height: 'auto',
        overflowY: 'auto',
        padding: rem(0, 3.2, 1),
        img: {
          height: 'auto',
          maxWidth: '100%',
        },
        [breakpoints.down('md')]: {
          padding: rem(0, 2.5),
        },
      },
    },
  },
})

export default overrides
