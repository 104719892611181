import { addDays } from 'date-fns'
// import getMonth from 'date-fns/getMonth'
// import getYear from 'date-fns/getYear'
// import startOfMonth from 'date-fns/startOfMonth'

// export const currentMonth = () => getMonth(now())

// export const currentYear = () => getYear(now())

export const daysBefore = days => addDays(now(), -days)

// export const lastAugust = () => monthFromLastYear(7)

// export const monthFromLastYear = month => startOfMonth(new Date(currentYear() - 1, month, 1))

// export const monthFromThisYear = month => startOfMonth(new Date(currentYear(), month, 1))

// export const monthFromNextYear = month => startOfMonth(new Date(currentYear() + 1, month, 1))

// export const nextAugust = () => monthFromNextYear(7)

export const now = () => new Date()

// export const thisAugust = () => monthFromThisYear(7)

// export const MAX_DATE = new Date(8640000000000000)

// export const MAX_ISO_DATE = MAX_DATE.toISOString()
