import type { Theme } from '@mui/material'
import { important as allImportant, border, position } from 'polished'
import { ChevronDown } from 'react-feather'

const overrides = ({
  mixins: { borderS, rem, transition },
  palette,
  typography,
}: Theme) => ({
  MuiAccordionSummary: {
    defaultProps: {
      expandIcon: <ChevronDown />,
    },

    styleOverrides: {
      root: {
        ...border('top', 1, 'solid', palette.grey[0]),
        ...border('bottom', 1, 'solid', palette.grey[0]),
        paddingLeft: 0,
      },

      content: {
        ...typography.variants.navigation,
        paddingLeft: 24,
      },

      expandIconWrapper: {
        color: 'inherit',
        svg: {
          width: 18,
        },
      },
    },

    variants: [
      {
        props: { variant: 'form-section' },
        style: {
          borderTopStyle: 'none',
          borderBottomColor: 'transparent',
          '&.Mui-expanded': {
            borderBottomColor: palette.grey[4],
          },
          '.MuiAccordionSummary-content': {
            padding: rem(0.5, 0),
            letterSpacing: 'unset',
            ...typography.h4,
          },
        },
      },

      {
        props: {
          variant: 'form-section',
          'data-subvariant': 'feature-paragraph',
        },
        style: {
          '.MuiAccordionSummary-content': {
            ...typography.variants['feature-paragraph'],
          },
        },
      },

      {
        props: { variant: 'form-section-dense' },
        style: {
          borderStyle: 'none',
          '&::before': {
            ...transition('opacity'),
            ...position('absolute', -1, 0),
            height: 1,
            content: '""',
            opacity: 0,
            backgroundColor: palette.grey[4],
          },
          paddingLeft: rem(2),
          ...borderS('transparent'),
          borderTopStyle: 'none',
          borderBottomStyle: 'none',
          '&.Mui-expanded': {
            '&::before': {
              opacity: 1,
            },
            borderColor: palette.grey[4],
          },
          '.MuiAccordionSummary-content': {
            alignItems: 'center',
            textTransform: 'none',
            margin: 0,
            padding: rem(2, 0),
            '.MuiFormControl-marginNormal': {
              margin: 0,
            },
          },
        },
      },

      {
        props: { variant: 'collapsed-heading' },
        style: {
          borderTopStyle: 'none',
          borderBottomColor: 'transparent',
          '&.Mui-expanded': {
            borderBottomColor: palette.grey[4],
          },
          '.MuiAccordionSummary-content': {
            padding: rem(0.5, 0),
            letterSpacing: 'unset',
            ...typography.h4,
          },
        },
      },

      {
        props: { variant: 'interactive-grading' },
        style: {
          borderTopStyle: 'none',
          borderBottomColor: palette.grey[4],
          display: 'block',
          paddingRight: 0,
          '.MuiAccordionSummary-content': {
            justifyContent: 'center',
            padding: rem(0.5, 0),
            letterSpacing: 'unset',
          },
          '.MuiAccordionSummary-expandIconWrapper': {
            position: 'absolute',
            right: 0,
            top: 0,
            display: 'flex',
            alignItems: 'center',
            margin: rem(0.5, 0),
          },
        },
      },

      {
        props: { variant: 'peer-review' },
        style: {
          ...borderS(palette.border[1]),
          '&.Mui-expanded': {
            borderTopColor: 'currentColor',
            borderRightColor: 'currentColor',
            borderLeftColor: 'currentColor',
          },

          '.MuiAccordionSummary-content': {
            justifyContent: 'space-between',
            padding: rem(0, 0.5, 0, 1.5),
            textTransform: 'none',
            p: typography.variants['body1-semibold'],
          },
        },
      },

      {
        props: { variant: 'peer-review', 'data-featured': 'true' },
        style: {
          ...borderS(palette.canary[0]),
          '&.Mui-expanded': allImportant({
            borderTopColor: palette.canary[0],
            borderRightColor: palette.canary[0],
            borderBottomColor: palette.border[1],
            borderLeftColor: palette.canary[0],
          }),
        },
      },
      {
        props: { variant: 'rubric-display' },
        style: {
          border: 'none',
          backgroundColor: palette.background.default,
          '.MuiAccordionSummary-content': {
            textTransform: 'none',
            fontWeight: 'normal',
            letterSpacing: 'unset',
            margin: rem(2),
            padding: 0,
            ...typography.p,
          },
        },
      },
      {
        props: { 'data-subvariant': 'single-item' },
        style: {
          ...borderS(palette.border[0]),
          paddingRight: 0,
          '&.Mui-expanded': {
            borderTopColor: palette.border[0],
            borderRightColor: palette.border[0],
            borderLeftColor: palette.border[0],
          },
        },
      },
    ],
  },
})

export default overrides
