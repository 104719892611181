import type { Theme } from '@mui/material'

const overrides = ({
  mixins: { important, rem },
  palette,
  shadows,
}: Theme) => ({
  MuiToolbar: {
    styleOverrides: {
      root: {
        backgroundColor: palette.background.default,
        boxShadow: 'none',
      },
    },

    variants: [
      {
        props: { variant: 'appbar' },
        style: {
          display: 'flex',
          flexFlow: 'row nowrap',
          height: 80,
          justifyContent: 'space-between',
          paddingLeft: important('var(--containedPaddingLeft)'),
          paddingRight: important('var(--containedPaddingRight)'),
        },
      },
      {
        props: { variant: 'home' },
        style: {
          boxShadow: shadows[3],
          height: 72,
        },
      },
      {
        props: { variant: 'subsection' },
        style: {
          paddingLeft: important(0),
          paddingRight: important(0),
        },
      },
      {
        props: { variant: 'insertion' },
        style: {
          justifyContent: 'center',
          background:
            'linear-gradient(101.07deg, rgba(28, 91, 255, 0.5) 0%, rgba(110, 226, 245, 0.5) 102.07%);',
          padding: rem(1, 0),

          '& .district-label': {
            textTransform: 'uppercase',
            fontWeight: 600,
          },
        },
      },
    ],
  },
})

export default overrides
