import type { TreeItem2SlotProps, UseTreeItem2Status } from '@mui/x-tree-view'
import cl from 'classnames'
import { get } from 'fp/objects'
import { when } from 'fp/utils'
import useNavigation from 'hooks/useNavigation'
import { forwardRef } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { useSelector } from 'react-redux'
import { getLocation } from 'selectors/routing'
import EntryIcon from './EntryIcon'
import type { NavTreeEntry } from './TreeEntry'
import TreeEntryLabel from './TreeEntryLabel'

type TreeEntryContentProps = TreeItem2SlotProps & {
  entry: NavTreeEntry
  itemId: string
  onClick: (event: React.SyntheticEvent) => void
  onMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void
  status: UseTreeItem2Status
}

const TreeEntryContent = forwardRef<HTMLDivElement, TreeEntryContentProps>(
  (props, ref) => {
    const { entry, status, onClick, onMouseDown } = props

    const { expanded, disabled, focused } = status

    const leaf = !entry.items

    const { navigate } = useNavigation()
    const { pathname } = useSelector(getLocation)

    const handleLabelClick = (event: React.SyntheticEvent) => {
      const childIsCurrentRoute = entry.items?.map(get('to')).includes(pathname)
      event.stopPropagation()
      if (entry.href) {
        location.href = entry.href
        return
      }
      onClick(event)
      when(leaf || !entry.items.length, navigate, entry.to)
      when(
        !expanded && entry.items?.length && !childIsCurrentRoute,
        navigate,
        get('items.0.to')(entry),
      )
    }

    const selected = entry.matches || entry.items?.some(get('matches'))

    return (
      <div
        className={cl('MuiTreeItem2-content', {
          'Mui-expanded': expanded,
          'Mui-selected': selected,
          'Mui-focused': focused,
          'Mui-disabled': disabled,
          leaf,
        })}
        onClick={handleLabelClick}
        onMouseDown={onMouseDown}
        ref={ref}
        role="link">
        <div className="MuiTreeItem2-label">
          {!leaf && <EntryIcon entry={entry} />}

          <TreeEntryLabel entry={entry} />
        </div>

        {!leaf && entry.items.length > 0 && (
          <button
            className="MuiTreeItem2-iconContainer"
            onClick={onClick}
            style={{ display: 'block' }}
            tabIndex={-1}
            type="button">
            {expanded ? <ChevronUp /> : <ChevronDown />}
          </button>
        )}
      </div>
    )
  },
)

export default TreeEntryContent

export type { TreeEntryContentProps }
