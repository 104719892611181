import type {
  OverridableComponent,
  OverridableTypeMap,
} from '@mui/material/OverridableComponent'
import ConfirmationDialog from 'common/dialogs/ConfirmationDialog'
import { forwardRef } from 'react'
import { createConfirmation } from 'react-confirm'

const confirm = createConfirmation(ConfirmationDialog, 0)

interface Props {
  DialogProps?: Partial<typeof ConfirmationDialog>
  cancelLabel?: string
  confirmLabel?: string
  confirmationMessage?: string
  confirmationTitle?: string
  onClick: (e: React.MouseEvent) => void
}

const withConfirm = <T extends OverridableTypeMap>(
  WrappedComponent: OverridableComponent<T>,
): OverridableComponent<T> => {
  const Enhanced = forwardRef<OverridableComponent<T>, Props>((props, ref) => {
    const {
      DialogProps,
      cancelLabel = 'Cancel',
      confirmLabel = 'Yes',
      confirmationMessage = 'Are you sure?',
      confirmationTitle = 'Confirm',
      onClick,
      ...rest
    } = props

    const handleClick = async (e: React.MouseEvent) => {
      e.preventDefault()
      const shouldProceed = await confirm({
        primaryText: confirmationTitle,
        secondaryText: confirmationMessage,
        confirmLabel,
        cancelLabel,
        ...DialogProps,
      })

      if (shouldProceed) {
        onClick(e)
      }
    }

    return (
      <WrappedComponent
        {...(rest as T['props'])}
        onClick={handleClick}
        ref={ref}
        component={WrappedComponent}
      />
    )
  })

  return Enhanced as unknown as OverridableComponent<T>
}

export default withConfirm
