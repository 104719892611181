import { MenuItem, type SelectChangeEvent } from '@mui/material'
import Select from 'common/formControls/selects/Select'
import Checkbox from 'common/formControls/switches/Checkbox'
import TextField from 'common/formControls/textInputs/TextField'
import { type SyntheticEvent, forwardRef } from 'react'
import type { ImageInteractiveData } from '../@types'
import type { ImageDisplayMode } from 'common/formControls/textInputs/RichTextEdit/plugins/imagePlugin/ImageSelectionProvider'
// import TagsPicker from 'common/tags/TagsPicker'
import type {
  InlineImageSize,
  InlineImageVerticalAlign,
} from 'common/indicators/InlineImage'
import { reverse } from 'fp/arrays'

type AttributesProps = {
  data: ImageInteractiveData
  displayMode: ImageDisplayMode
  searchWasMade: boolean
  setData: (data: ImageInteractiveData) => void
  valign: InlineImageVerticalAlign
  setValign: (valign: InlineImageVerticalAlign) => void
  size: InlineImageSize
  setSize: (size: InlineImageSize) => void
}

type DropdownEntry<T> = { value: T; name: string }

type CaptionPositionEntry = DropdownEntry<CaptionPosition>
type SizeEntry = DropdownEntry<InlineImageSize>
type ValignEntry = DropdownEntry<InlineImageVerticalAlign>

const allowedCaptionPositions: CaptionPositionEntry[] = [
  { value: 'left', name: 'Left' },
  { value: 'bottom', name: 'Bottom' },
  { value: 'right', name: 'Right' },
]
//use the same vocabulary as image upload for consistency?
const allowedSizes: SizeEntry[] = [
  { value: 'tiny', name: 'Tiny' },
  { value: 'small', name: 'Small' },
  { value: 'medium', name: 'Medium' },
  { value: 'large', name: 'Large' },
]

const allowedValigns: ValignEntry[] = [
  { value: 'top', name: 'Top' },
  { value: 'middle', name: 'Middle' },
  { value: 'bottom', name: 'Bottom' },
]

function MenuItems<T extends string | number | readonly string[]>(
  entries: DropdownEntry<T>[],
) {
  return entries.map(({ value, name }) => (
    <MenuItem
      key={String(value)}
      value={value}>
      {String(name)}
    </MenuItem>
  ))
}

const Attributes = forwardRef<HTMLDivElement, AttributesProps>(
  (
    {
      data,
      displayMode,
      searchWasMade,
      setData,
      setSize,
      setValign,
      size,
      valign,
    },
    ref,
  ) => {
    const handleChange =
      (field: string) =>
      (event: SyntheticEvent | SelectChangeEvent<unknown>) => {
        const value = (event.target as HTMLInputElement).value

        switch (field) {
          case 'valign':
            return setValign(value as InlineImageVerticalAlign)
          case 'size':
            return setSize(value as InlineImageSize)
          default:
            setData({
              ...data,
              [field]: field === 'includeSwatch' ? value === 'on' : value,
            })
        }
      }

    const { caption, captionPosition, includeSwatch, imageAltText, sensitive } =
      data

    return (
      <div
        ref={ref}
        style={{ width: '100%' }}>
        {displayMode === 'block' && (
          <>
            <TextField
              label="Caption"
              value={caption}
              onChange={handleChange('caption')}
              placeholder=""
            />

            <Select
              value={captionPosition}
              name="captionPosition"
              label="Caption Position"
              onChange={handleChange('captionPosition')}>
              {MenuItems(allowedCaptionPositions)}
            </Select>
          </>
        )}

        {!searchWasMade && (
          <>
            <TextField
              onChange={handleChange('imageAltText')}
              label="Description"
              value={imageAltText}
              helperText={`
            This will be used as the alternate text for the image.
            This is used by screen readers and other assistive technologies`}
            />

            {/* <TagsPicker
              label=""
              sx={{ marginTop: 0 }}
              helperText={`
            Add tags to help this image be discoverable in searches`}
            /> */}

            <br />
            <br />
          </>
        )}

        {displayMode === 'block' && (
          <>
            <br />
            <br />

            <Checkbox
              onChange={handleChange('sensitive')}
              label="Contains sensitive content"
              description={''}
              checked={sensitive}
            />

            <br />
            <br />

            <Checkbox
              onChange={handleChange('includeSwatch')}
              label="Include Color Swatch"
              description={''}
              checked={includeSwatch}
            />
          </>
        )}

        {displayMode === 'inline' && (
          <>
            <Select
              value={size}
              name="size"
              label="Size"
              onChange={handleChange('size')}>
              {MenuItems(reverse(allowedSizes))}
            </Select>
            <br />
            <br />

            <Select
              value={valign}
              name="valign"
              label="Vertical Alignment"
              onChange={handleChange('valign')}>
              {MenuItems(allowedValigns)}
            </Select>
          </>
        )}
      </div>
    )
  },
)

export default Attributes
