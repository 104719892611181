import { moveCaretToEnd } from '@studysync/draft-js-modifiers'
import { convertFromHTML } from 'draft-convert'
import {
  CompositeDecorator,
  EditorState,
  Modifier,
  SelectionState,
} from 'draft-js'
import { callWith } from 'fp/call'
import { get } from 'fp/objects'
import { curryRight } from 'fp/utils'
import { compose } from 'redux'
import { assertWhitespaceAroundPlugins } from '../plugins/helpers/utils'

//TODO put this in '@studysync/draft-js-modifiers'
const trimEditorState = editorState => {
  const currentContent = editorState.getCurrentContent()
  let modified = false
  const newContent = currentContent
    .getBlockMap()
    .reduce((accumulator, block) => {
      const key = block.getKey()
      const text = block.getText()
      const trimmedLeft = text.trimLeft()
      const trimmedRight = text.trimRight()
      const offset = text.length - trimmedLeft.length
      const entityAtEnd = block.getEntityAt(text.length - 1)
      const entityAtStart = block.getEntityAt(0)
      let leftTrimmedContent
      let rightTrimmedContent

      if (entityAtEnd === entityAtStart && entityAtEnd !== null) {
        //entity is the only thing in here, no trimming
      } else if (!entityAtStart) {
        const textToReplaceLeft = new SelectionState({
          anchorKey: key,
          focusKey: key,
          anchorOffset: 0,
          focusOffset: offset,
        })

        leftTrimmedContent = Modifier.replaceText(
          accumulator,
          textToReplaceLeft,
          '',
        )
        modified = true
      } else if (!entityAtEnd) {
        const textToReplaceRight = new SelectionState({
          anchorKey: key,
          focusKey: key,
          anchorOffset: trimmedRight.length - offset,
          focusOffset: text.length - offset,
        })
        rightTrimmedContent = Modifier.replaceText(
          leftTrimmedContent || accumulator,
          textToReplaceRight,
          '',
        )
        modified = true
      }

      return rightTrimmedContent || leftTrimmedContent || accumulator
    }, currentContent)

  return modified
    ? EditorState.push(editorState, newContent, 'remove-range')
    : editorState
}

const compositeDecorator = (features, plugins) => {
  const decorators = plugins //
    .map(get('decorator'))
    .filter(Boolean)
    .flatMap(callWith(features))

  return new CompositeDecorator(decorators)
}

const htmlToBlock = (features, plugins) => (nodeName, node) =>
  plugins
    .map(get('htmlToBlock'))
    .filter(Boolean)
    .map(callWith(features))
    .reduce((acc, f) => f(acc, nodeName, node), undefined)

const htmlToEntity = (features, plugins) => (nodeName, node, createEntity) =>
  plugins
    .map(get('htmlToEntity'))
    .filter(Boolean)
    .map(callWith(features))
    .reduce((acc, f) => f(nodeName, node, createEntity) || acc, undefined)

const htmlToStyle = (features, plugins) => (nodeName, node, currentStyle) =>
  plugins
    .map(get('htmlToStyle'))
    .filter(Boolean)
    .map(callWith(features))
    .reduce((acc, f) => f(nodeName, node, acc), currentStyle)

// We currently don't have any plugins that implement textToEntity.
// const textToEntity = (features, plugins) => (text, createEntity) => plugins
//   .map(get('textToEntity'))
//   .filter(Boolean)
//   .map(callWith(features))
//   .reduce((acc, f) => f(text, createEntity, acc), [])

const importingPostProcess = (features, plugins) => editorState =>
  plugins
    .map(get('importingPostProcess'))
    .filter(Boolean)
    .map(callWith(features))
    .reduce((acc, f) => f(acc), editorState)

const htmlToContent = (value, features, plugins) => {
  const handlers = {
    htmlToBlock: htmlToBlock(features, plugins),
    htmlToEntity: htmlToEntity(features, plugins),
    htmlToStyle: htmlToStyle(features, plugins),
    // textToEntity: textToEntity(features, plugins),
  }

  const decorators = compositeDecorator(features, plugins)

  return compose(
    moveCaretToEnd,
    assertWhitespaceAroundPlugins,
    trimEditorState,
    importingPostProcess(features, plugins),
    curryRight(EditorState.createWithContent, decorators),
    // enable to debug the raw content
    // (contentState) => {
    //   console.log('contentState', JSON.stringify(convertToRaw(contentState), null, 2))
    //   return contentState
    // },
    convertFromHTML(handlers),
  )(value)
}

export default htmlToContent
