import Dialog from 'common/dialogs/Dialog'
import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import { assert, alter } from 'core/store/search/squery'
import useAbilityCheck from 'hooks/useAbilityCheck'
import useDataset from 'hooks/useDataset'
import { contentAuthorInteractives } from 'hss/ContentBuilder/consts'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { restEndpoint } from 'reducers/utils'
import { compose } from 'redux'
import {
  ABILITY_SELF_CONTENT_AUTHORING,
  CONTENT_ORIGIN_CREATED_BY_SELF,
  CONTENT_ORIGIN_SHARED,
} from 'shared/consts'
import DialogPagination from './DialogPagination'
import InteractivesFilter from './InteractivesFilter'
import InteractivesTable from './InteractivesTable'

const initialSquery = compose(
  alter.set.limit(10),
  alter.set.orderBy('name', 'asc'),
  alter.set.where('contentType').is(CONTENT_TYPE_INTERACTIVE),
)(assert())

const ChooseInteractiveDialog = ({
  excludeIds,
  onClose,
  onComplete,
  subContentTypes,
}) => {
  const tableRef = useRef()
  const [squery, setSquery] = useState(initialSquery)
  const dataset = useDataset({ restEndpoint: restEndpoint.content, squery })

  const hasContentAuthoringAbility = useAbilityCheck(
    ABILITY_SELF_CONTENT_AUTHORING,
  )

  // Content Authors have restrictions in the dialog; they cannot see every interactive subType
  const filteredSubContentTypes = hasContentAuthoringAbility
    ? contentAuthorInteractives
    : subContentTypes

  useEffect(() => {
    setSquery(alter.set.where('id').notIn(excludeIds))
  }, [excludeIds])

  useEffect(() => {
    if (filteredSubContentTypes.length) {
      setSquery(alter.set.where('contentSubType').in(filteredSubContentTypes))
    }
  }, [filteredSubContentTypes])

  useEffect(() => {
    if (hasContentAuthoringAbility) {
      setSquery(
        alter.set
          .modifier('forContentOrigin')
          .is([CONTENT_ORIGIN_CREATED_BY_SELF, CONTENT_ORIGIN_SHARED]),
      )
    }
  }, [hasContentAuthoringAbility])

  return (
    <Dialog
      disableAutoFocus
      maxWidth="sm"
      onClose={onClose}
      open
      showCloseButton
      swatch
      title="Insert Interactive"
      variant="maximized-vertically">
      <InteractivesFilter
        {...{ squery, setSquery, subContentTypes: filteredSubContentTypes }}
      />

      <InteractivesTable {...{ dataset, onComplete, ref: tableRef }} />

      <DialogPagination
        {...{ dataset, squery, setSquery, scrollToResultsRef: tableRef }}
      />
    </Dialog>
  )
}

ChooseInteractiveDialog.propTypes = {
  excludeIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  subContentTypes: PropTypes.array.isRequired,
}

export default ChooseInteractiveDialog
