import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import cl from 'classnames'
import MaybeDraft from 'common/indicators/Draft'
import BaseTE from 'common/indicators/TE'
import ScreenReaderText from 'common/text/ScreenReaderText'
import { COMPLETION_DONE, COMPLETION_INDETERMINATE } from 'projections/content'
import type { Content } from 'reducers/@types/content'
import { isSubsectionLike } from 'selectors/content'

const TE = styled(BaseTE, { name: 'TocNavItemLabel-TE' })(
  ({
    theme: {
      mixins: { px },
      palette,
      typography,
    },
  }) => ({
    marginLeft: px(8),
    ...typography.variants['nav-item-uppercase-semibold'],
    color: palette.navigation.tocLight,
    display: 'inline-block',
  }),
)

const ItemLabel = ({
  className,
  item,
}: {
  className?: string
  item: Content
}) => {
  const { calculatedCompletion, data, name, teacherEdition } = item
  const isTE = teacherEdition

  const leaf = data?.tabbed || isSubsectionLike(item)

  return (
    <Typography
      className={cl(className, { leaf })}
      component="div"
      variant="nav-item">
      {name}
      {calculatedCompletion === COMPLETION_DONE && (
        <ScreenReaderText>(complete)</ScreenReaderText>
      )}
      {calculatedCompletion === COMPLETION_INDETERMINATE && (
        <ScreenReaderText>(partially complete)</ScreenReaderText>
      )}{' '}
      {Boolean(isTE) && <TE />}
      <MaybeDraft
        content={item}
        small
      />
    </Typography>
  )
}

export default ItemLabel
