import type { Theme } from '@mui/material'

const overrides = ({ mixins: { em, rem }, palette }: Theme) => ({
  MuiInputLabel: {
    defaultProps: {
      // shrink: true,
    },

    styleOverrides: {
      root: {
        color: palette.grey[0],
        fontSize: rem(1.6),
        lineHeight: '157%',
        letterSpacing: em(-0.02),

        '&.Mui-disabled': {
          color: palette.grey[3],
        },
      },

      filled: {
        top: -4,

        '&.MuiInputLabel-shrink': {
          fontSize: rem(1.4),
          left: -12,
          top: -16,
          transform: 'translate(14px, -9px)',
        },
      },

      outlined: {
        top: -4,

        '&.MuiInputLabel-shrink': {
          fontSize: rem(1.4),
          left: -12,
          top: -16,
          transform: 'translate(14px, -9px)',
        },
      },

      shrink: {
        fontSize: rem(1.4),
        top: -4,
        transform: 'translate(0, -1.5px)',
      },
    },

    variants: [],
  },
})

export default overrides
