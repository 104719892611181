import type { AbilityFlag } from 'hooks/@types/ability'
import type { FeatureFlag } from 'hooks/@types/featureFlag'
import { useSelector } from 'react-redux'
import { createAbilityChecker } from 'selectors/userAbility'

type AbilityChecker = (
  flags: AbilityFlag | FeatureFlag | Array<AbilityFlag | FeatureFlag>,
) => boolean

const useAbilityChecker = (): AbilityChecker =>
  useSelector(createAbilityChecker)

export type { AbilityChecker, AbilityFlag }

export default useAbilityChecker
