import { Button, DialogActions, Stack } from '@mui/material'
import AssetUploader from 'common/formControls/selects/AssetUploader'
import { useImageSelectionContext } from 'common/formControls/textInputs/RichTextEdit/plugins/imagePlugin/ImageSelectionProvider'
import type { AssetUploadedProps } from '../@types'
import Attributes from './Attributes'
import Sample from './Sample'

type ImageSelectionProps = {
  upload?: AssetUploadedProps | undefined
  setUpload?: (upload: AssetUploadedProps) => void
}

const ImageSelection: React.FC<ImageSelectionProps> = props => {
  const {
    data,
    displayMode,
    searchWasMade,
    setData,
    setSize,
    setUpload,
    setValign,
    size,
    upload,
    uploadStep,
    uploadWasMade,
    valign,
  } = useImageSelectionContext()

  const handleChange = ({
    target: { value },
  }: {
    target: { value: AssetUploadedProps }
  }) => {
    setUpload(value)
  }

  return (
    <>
      <Stack
        direction="row"
        gap={2}>
        {uploadStep === 'upload' && (
          <AssetUploader
            onUpload={handleChange}
            onClear={() => uploadWasMade && setUpload(undefined)}
            uploadType="image"
            name="author-image"
            FileUploaderButtonProps={{
              children: 'Upload from Computer…',
            }}
          />
        )}

        {uploadStep === 'annotate' && upload?.url && (
          <Attributes
            {...{
              data,
              displayMode,
              searchWasMade,
              setData,
              setSize,
              setValign,
              size,
              valign,
            }}
          />
        )}

        {upload?.url && (
          <Sample
            {...{
              displayMode,
              upload,
              uploadStep,
              valign,
              size,
            }}
          />
        )}
      </Stack>
    </>
  )
}

export default ImageSelection
