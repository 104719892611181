import type { Theme } from '@mui/material'
import {
  type ApplicatorFn,
  svgColor,
  themeColorVariants,
} from './accentColorUtils'

const applicator: ApplicatorFn = ({ color, getProperty, variant }) => ({
  props: { color, variant },

  style: {
    backgroundColor: getProperty('backgroundColor'),
    color: getProperty('color'),
    ...svgColor(getProperty('color')),

    '&:hover': {
      color: getProperty('hoverColor'),
      ...svgColor(getProperty('hoverColor')),
    },

    '&.Mui-disabled': {
      backgroundColor: getProperty('disabledBackground'),
      color: getProperty('disabledColor'),
      ...svgColor(getProperty('disabledColor')),
    },
  },
})

const overrides = (theme: Theme) => {
  const {
    mixins: { transition },
  } = theme

  return {
    MuiButtonBase: {
      defaultProps: {
        color: 'secondary',
        disableRipple: true,
      },

      variants: themeColorVariants(theme, applicator),

      styleOverrides: {
        root: transition(),
      },
    },
  }
}

export default overrides
