import type { Theme } from '@mui/material/index'
import {
  containedPaddingLeft,
  containedPaddingRight,
  featuredContentMaxWidth,
} from 'styling/theming/base'

const overrides = ({ mixins: { important, px }, typography }: Theme) => ({
  MuiContainer: {
    styleOverrides: {
      root: {
        paddingLeft: important('var(--containedPaddingLeft)'),
        paddingRight: important('var(--containedPaddingRight)'),
        position: 'relative',
        maxWidth: 'unset !important',
        ...typography.variants['feature-paragraph'],
      },
    },
    variants: [
      {
        props: { variant: 'centered' },
        style: {
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        },
      },

      {
        props: { variant: 'centered-content' },
        style: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          maxWidth: important(
            `calc(${px(featuredContentMaxWidth)} + ${containedPaddingLeft} + ${containedPaddingRight})`,
          ),
        },
      },

      {
        props: { variant: 'list-column' },
        style: {
          maxWidth: 800,
        },
      },

      {
        props: { variant: 'margin-auto' },
        style: {
          paddingLeft: important('unset'),
          paddingRight: important('unset'),
          '&, > *, ul > li': {
            marginLeft: 'auto',
            marginRight: 'auto',
          },
        },
      },

      {
        props: { variant: 'author-split-pane' },
        style: {
          // paddingLeft: important('unset'),
          paddingRight: important('unset'),
          '&, > *, ul > li': {
            marginLeft: 'auto',
            marginRight: 'auto',
          },
        },
      },
    ],
  },
})

export default overrides
