import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import {
  ABILITY_SELF_CONTENT_AUTHORING,
  CONTENT_TYPE_RUBRIC,
  CONTENT_TYPE_SOURCE,
  INTERACTIVE_TYPE_ESSAY,
  INTERACTIVE_TYPE_MULTIPLE_CHOICE,
  INTERACTIVE_TYPE_POLL,
} from 'core/consts'
import { mapValues, set } from 'fp/objects'
import BlockEditor from 'hss/ContentAuthor/BlockEditor'
import StructuredSource from 'hss/ContentAuthor/StructuredSource'
import Essay from 'hss/ContentAuthor/interactives/Essay'
import MultipleChoice from 'hss/ContentAuthor/interactives/MultipleChoice'
import Poll from 'hss/ContentAuthor/interactives/Poll'
import Rubric from 'hss/ContentAuthor/interactives/Rubric'
import { withEdit } from 'hss/ContentBuilder/utils'
import { createElement } from 'react'
/* istanbul ignore file */
import { Routes } from 'react-router-dom'
import Route404 from 'routing/Route404'
import { configToRoutes } from 'routing/TraverseRoute'
import { CONTENT_SUB_TYPE_STRUCTURED } from 'shared/consts'

const config = mapValues(set('abilityFlags', [ABILITY_SELF_CONTENT_AUTHORING]))(
  {
    /** ***************************** CONTENT ********************************* */
    create1: {
      path: 'create/:contentType',
      element: <BlockEditor creating />,
    },

    createStructuredSource: {
      // Note: this URL is actually never rendered. Instead, we have a saga over in
      // `content.js` to look at the location change and create a new Section automatically.
      path: `create/${CONTENT_TYPE_SOURCE}/type/${CONTENT_SUB_TYPE_STRUCTURED}`,
      element: <div />,
    },

    create2: {
      path: 'create/:contentType/type/:contentSubType',
      element: <BlockEditor creating />,
    },

    createEssay: {
      path: `create/:contentType/type/${INTERACTIVE_TYPE_ESSAY}`,
      element: <Essay />,
    },

    createMultipleChoice: {
      path: `create/:contentType/type/${INTERACTIVE_TYPE_MULTIPLE_CHOICE}`,
      element: <MultipleChoice />,
    },

    createPoll: {
      path: `create/:contentType/type/${INTERACTIVE_TYPE_POLL}`,
      element: <Poll />,
    },

    createRubric: {
      path: `create/${CONTENT_TYPE_RUBRIC}`,
      element: <Rubric />,
    },

    edit1: {
      path: 'edit/:contentType/:contentId',
      element: <BlockEditor editing />,
    },

    editStructuredSource: {
      path: `edit/${CONTENT_TYPE_SOURCE}/type/${CONTENT_SUB_TYPE_STRUCTURED}/:contentId`,
      element: <StructuredSource editing />,
    },

    edit2: {
      path: 'edit/:contentType/type/:contentSubType/:contentId',
      element: <BlockEditor editing />,
    },

    editEssay: {
      path: `edit/:contentType/type/${INTERACTIVE_TYPE_ESSAY}/:contentId`,
      element: createElement(withEdit(Essay)),
    },

    editMultipleChoice: {
      path: `edit/:contentType/type/${INTERACTIVE_TYPE_MULTIPLE_CHOICE}/:contentId`,
      element: createElement(withEdit(MultipleChoice)),
    },

    editPoll: {
      path: `edit/:contentType/type/${INTERACTIVE_TYPE_POLL}/:contentId`,
      element: createElement(withEdit(Poll)),
    },

    editRubric: {
      path: `edit/${CONTENT_TYPE_RUBRIC}/:contentId`,
      element: createElement(withEdit(Rubric)),
    },

    404: {
      path: '*',
      element: <Route404 collectionName="ContentAuthor" />,
    },
  },
)

const routes = configToRoutes(config)

const ContentAuthorRoutes = () => (
  <ErrorBoundary moduleName="ContentAuthorRoutes">
    <Routes>{routes}</Routes>
  </ErrorBoundary>
)

export default ContentAuthorRoutes
