// import compareAsc from 'date-fns/compareAsc'
// import compareDesc from 'date-fns/compareDesc'
import { format, formatDistanceToNow, parseISO } from 'date-fns'
import { enUS } from 'date-fns/locale'
import { map } from 'fp/arrays'
import { pluralize, split } from 'fp/strings'
import { curryRight } from 'fp/utils'
import { Maybe } from 'monet'
import { compose } from 'redux'
// import { tzAbbr } from './timezones'

/**
 * TODO: Remove hardcoded locale
 * https://date-fns.org/v1.29.0/docs/I18n
 */

// const dateCompareDirection = {
//   asc: compareAsc,
//   desc: compareDesc,
// }

type MaybeDate = Date | string | null

const dateShortFormatString = 'MM/dd/yyyy'
const timeFormatString = 'h:mm a'
const dateLongFormatString = 'MMM d, yyyy'

// const formatDateIso = date => format(date, 'yyyy-MM-dd', { locale: enUS })
const formatDateLong = (date: Date) =>
  format(date, dateLongFormatString, { locale: enUS })
const formatDateShort = (date: Date) =>
  format(date, dateShortFormatString, { locale: enUS })
// const formatDateTimeShort = date => format(date, `${dateShortFormatString} ${timeFormatString}`, { locale: enUS })
const formatDateWithFormatString = (formatString: string) => (date: Date) =>
  format(date, formatString, { locale: enUS })

const formatDateTimeLong = (date: Date) =>
  format(date, `${dateLongFormatString} ${timeFormatString}`, { locale: enUS })
const formatTime = (date: Date) =>
  format(date, timeFormatString, { locale: enUS })

const pad = (value: number | string, digits = 2) =>
  String(value).padStart(digits, '0')
const msToSeconds = (ms: number) => Math.trunc(ms / 1000)
const minutes = (sec: number) => Math.floor(sec / 60)

const msToTime = compose(
  sec => `${pad(minutes(sec), 1)}:${pad(sec % 60)}`,
  msToSeconds,
)

const parseDate = (date: MaybeDate) => {
  if (date instanceof Date && !Number.isNaN(date.valueOf())) {
    // this is already a date, so just return it as-is
    return date
  }
  return parseISO(date as string)
}

// const dateCompare = (date1, date2, direction = 'asc') => dateCompareDirection[direction](date1, date2)

// const formatDateStrIso = date => Maybe.fromNull(date)
//   .map(parseDate)
//   .map(formatDateIso)
//   .orNull()

const formatDateStrShort = (date: MaybeDate) =>
  Maybe.fromNull(date).map(parseDate).map(formatDateShort).orNull()

const formatDateStrLong = (date: MaybeDate) =>
  Maybe.fromNull(date).map(parseDate).map(formatDateLong).orNull()

// const formatDateTimeStrShort = date => Maybe.fromNull(date)
//   .map(parseDate)
//   .map(formatDateTimeShort)
//   .orNull()

const formatDateTimeStrWithFormatString =
  (formatString: string) => (date: MaybeDate) =>
    Maybe.fromNull(date)
      .map(parseDate)
      .map(formatDateWithFormatString(formatString))
      .orNull()

const formatDateTimeStrLong = (date: MaybeDate) =>
  Maybe.fromNull(date).map(parseDate).map(formatDateTimeLong).orNull()

// const formatDateTimeStrLongTZ = date => Maybe
//   .fromNull(formatDateTimeStrLong(date))
//   .map(suffix(` ${tzAbbr(date)}`))
//   .orNull()

const formatRelativeTime = (value: MaybeDate) =>
  Maybe.fromFalsy(value)
    .map(parseDate)
    .map(curryRight(formatDistanceToNow, { addSuffix: true }))
    .orNull()

const formatSec = compose(msToTime, v => v * 1000)

const formatTimeStr = (value: MaybeDate) =>
  Maybe.fromFalsy(value).map(parseDate).map(formatTime).orNull()

const msToTimeText = compose(
  ([m, s]) => `${pluralize('minute')(m)} ${pluralize('second')(s)}`,
  map(Number.parseFloat),
  split(':'),
  msToTime,
)

// const parseDateFromField = obj => field => Maybe.fromNull(obj[field]) //
//   .map(parseDate)
//   .orNull()

export {
  dateShortFormatString,
  // formatDateLong,
  // formatDateShort,
  formatDateStrLong,
  formatDateStrShort,
  // formatDateTimeLong,
  formatDateTimeStrLong,
  formatDateTimeStrWithFormatString,
  // formatDateWithFormatString,
  formatRelativeTime,
  formatSec,
  // formatTime,
  formatTimeStr,
  // minutes,
  // msToSeconds,
  msToTime,
  msToTimeText,
  // pad,
  parseDate,
  timeFormatString,
}
