import type { Theme } from '@mui/material'
import { position } from 'polished'

const overrides = ({
  breakpoints,
  mixins: { rem, safari },
  palette,
}: Theme) => ({
  MuiDialog: {
    styleOverrides: {
      container: {
        bottom: 0,
        display: 'block',
        left: 0,
        padding: '10% 0',
        position: 'fixed',
        right: 0,
        top: 0,
        [breakpoints.down('sm')]: {
          padding: 0,
        },
      },
      paper: {
        borderRadius: 2,
        margin: '0 auto',
        backgroundColor: palette.background.default,

        /**
         * These are the minimum heights for a dialog with a title, action buttons,
         * and a single line of text
         */
        minHeight: 250,
        ...safari({ minHeight: 258 }),
      },
      paperFullScreen: {
        [breakpoints.up('sm')]: position('absolute', 0, 0, 0, 0),
        maxWidth: 'unset',
      },
      paperScrollPaper: {
        maxHeight: 'fit-content',
      },
      paperWidthXs: {
        maxWidth: 600,
      },
      paperWidthSm: {
        maxWidth: 600,
      },
      paperWidthMd: {
        [breakpoints.down('xs')]: {
          display: 'block',
          margin: '0 auto',
          maxWidth: 770,
        },
        [breakpoints.up('sm')]: {
          maxWidth: 600,
          minWidth: 600,
        },
      },
      paperWidthLg: {
        [breakpoints.down('sm')]: {
          display: 'block',
          margin: '0 auto',
          maxWidth: 970,
        },
        [breakpoints.up('md')]: {
          maxWidth: 820,
          minWidth: 820,
        },
      },
      scrollPaper: {
        overflowY: 'auto',
      },
    },

    variants: [
      {
        props: { variant: 'maximized-vertically' },
        style: {
          '.MuiDialog-container': {
            padding: '2% 0',
          },
          '.MuiDialog-paper': {
            maxHeight: 'unset',
          },
        },
      },
      {
        props: { variant: 'swatch' },
        style: {
          '.MuiDialogContent-root': {
            paddingLeft: rem(4.5),
            paddingRight: rem(4.5),
          },
        },
      },
    ],
  },
})

export default overrides
