import {
  type TreeItem2SlotProps,
  type UseTreeItem2Status,
  useTreeItemState,
} from '@mui/x-tree-view'
import cl from 'classnames'
import {
  ABILITY_CONTENT_RESTRICTION,
  CONTENT_SUBTYPE_CHAPTER_SUMMARY,
  CONTENT_TYPE_BLOCK,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
} from 'core/consts'
import { first } from 'fp/arrays'
import { noop } from 'fp/utils'
import useAbilityCheck from 'hooks/useAbilityCheck'
import useNavigation from 'hooks/useNavigation'
import { assignmentEditContext } from 'hss/AssignmentEditor/assignmentEditContext'
import { buildSourceUrlPath } from 'projections/content'
import { forwardRef, useContext } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import type { Content } from 'reducers/@types/content'
import ItemExclusionIndicator from './ItemExclusionIndicator'
import ItemIcon from './ItemIcon'
import ItemLabel from './ItemLabel'

type ItemContentProps = TreeItem2SlotProps & {
  isNestedSection: boolean
  item: Content
  itemId: string
  onClick: (event: React.SyntheticEvent) => void
  onMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void
  status: UseTreeItem2Status
}

const ItemContent = forwardRef<HTMLDivElement, ItemContentProps>(
  (props, ref) => {
    const { isNestedSection, item, itemId, status, onClick, onMouseDown } =
      props

    const { expanded, disabled, focused } = status

    const leaf =
      item.data?.tabbed ||
      !item.children?.length ||
      first(item.children).contentType === CONTENT_TYPE_BLOCK

    const { selected } = useTreeItemState(itemId)

    const { navigate } = useNavigation()

    // 2025-03-07: Temporarily showing the insertion indicator for District Admins.
    //   We want to show it for all users, but need to iterate on the actual icon.
    const canRemoveInsertion = useAbilityCheck(ABILITY_CONTENT_RESTRICTION)
    // const canRemoveInsertion = useSelector(canRemoveInsertedContent(item))

    const handleLabelClick = (event: React.MouseEvent<HTMLDivElement>) => {
      onClick(event)
      if ((isNestedSection || item.data?.tabbed) && item?.children?.length) {
        navigate(`${item.children[0].contentType}/${item.children[0].id}`)
      } else if (isNestedSection && item?.children?.length) {
        navigate(`${CONTENT_TYPE_SUBSECTION}/${item.children[0].id}`)
      } else if (item.contentType === CONTENT_TYPE_ECHO) {
        navigate(`${CONTENT_TYPE_ECHO}/${item.id}`)
      } else if (leaf) {
        if (item.parent?.contentType === CONTENT_TYPE_SOURCE) {
          navigate(buildSourceUrlPath(item))
        } else {
          navigate(`${item.contentType}/${item.id}`)
        }
      } else if (
        (item.teacherEdition || item.data?.tabbed) &&
        item?.children?.length
      ) {
        navigate(`${CONTENT_TYPE_SUBSECTION}/${item.children[0].id}`)
      } else {
        onClick(event)
      }
    }

    const context = useContext(assignmentEditContext)
    const isViewingAssignment = Boolean(context)
    const { includeChapterSummary, isContentIdExcluded = noop } = context || {}
    const isMyContentIdExcluded =
      isContentIdExcluded(item.id) && !item.teacherEdition

    const hideSummaryForPresenterMode =
      isViewingAssignment &&
      item.contentSubType === CONTENT_SUBTYPE_CHAPTER_SUMMARY &&
      !includeChapterSummary

    return hideSummaryForPresenterMode ? null : (
      <div
        className={cl('MuiTreeItem2-content', {
          'Mui-expanded': expanded,
          'Mui-selected': selected,
          'Mui-focused': focused,
          'Mui-disabled': disabled,
          leaf: leaf || isNestedSection,
          'customer-insertion': canRemoveInsertion && !!item.insertionData,
          //'customer-insertion': canRemoveInsertion,
        })}
        onClick={handleLabelClick}
        onMouseDown={onMouseDown}
        ref={ref}
        role="link">
        <div className="MuiTreeItem2-label">
          <ItemIcon item={item} />

          <ItemLabel
            className={cl({ excluded: isMyContentIdExcluded })}
            item={item}
          />
        </div>

        <div>
          <ItemExclusionIndicator item={item} />

          {!leaf && (
            <button
              className="MuiTreeItem2-iconContainer"
              onClick={onClick}
              style={{ display: 'block' }}
              tabIndex={-1}
              type="button">
              {expanded ? <ChevronUp /> : <ChevronDown />}
            </button>
          )}
        </div>
      </div>
    )
  },
)

export default ItemContent
