import type { Theme } from '@mui/material'
import { border, padding } from 'polished'

const overrides = ({
  mixins: { absHeight, important, px, rem, size, transition },
  palette,
}: Theme) => ({
  MuiTreeItem2: {
    variants: [
      {
        props: { variant: 'nav-shell' },
        style: {
          color: palette.navigation.main,
          lineHeight: px(20),

          '.MuiTreeItem2-iconContainer': {
            svg: {
              color: palette.navigation.main,
              width: 18,
              paddingTop: 2,
            },
            marginRight: 4,
            width: important('unset'),
          },

          '&[data-haschildren="true"] ul': {
            marginTop: 10,
          },

          '.MuiTreeItem-content': {
            // not a typo. this is still used within mui
            padding: 0,
            margin: 0,
          },

          '.MuiTreeItem-root': {
            '&[aria-selected="true"]': {
              backgroundColor: 'transparent',
              color: palette.grey.contrastText,

              '.icon-container::before': {
                content: "''",
                background: palette.grey[0],
                borderRadius: '50%',
                position: 'absolute',
                ...size(40),
                zIndex: -1,
                transform: 'translate(-8px,-8px)',
              },

              '&.Mui-focused': {
                backgroundColor: 'transparent',
              },
            },
          },

          '.MuiTreeItem2-content': {
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'space-between',
            margin: px(32, 0),

            '&[role="link"]': {
              cursor: 'pointer',
            },

            '&.leaf': {
              padding: rem(1.6, 0, 1.6, 4),
              margin: 0,
              ...border('left', 1, 'solid', palette.border.dark),
              '&:first-of-type': {
                marginTop: important(0),
              },
            },

            '.icon-filled': { opacity: 0 },

            '.MuiTreeItem2-label': transition(),

            '&.customer-insertion .MuiTreeItem2-label': {
              '&::before': {
                content: "'⌅'",
                color: palette.info.main,
                marginRight: 4,
              },
            },

            '&:hover': {
              backgroundColor: 'transparent',
              '.icon-unfilled': { opacity: 0 },
              '.icon-filled': { opacity: 1 },
            },

            '> div': {
              display: 'flex',
              alignItems: 'center',
              gap: 15,
            },

            '&:not(.Mui-expanded):hover': {
              color: palette.grey.contrastText,
            },
          },
        },
      },

      {
        props: { 'data-subvariant': 'content-viewer' },
        style: {
          marginTop: 2,

          '.MuiTreeItem-root': {
            '&[aria-selected="true"]': {
              textDecoration: 'underline',
              textUnderlineOffset: rem(0.4),
              ...transition(),

              '.leaf': {
                fontWeight: 600,
              },

              '.icon-container::before': {
                background: 'unset !important',
              },
            },
          },

          '.MuiTreeItem2-label, svg': {
            color: palette.primary.contrastText,
          },

          '.MuiTreeItem2-label.excluded': {
            color: palette.navigation.main,
          },

          '.icon-container': {
            ...absHeight(15),
            svg: {
              color: palette.navigation.icon,
              width: 15,
              height: 15,
              strokeWidth: 2,
              '&[data-complete="true"], &[data-viewed="true"]': {
                color: palette.navigation.toc,
                strokeWidth: 3,
              },
              '&[data-viewed="true"]:not([data-complete="true"])': {
                strokeWidth: 3,
              },
            },
          },

          '.MuiTreeItem2-content': {
            minHeight: 40,
            height: 'auto',
            margin: 0,
            '> div': {
              gap: 0,
            },

            '&.leaf': {
              borderStyle: 'none',
              ...padding(0, 12, 0, 0),
            },
          },
        },
      },
    ],
  },
})

export default overrides
