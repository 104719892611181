import {
  ABILITY_CONTENT_CREATION,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_VOCABULARY,
  ROLE_STAFF,
  ROLE_STUDENT,
} from 'core/consts'
import {
  ABILITY_SELF_CONTENT_AUTHORING,
  CONTENT_SUB_TYPE_STANDALONE,
  CONTENT_SUB_TYPE_STRUCTURED,
  CONTENT_TYPE_RUBRIC,
  FEATURE_FLAG_CUSTOMER_CONTENT,
  INTERACTIVE_TYPE_AUDIO,
  INTERACTIVE_TYPE_IMAGE,
  INTERACTIVE_TYPE_VIDEO,
  LINKABLE_CONTENT_TYPES,
} from 'shared/consts'
import { createSelector } from './index'
import { createAbilityChecker } from './userAbility'
import { getCurrentRoleId } from './users'

export const getSearchableLibraryContentTypes = createSelector(
  'getSearchableLibraryContentTypes',
)(getCurrentRoleId, createAbilityChecker, (roleId, has) => {
  if (
    has(ABILITY_SELF_CONTENT_AUTHORING) &&
    has(FEATURE_FLAG_CUSTOMER_CONTENT)
  ) {
    return [
      // content authors should additionally have access to interactives and rubrics
      CONTENT_TYPE_ECHO,
      CONTENT_TYPE_INTERACTIVE,
      CONTENT_TYPE_RUBRIC,
      CONTENT_TYPE_SOURCE,
    ]
  }

  if ([ROLE_STAFF, ROLE_STUDENT].includes(roleId)) {
    return [
      // teachers only have access to these content types at the moment
      CONTENT_TYPE_ECHO,
      CONTENT_TYPE_SOURCE,
    ]
  }

  if (has(ABILITY_CONTENT_CREATION)) {
    return [
      ...LINKABLE_CONTENT_TYPES,
      // content creators are allowed to search/view individual interactives
      CONTENT_TYPE_INTERACTIVE,
      CONTENT_TYPE_VOCABULARY,
    ]
  }

  return LINKABLE_CONTENT_TYPES
})

export const getSearchableLibraryContentSubTypes = createSelector(
  'getSearchableLibraryContentSubTypes',
)(getCurrentRoleId, createAbilityChecker, (roleId, has) => {
  if ([ROLE_STAFF, ROLE_STUDENT].includes(roleId)) {
    return [
      // teachers only have access to these content types at the moment
      CONTENT_SUB_TYPE_STANDALONE,
      CONTENT_SUB_TYPE_STRUCTURED,
    ]
  }

  if (has(ABILITY_CONTENT_CREATION)) {
    return [
      INTERACTIVE_TYPE_AUDIO,
      INTERACTIVE_TYPE_IMAGE,
      INTERACTIVE_TYPE_VIDEO,
      CONTENT_SUB_TYPE_STANDALONE,
      CONTENT_SUB_TYPE_STRUCTURED,
    ]
  }

  return []
})
