import DialogContent from '@mui/material/DialogContent'
import { styled } from '@mui/material/styles'
import DataTable from 'common/tables/DataTable'
import { get } from 'fp/objects'
import { getLabel } from 'hss/ContentBuilder/utils'
import CardIcon from 'hss/views/Card/CardIcon'
import { position } from 'polished'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import TableCardRenderer from './TableCardRenderer'

const contentTypeCell = ({ getValue, row }) => {
  const contentType = getValue()
  const { contentSubType } = row.original

  return (
    <>
      <CardIcon contentType={contentType} />
      {getLabel({ contentType, contentSubType })}
    </>
  )
}

const columns = [
  {
    accessorKey: 'uploadsMap',
    header: 'uploadsMap',
    id: 'uploadsMap',
  },

  {
    accessorKey: 'contentType',
    cell: contentTypeCell,
    header: 'Type',
    id: 'contentType',
  },

  {
    accessorKey: 'assetCode',
    header: 'Asset',
    id: 'assetCode',
  },

  {
    accessorKey: 'name',
    header: 'Name',
    id: 'name',
  },

  {
    accessorKey: 'id',
    header: 'ID',
    id: 'id',
  },

  {
    accessorFn: get('parent.name'),
    header: 'Parent',
    id: 'parent.name',
  },
]

const StyledTable = styled(DialogContent, {
  name: 'interactivePlugin-InteractivesTable',
})(
  ({
    theme: {
      mixins: { rem },
    },
  }) => ({
    overflowY: 'auto',
    paddingRight: 0,
    position: 'relative',
    height: 'auto',
    maxHeight: '56vh',
    '> ul:first-of-type': {
      overflowY: 'auto',
      marginBottom: 20,
      padding: rem(0, 1.5, 0, 3),
      ...position('absolute', 0, 0, 0, 0),
    },
    li: {
      position: 'relative',
      '.library-card-icon': {
        right: 10,
        top: 0,
      },
    },
  }),
)

const ContentTable = forwardRef(
  ({ dataset: { data }, mode, onComplete }, ref) => (
    <StyledTable dividers>
      <DataTable
        CardRenderer={TableCardRenderer}
        cardRendererProps={{
          forSystemUse: mode === 'internal-link',
          onComplete,
        }}
        columns={columns}
        data={data}
        id="linkPlugin-ContentTable"
        preferCardView
        ref={ref}
      />
    </StyledTable>
  ),
)

export const modeShape = PropTypes.oneOf(['content-insertion', 'internal-link'])

ContentTable.propTypes = {
  dataset: PropTypes.object.isRequired,
  mode: modeShape,
  onComplete: PropTypes.func.isRequired,
}

export default ContentTable
