import {
  CONTENT_STATE_DRAFT,
  CONTENT_SUB_TYPE_STRUCTURED,
  CONTENT_TYPE_SOURCE,
} from 'core/consts'
import type { Content } from 'reducers/@types/content'
import Item from './Item'

const Sections = ({ sections }: { sections: Content[] }) =>
  sections.map(section => (
    <Item
      data-draft={
        section.contentState === CONTENT_STATE_DRAFT ? 'true' : 'false'
      }
      item={section}
      key={section.id}
      itemId={section.id}
      parentContent={section}>
      {section?.children?.map(child =>
        child.contentType === CONTENT_TYPE_SOURCE &&
        child.contentSubType === CONTENT_SUB_TYPE_STRUCTURED ? (
          <Sections
            key={child.id}
            sections={[child]}
          />
        ) : (
          <Item
            data-draft={
              child.contentState === CONTENT_STATE_DRAFT ? 'true' : 'false'
            }
            item={child}
            key={child.id}
            itemId={child.id}
            parentContent={section}
          />
        ),
      )}
    </Item>
  ))

export default Sections
