import InlineImage from 'common/indicators/InlineImage'
import type { ContentState } from 'draft-js'

interface DraftDecoratorProps extends React.ComponentPropsWithoutRef<'img'> {
  contentState: ContentState
  entityKey: string
}

const DraftDecorator: React.FC<DraftDecoratorProps> = ({
  contentState,
  entityKey,
  children,
}) => {
  const entity = contentState.getEntity(entityKey)

  return (
    <>
      <InlineImage
        contentId={entity.getData().contentId}
        size={entity.getData().size}
        valign={entity.getData().valign}
      />
      {/* you have to render the children, even if it does nothing in order for proper selection/deletion https://stackoverflow.com/questions/63074206/draft-js-bug-with-selection-if-decorator-at-end-of-content-block */}
      <span> {children}</span>
    </>
  )
}

export default DraftDecorator
