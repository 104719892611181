import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { sidebarContext } from 'routing/shells/SidebarProvider'
import { hideForPrint } from 'styling/theming/base/mixins'
import BuildNumber from './BuildNumber'
import CollapseButton from './CollapseButton'
import Logo from './Logo'
import Nav from './Nav'

const NavSidebar = ({ homeUrl }) => {
  const { isSidebarShrunk, setSidebarShrunk, sidebarRef, sidebarWidth } =
    useContext(sidebarContext)

  return (
    <>
      <Drawer
        anchor="left"
        data-sidebar="yes"
        data-sidebarstate={isSidebarShrunk ? 'shrunk' : 'full'}
        onClick={isSidebarShrunk ? () => setSidebarShrunk(false) : undefined}
        open
        ref={sidebarRef}
        sx={{
          width: sidebarWidth,
          '> div:first-of-type': {
            width: sidebarWidth,
          },
          '.MuiListItemIcon-root': {
            marginBottom: 0,
          },
          ...hideForPrint(),
        }}
        variant="permanent">
        <Typography
          component="div"
          sx={{ fontSize: 14 }}
          variant="body2">
          <nav>
            <Logo homeUrl={homeUrl} />

            <Nav isSidebarShrunk={isSidebarShrunk} />

            <BuildNumber />
          </nav>
        </Typography>
      </Drawer>

      <CollapseButton />
    </>
  )
}

NavSidebar.propTypes = {
  homeUrl: PropTypes.string.isRequired,
}

export default NavSidebar
